import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import ULinkService from "services/ULink"
import { GlobalState } from "GlobalState"

import DataTable from "widgets/DataTable"

// MUI
import { Button, Container, Paper } from "@mui/material"

export const ListResellers = () => {
    const navigate = useNavigate()
    const { scrollToTop, context } = GlobalState.useContainer()
    useEffect(() => {
        scrollToTop()
    }, [])

    const uri = context.links.find(link => link.name === "Resellers").uri

    return (
        <>
            <Container id="ListMerchants">
                <header className="header-and-button">
                    <h1>All Resellers</h1>
                    <Button
                        id="invite-resellers"
                        variant="outlined"
                        onClick={() => {
                            navigate("/reseller/invite")
                        }}
                    >
                        Invite Resellers
                    </Button>
                </header>

                <Paper
                    sx={{ padding: ".5rem 0" }}
                    children={
                        <DataTable
                            firstFetch={() => ULinkService.resellers.getList(uri)}
                            columns={[{ name: "Name", selector: row => row.name }]}
                            onRowClicked={(row, e) => navigate("/reseller?uri=" + row.uri)}
                            loadingMessage={"Loading Resellers..."}
                            emptyMessage={"No Resellers"}
                        />
                    }
                />
            </Container>
        </>
    )
}
