import React, { useState, useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import ULinkService, { NewCodepayUser } from "services/ULink"
import { MerchantUser } from "data/Merchant"
import { toaster } from "global-ui"

export const AddCpayUser = () => {
    let navigate = useNavigate()
    const uri = useSearchParams()[0].get("uri")

    let [firstName, setFirstName] = useState("")
    let [lastName, setLastName] = useState("")
    let [email, setEmail] = useState("")
    let [phone, setPhone] = useState("")

    //creating new MerchantUser
    const onSave = uri => {
        let cpay_user = {
            name_first: firstName,
            name_last: lastName,
            email: email,
            phone: parseInt(phone.replace(/\D/g, "")),
        }
    }
    return (
        <main id="AddMerchant">
            <div id="ViewCpayUser">
                <h1>Add a new CODE Pay User</h1>
                <table id="basic-edit">
                    <tbody>
                        <tr>
                            <td className="field-label">Role</td>
                            <td>
                                
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label">First name</td>
                            <td>
                                <input
                                    type="text"
                                    onChange={e => setFirstName(e.target.value)}
                                    value={firstName}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label">Last name</td>
                            <td>
                                <input
                                    type="text"
                                    onChange={e => setLastName(e.target.value)}
                                    value={lastName}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label">Email</td>
                            <td>
                                <input
                                    type="text"
                                    onChange={e => setEmail(e.target.value)}
                                    value={email}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="field-label">Phone number</td>
                            <td>
                                <input
                                    type="text"
                                    onChange={e => setPhone(e.target.value)}
                                    value={phone}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td className="actions">
                                <button
                                    className="primary btn-size-jumbo"
                                    onClick={() => onSave(uri)}
                                >
                                    Save
                                </button>
                            </td>
                            <td className="actions">
                                <button
                                    className="destructive btn-size-jumbo"
                                    onClick={() => navigate(-1)}
                                >
                                    Cancel
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </main>
    )
}
