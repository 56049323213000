import React, { useState, useEffect } from "react"
import { Link, useParams, useSearchParams } from "react-router-dom"

import ULinkService, { Iterator, ulink } from "services/ULink"
import Location, { DDAAccount, MIDConfig, MIDCybs } from "data/Location"
import LocationInfo from "./LocationInfo"

import { toaster, currency, timestamp } from "global-ui"
import { Dialog } from "utils"

import { RTP, MID } from "./payment-configs"
import Upload from "/views/common-modals/Upload"
import DataTable from "widgets/DataTable"
import MidCybsConfig from "widgets/configs/MidCybsConfig"
import DDAconfig from "widgets/configs/DDAConfig"
import { GlobalState } from "GlobalState"

export default function (props) {
    let query = useSearchParams()[0]

    // Form state

    let [hasUnsaved, setHasUnsaved] = useState(false)

    let [name, setName] = useState("")
    let [phone, setPhone] = useState("")
    let [address_1, setAddress_1] = useState("")
    let [address_2, setAddress_2] = useState("")
    let [city, setCity] = useState("")
    let [region, setRegion] = useState("")
    let [postal_code, setPostal_Code] = useState("")
    let [status, setStatus] = useState(0)

    //
    // Merchant data
    //
    const [merchant, setMerchant] = useState(null)

    //
    // Location data
    //

    /** @type {[ ?Location, React.Dispatch<Location> ]} */
    let location_state = useState(null)
    const location = location_state[0]

    /** Sets the location directly **without** initializing the form or querying */
    const _set_location = location_state[1]

    /**
     * Updates the location from a fetch, loading form state in the process
     * @param {Location} location
     */
    let setLocation = location => {
        _set_location(location)

        setName(location.name)
        setPhone(location.phone)
        setAddress_1(location.address_1)
        setAddress_2(location.address_2)
        setCity(location.city)
        setRegion(location.region)
        setPostal_Code(location.postal_code)
        setStatus(location.status)
    }

    const { scrollToTop } = GlobalState.useContainer()

    useEffect(() => {
        scrollToTop()

        ULinkService.locations.get(query.get("uri")).then(setLocation).catch(props.catch)
    }, [])

    useEffect(() => {
        if (!location) return

        ULinkService.merchants.get(location?._links.merchant).then(data => setMerchant(data))
    }, [location])


    //
    // Modals
    //

    let [smbMigrateModal, setSmbMigrateModal] = useState(null)
    let [copyConfigModal, setCopyConfigModal] = useState(null)

    let [bannerModal, setBannerModal] = useState(null)

    const openSmbMigrate = () => {
        if (smbMigrateModal) return

        setSmbMigrateModal(
            <SMBMigrateModal
                close={() => setSmbMigrateModal(null)}
                location={location}
                setLocation={_set_location}
            />
            //                                      👆
            // We use `_set_location` since we don't want to destroy all the form state
            // cf. the definitions `setLocation` vs `_set_location`
        )
    }

    const openCopyConfigModal = () => {
        if (copyConfigModal) return

        setCopyConfigModal(
            <CopyConfigsModal
                close={() => setCopyConfigModal(null)}
                location={location}
                setLocation={_set_location}
            />
            // See comments under setSMBMigrateModal above
        )
    }

    const openBannerModal = () => {
        if (bannerModal) return

        setBannerModal(
            <Upload
                title="Banner Image"
                close={() => setBannerModal(null)}
                url={location._links.uploads.banner}
            />
        )
    }

    //
    // Other actions
    //

    const onDeleteMenu = () => {
        let confirm = window.confirm(
            "Are you sure?  This action cannot be undone.  But you can just migrate the legacy menu again 🙂"
        )
        if (!confirm) return

        ulink(location._links.ordering.smb_menu, { method: "DELETE" }).then(res => {
            if (res.ok) {
                ULinkService.refreshObject(location)
                    .then(setLocation)
                    .catch(() =>
                        alert(
                            "The operation succeeded, but there was an error updating your page.  Please refresh your browser window."
                        )
                    )
            } else {
                toaster.catchULink(res)
            }
        })
    }

    //
    // Main render
    //

    if (!location || !merchant) return null
    let rtp = null
    let mid = null

    var $rtp
    if (rtp === undefined) $rtp = <p>Loading...</p>
    else if (rtp === null) $rtp = <p>No Config</p>
    else $rtp = <RTP rtp={rtp} /> // lol

    var $mid
    if (mid === undefined) $mid = <p>Loading...</p>
    else if (mid === null) $mid = <p>No Config</p>
    else $mid = <MID mid={mid} />

    var $ordering
    if (location.has_smb_ordering) {
        $ordering = (
            <>
                <Link
                    role="button"
                    className="primary btn-size-jumbo"
                    to={`/location/smb-menu?uri=${location._links.ordering.smb_menu}`}
                >
                    SMB Ordering Configuration
                </Link>
                <button className="btn-size-jumbo destructive" onClick={onDeleteMenu}>
                    Delete Menu
                </button>
            </>
        )
    } else {
        $ordering = (
            <>
                <p>
                    This location does not have Order Ahead configured. You may migrate a menu from
                    the legacy Menu Builder with the button below. New menus cannot be created yet,
                    but that feature is in development.
                </p>
                <button className="btn-size-jumbo" onClick={openSmbMigrate}>
                    Migrate Legacy Menu
                </button>
            </>
        )
    }

    return (
        <>
            {smbMigrateModal}
            {copyConfigModal}
            {bannerModal}

            <main id="ViewLocation">
                <section role="banner">
                    <h1>{location.name}</h1>
                    <span>{location.has_rtp}</span>

                    <address>
                        {location.address_1}
                        <br />
                        {location.address_2 && (
                            <>
                                {location.address_2} <br />
                            </>
                        )}
                        {location.city} {location.region} {location.postal_code}
                        {hasUnsaved ? (
                            <div>
                                <span>You have unsaved changes.</span>
                            </div>
                        ) : null}
                    </address>

                    {/* LINKS OUT TO LOCATION'S ENTITY */}
                    <span
                        className="merchant-link"
                        onClick={() => {
                            navigate("/merchant?uri=" + encodeURI(merchant.uri))
                        }}
                    >
                        Merchant: {merchant.name}
                    </span>
                </section>

                <section id="Transactions">
                    <h3>Transactions</h3>

                    {/* WARNING WARNING WARNING: DATATABLE BELOW HAS STUB DATA THAT NEEDS TO BE REPLACED */}

                    <DataTable
                        firstFetch={() => ULinkService.transactions.filterByLocation(location)}
                        columns={[
                            { name: "Date & Time", selector: row => timestamp(row.created_at) },
                            { name: "Total", selector: row => currency(row.total) },
                            { name: "Payment Method", selector: row => "BANK 5382" },
                            { name: "Status", selector: row => "Settled at 11/27/21" },
                        ]}
                        onRowClicked={(row, e) => navigate("/transactions/" + row.id)}
                        loadingMessage={"Loading Transactions..."}
                        emptyMessage={"No Transactions"}
                    />
                </section>

                <div className="paneled-grid">
                    <LocationInfo location={location} setHasUnsaved={setHasUnsaved} />

                    <section id="operations">
                        <h2>Operations</h2>
                        <hr />

                        <div className="actions">
                            <button className="btn-size-jumbo" onClick={openCopyConfigModal}>
                                Copy configuration to location
                            </button>
                        </div>
                    </section>

                    <section id="uploads">
                        <h2>Uploads</h2>
                        <hr />

                        <div className="actions">
                            <button className="btn-size-jumbo" onClick={openBannerModal}>
                                Banner Image
                            </button>
                        </div>
                    </section>

                    {/* TODO: Loading animations here 👇 */}

                    <section id="ordering">
                        <h2>Order Ahead</h2>
                        <hr />

                        <div className="actions">{$ordering}</div>
                    </section>

                    <section id="rtp">
                        <h2>DDA Configuration</h2>
                        <hr />

                        <DDAconfig uri={location._links.override_configs.mid_dda} />
                    </section>

                    <section id="mid">
                        <h2>MID Configuration</h2>
                        <hr />

                        <MidCybsConfig uri={location._links.override_configs.mid_cybs} />
                    </section>
                </div>
            </main>
        </>
    )
}

/**
 *
 * @param {object} props
 * @param {function} props.close
 *
 * @param {Location} props.location
 * @param {React.Dispatch<Location>} props.setLocation
 */
function SMBMigrateModal(props) {
    let [mrmID, setMrmID] = useState("1654217")

    const submit = () => {
        ulink(props.location._links.ordering.smb_migrate, {
            method: "POST",
            json: { mrm_id: mrmID },
        }).then(res => {
            if (res.ok) {
                ULinkService.refreshObject(props.location)
                    .then(loc => {
                        props.setLocation(loc)
                        props.close()
                    })
                    .catch(() =>
                        alert(
                            "The operation succeeded, but there was an error updating your page.  Please refresh your browser window."
                        )
                    )
            } else {
                switch (res.status) {
                    case 422:
                        alert(
                            "ULink could not find a location with that MRM ID in the legacy system; please double-check it and try again."
                        )
                    case 409:
                        alert(
                            "A menu corresponding to this location already exists in the SMB service."
                        )
                    case 410:
                        alert(
                            "This location already has v2 SMB menus set up; please refresh this page."
                        )
                    default:
                        alert("An unexpected error has occurred.")
                }
            }
        })
    }

    return (
        <Dialog close={props.close} title="Migrate Legacy Menu">
            <p>
                This will migrate a menu from the legacy Menu Builder and associate it with{" "}
                {props.location.name}
            </p>
            <p>Enter the MRM ID of the menu from Menu Builder below:</p>

            <table className="editor">
                <tbody>
                    <tr>
                        <td>MRM ID</td>
                        <td>
                            <input
                                type="text"
                                value={mrmID}
                                onChange={e => setMrmID(e.target.value)}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>

            <button className="primary btn-size-jumbo" onClick={submit}>
                Migrate
            </button>
        </Dialog>
    )
}

/**
 *
 * @param {object} props
 * @param {function} props.close
 *
 * @param {Location} props.location The location of the parent view
 * @param {React.Dispatch<Location>} props.setLocation
 */
function CopyConfigsModal(props) {
    /** @type {[ [Location], React.Dispatch<[Location]> ]} */
    let [siblingLocs, setSiblingLocs] = useState([])
    /** @type {[ Iterator<Location>, React.Dispatch<Iterator<Location>> ]} */
    let [siblingIterator, setSiblingIterator] = useState(null)

    /** @type {[ number, React.Dispatch<number> ]} */
    let [selectedLocID, setSelectedLocID] = useState(null)

    let [useRTP, setUseRTP] = useState(false)
    let [useMID, setUseMID] = useState(false)

    /** @param {Iterator<Location>} it */
    function appendSiblingLocs(it) {
        setSiblingIterator(it)
        setSiblingLocs(x => x.concat(it.items))
    }

    const nextPage = () =>
        siblingIterator
            .next()
            .then(appendSiblingLocs)
            .catch(err => {
                toaster.catchULink(err)
                setSiblingIterator(null)
            })

    useEffect(() => {
        ULinkService.locations
            .getIterator(props.location._links.siblingLocations)
            .then(appendSiblingLocs)
            .catch(err => {
                toaster.catchULink(err)
                props.close()
            })
    }, [])

    const selectedLoc = selectedLocID && siblingLocs.find(loc => loc.id === selectedLocID)

    const canRTP =
        siblingLocs &&
        siblingLocs.find(loc => !loc.has_rtp) &&
        (!selectedLoc || !selectedLoc.has_rtp)
    const canMID =
        siblingLocs &&
        siblingLocs.find(loc => !loc.has_mid) &&
        (!selectedLoc || !selectedLoc.has_mid)

    var $locSelect
    if (siblingLocs.length == 0 && siblingIterator === null) $locSelect = <p>Loading...</p>
    else if (siblingLocs.length == 0) $locSelect = <p>No eligible locations!</p>
    else
        $locSelect = (
            <>
                {siblingLocs.map(loc => {
                    let eligible = !(loc.has_mid && loc.has_rtp)

                    var status
                    if (!loc.has_mid && !loc.has_rtp) status = "Has no payment configuration"
                    else if (!loc.has_mid && loc.has_rtp) status = "Has no MID configuration"
                    else if (loc.has_mid && !loc.has_rtp) status = "Has no RTP configuration"
                    else status = "Already fully configured"

                    return (
                        <label key={loc.id} aria-disabled={!eligible}>
                            <input
                                type="radio"
                                checked={selectedLocID == loc.id}
                                onChange={() => setSelectedLocID(loc.id)}
                                disabled={!eligible}
                            />
                            {loc.name} - {loc.address_1}
                            <span className="status">{status}</span>
                        </label>
                    )
                })}

                {siblingIterator && (
                    <button className="btn-size-xlg" onClick={nextPage}>
                        Load More
                    </button>
                )}
            </>
        )

    let ready = selectedLoc && (useMID || useRTP)

    const submit = () => {
        if (!ready) return

        let links = {}
        if (useMID) links.mid = props.location._links.mid
        if (useRTP) links.rtp = props.location._links.rtp

        ULinkService.link(selectedLoc.uri, links).then(res => {
            if (res.ok) {
                props.close()
                toaster.saved()
            } else {
                // Error during LINK operation
                toaster.catchULink(res.status)
            }
        })
    }

    return (
        <Dialog close={props.close} title="Copy Configuration" id="CopyConfigsModal">
            <p>
                This will copy the selected payment configurations to another location within this
                merchant. You cannot copy a payment configuration to a location that already has a
                configuration of that type; to do so, go to that location's page and remove the
                configuration you wish to overwrite.
            </p>

            <section>
                {props.location.has_rtp && (
                    <label aria-disabled={!canRTP}>
                        <input
                            type="checkbox"
                            id="copy_useRTP"
                            selected={useRTP}
                            onChange={e => setUseRTP(e.target.value)}
                            disabled={!canRTP}
                        />
                        Copy RTP configuration
                    </label>
                )}

                {props.location.has_mid && (
                    <label aria-disabled={!canMID}>
                        <input
                            type="checkbox"
                            id="copy_useMID"
                            selected={useMID}
                            onChange={e => setUseMID(e.target.value)}
                            disabled={!canMID}
                        />
                        Copy MID configuration
                    </label>
                )}
            </section>

            <p>Select a location:</p>
            <div>{$locSelect}</div>

            <button className="primary btn-size-xlg" onClick={submit} aria-disabled={!ready}>
                Submit
            </button>
        </Dialog>
    )
}
