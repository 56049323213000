import React from "react"
import { helperText } from "widgets/InputValidator";

// MUI
import {
    Paper
} from "@mui/material"

import FormValidator from '../widgets/FormValidator';
import TextInput from '../widgets/TextInput';

interface PropTypes {
    personalDetailsValid: boolean
    setPersonalDetailsValid: (valid: boolean) => void
    accountSetupValid: boolean
    setAccountSetupValid: (valid: boolean) => void
    email: string
    setEmail: (email: string) => void
    name: string
    setName: (name: string) => void
    password: string
    setPassword: (password: string) => void
    passwordConfirm: string
    setPasswordConfirm: (passConf: string) => void
    passwordsMatch: boolean
}

export const UserEdit: React.FC<PropTypes> = ({
    personalDetailsValid,
    setPersonalDetailsValid,
    accountSetupValid,
    setAccountSetupValid,
    email,
    setEmail,
    name,
    setName,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    passwordsMatch
}) => {
    return (
        <>
            <Paper sx={{ padding: "2rem", marginBottom: "1rem" }} elevation={3} children={
                <div className="flex-column">
                    <h2>Personal Details</h2>

                    <FormValidator fieldsValid={personalDetailsValid} setFieldsValid={setPersonalDetailsValid}>
                        <TextInput
                            type="generic"
                            label="Full Name"
                            value={name}
                            required={true}
                            margin="normal"
                            onChange={(e) => { setName(e.target.value) }}
                        />

                        <TextInput
                            type="email"
                            label="E-mail"
                            value={email}
                            required={true}
                            margin="normal"
                            onChange={(e) => { setEmail(e.target.value) }}
                        />
                    </FormValidator>
                </div>
            }/>

            <Paper sx={{ padding: "2rem" }} elevation={3} children={
                <div className="flex-column">
                    <h2>Account Setup</h2>
                    <FormValidator fieldsValid={accountSetupValid} setFieldsValid={setAccountSetupValid}>
                        <TextInput
                            type="password"    
                            label="Password"
                            value={password}
                            required={true}
                            margin="normal"
                            onChange={(e) => { setPassword(e.target.value) }}
                            autoComplete="new-password"
                        />

                        <TextInput
                            type="password" 
                            label="Confirm Password"
                            value={passwordConfirm}
                            required={true}
                            margin="normal"
                            errorOverwrite={!passwordsMatch}
                            helperTextOverwrite={passwordsMatch ? "" : helperText.password.doNotMatch}
                            onChange={(e) => { setPasswordConfirm(e.target.value) }}
                            autoComplete="new-password"
                        />
                    </FormValidator>
                </div>
            } />
        </>
    )
}
