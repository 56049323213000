import { MIDdda } from "data/Location"
import React, { useState, useEffect } from "react"
import { toaster } from "global-ui"
import ULinkService from "services/ULink"
import { GlobalState } from "GlobalState"
import FormValidator from "widgets/FormValidator"
import TextInput from "widgets/TextInput"

// MUI Components
import { Button, TextField, FormGroup, FormControlLabel, Switch } from "@mui/material"

export default function DDAconfig(props: { 
    uri: string, 
    location?: object,
    ddaFormValid: boolean,
    setDDAFormValid: (valid: boolean) => void,
}) {
    let [loadingDDA, setLoadingDDA] = useState(true)
    let [DDA, setDDA]: [MIDdda, React.Dispatch<React.SetStateAction<MIDdda>>] = useState(null)

    let [newAccount, setAccount] = useState("")
    let [newRouting, setRouting] = useState("")
    let [name_first, setFirstName] = useState("")
    let [name_last, setLastName] = useState("")
    let [postal_code, setPostalCode] = useState("")
    let [activeStatus, setActiveStatus] = useState(false)
    let [reloadNewDDA, setReload] = useState(false)
    let [ isSaving, setIsSaving ] = useState(false);

    const { context } = GlobalState.useContainer()

    useEffect(() => {
        ULinkService.configs
            .getMidDDA(props.uri)
            .then(setDDA)
            .catch(err => {
                if (err == 404) console.debug("Location has no override cybs mid")
                else {
                    console.error("Fetching override dda mid", err)
                }
            })
            .finally(() => {
                setLoadingDDA(false)
            })

        setLoadingDDA(false)
    }, [reloadNewDDA])

    function onSave() {
        if(isSaving) { toaster.isSubmitting(); return; }
        if(!props.ddaFormValid) {
            toaster.invalidFields();
            return;
        }
        
        setIsSaving(true);
        ULinkService.put(props.uri, {
            active: activeStatus,
            account: newAccount,
            routing: newRouting,
            name_first,
            name_last,
            postal_code,
        })
            .then(res => {
                if (res.status == 200) {
                    toaster.saved()
                    setReload(!reloadNewDDA)
                    setIsSaving(false);
                } else if (res.status > 201) {
                    toaster.error()
                    setIsSaving(false);
                }
            })
            .catch(toaster.catchULink)
    }

    function handleActivation() {
        ULinkService.update({ ...DDA, active: !DDA.active, uri: DDA.uri ? DDA.uri : props.uri })
            .then(() => setReload(!reloadNewDDA))
            .catch(toaster.catchULink)
    }

    function onDelete() {
        const confirm = window.confirm(
            "Are you sure? This action cannot be undone without contacting support."
        )
        if (!confirm) return

        ULinkService.delete(props.uri)
            .then(res => {
                setDDA(null)
            })
            .catch(toaster.catchULink)
    }

    let noDDAConfig: React.ReactNode = (
        <>
            <p style={{ marginLeft: 0, marginTop: ".25rem" }}>
                No Configuration has been set. Add DDA info below.
            </p>
        </>
    )

    let ddaForm: React.ReactNode = (
        <>
            <h1 style={{ marginBottom: 0 }}>DDA Information</h1>
            {noDDAConfig}
            <div id="DDAForm">
                <FormValidator fieldsValid={props.ddaFormValid} setFieldsValid={props.setDDAFormValid}>
                    <TextInput
                        type="accountNumber"
                        id="outlined-required"
                        label="Account Number"
                        margin="normal"
                        required={true}
                        value={newAccount}
                        onChange={e => setAccount(e.target.value)}
                    />

                    <TextInput
                        type="routingNumber"
                        id="outlined-required"
                        label="Routing Number"
                        margin="normal"
                        required={true}
                        value={newRouting}
                        onChange={e => setRouting(e.target.value)}
                    />

                    <TextInput
                        type="generic"
                        id="outlined-required"
                        label="First Name"
                        margin="normal"
                        required={true}
                        value={name_first}
                        onChange={e => setFirstName(e.target.value)}
                    />

                    <TextInput
                        type="generic"
                        id="outlined-required"
                        label="Last Name"
                        margin="normal"
                        required={true}
                        value={name_last}
                        onChange={e => setLastName(e.target.value)}
                    />

                    <TextInput
                        type="addressZip"
                        id="outlined-required"
                        label="Postal Code"
                        margin="normal"
                        required={true}
                        value={postal_code}
                        onChange={e => setPostalCode(e.target.value)}
                    />
                </FormValidator>

                {/* "Active" switch */}
                <FormGroup>
                    <FormControlLabel
                        label="Active"
                        control={
                            <Switch
                                checked={activeStatus}
                                onChange={e => {
                                    setActiveStatus(e.target.checked)
                                }}
                            />
                        }
                    />
                </FormGroup>

                <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <Button variant="outlined" color="secondary" onClick={onSave}>
                        Save
                    </Button>
                </div>
            </div>
        </>
    )

    let existingDDA: React.ReactNode = <></>

    if (DDA) {
        existingDDA = (
            <>
                <div
                    id="ExistingDDA"
                    style={{ display: "flex", flexDirection: "column", width: "30%" }}
                >
                    <h1>DDA Information</h1>
                    <div id="DDAExisting">
                        <TextField
                            disabled
                            id="outlined-required"
                            label="Account Number"
                            value={`***** ${DDA.masked_account}`}
                            margin="normal"
                        />

                        <TextField
                            disabled
                            id="outlined-required"
                            label="Routing Number"
                            value={`***** ${DDA.masked_routing}`}
                            margin="normal"
                        />

                        <TextField
                            disabled
                            id="outlined-required"
                            label="Account Holder"
                            value={`${DDA.name_first} ${DDA.name_last}`}
                            margin="normal"
                        />

                        {/* "Active" switch */}
                        <FormGroup>
                            {context.type == "sionic" && (
                                <FormControlLabel
                                    label="Active"
                                    control={
                                        <Switch
                                            checked={DDA.active ? true : false}
                                            onChange={handleActivation}
                                        />
                                    }
                                />
                            )}
                        </FormGroup>

                        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <Button variant="outlined" color="error" onClick={onDelete}>
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
            {loadingDDA && <p>Searching For Config...</p>}
            {!loadingDDA && !DDA && ddaForm}
            {!loadingDDA && DDA && existingDDA}
        </>
    )
}
