import React, { useState, useEffect } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import ULinkService from "services/ULink"
import { GlobalState } from "GlobalState"
import { emailValid } from "widgets/regex/validation"
import { Container, Paper, Button, TextField, Grid, Typography } from "@mui/material"

import { toaster } from "global-ui"

export function InitiateReset(props) {
    const navigate = useNavigate()
    const uri = useSearchParams()[0].get("uri")

    const [ email, setEmail ]                       = useState("");
    const [ resetEmailsSent, setResetEmailsSent ]   = useState(0);
    const emailsSentLimit = 3;

    const submitEmail = () => {
        if (!emailValid(email)) {
            toaster.invalidEmail()
            return
        }
        generateResetLink()
    }

    const InterfaceCopy = (props: { resetEmailsSent: number }) => {
        const { resetEmailsSent } = props;
        if(resetEmailsSent == 0 && resetEmailsSent < emailsSentLimit) {
            return (
                <p style={{margin: "0"}}>
                    Forgot your account's password or having trouble logging in?
                    Enter your email address and we'll send you a recovery link.
                </p>
            )
        }
        else if(resetEmailsSent > 0 && resetEmailsSent < emailsSentLimit) {
            return (
                <>
                    <Typography color="primary" sx={{marginBottom: "0"}}>
                        <b>An email should appear in your inbox shortly.</b>
                    </Typography>
                    <p style={{margin: "0", marginTop: "1rem"}}>
                        If you haven't received an email within a few minutes, 
                        check that you've entered your email address correctly and try again.
                    </p>
                </>
            )
        } else {
            return (
                <p>
                    Please contact us at <a href="mailto:support@sionic.io">support@sionic.io</a> for assistance.
                </p>
            )
        }
    }

    const generateResetLink = () => {
        ULinkService.admins
            .getResetLink({ email: email })
            .then(res => {
                const address = `${location.protocol}//${
                    location.host
                }/reset-password?uri=${encodeURIComponent(res.uri)}`

                ULinkService.admins
                    .sendResetLink({ email: email, link: address })
                    .then(res => {
                        if (res === 200) {
                            setResetEmailsSent((previous) => previous + 1)
                            toaster.emailSent()
                        }
                    })
                    .catch(err => {
                        toaster.catchULink(err)
                    })
            })
            .catch(err => {
                // Provide a success message on failure to prevent users from easily seeing what emails are in db
                setResetEmailsSent((previous) => previous + 1)
                toaster.emailSent()
            })
    }
    return (
        <>
            <Container sx={{ display: "flex !important", justifyContent: "center", alignItems: "center", width: "100%", minHeight: "80vh" }}>
                <Paper
                    sx={{
                        padding: "2rem",
                        maxWidth: "50%",
                    }}
                    children={
                        <>
                            <header>
                                <h1 style={{marginBottom: "1.5rem"}}>Forgot Your Password?</h1>
                                <InterfaceCopy resetEmailsSent={resetEmailsSent} />
                            </header>
                            <Grid container rowSpacing={3}>
                                <Grid container rowSpacing={1} item xs={12}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Email"
                                            margin="normal"
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div style={{display: "flex", justifyContent: "space-between"}}>
                                            <Button disabled={resetEmailsSent >= emailsSentLimit} variant="contained" onClick={submitEmail}>
                                                Send recovery email
                                            </Button>

                                            <Button variant="outlined" color="primary" onClick={()=> navigate("/login")}>
                                                Back to Log In
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    }
                />
            </Container>
        </>
    )
}
