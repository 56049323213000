import React from "react"

import { useNavigate, useSearchParams } from "react-router-dom"

import ULinkService from "services/ULink"
import Merchant from "data/Merchant"
import { entity_location_statuses, roles } from "globals"
import DataTable from "widgets/DataTable"

export default function (props: { merchant: Merchant }) {
    const navigate = useNavigate()
    const uri = useSearchParams()[0].get("uri")

    return (
        <>
            <h2>Admin Portal Users</h2>
            <hr />

            <DataTable
                firstFetch={() => ULinkService.admins.listUsers(uri + "/users")}
                columns={[
                    { name: "Name", selector: row => row.user.name },
                    {
                        name: "Role",
                        selector: row => roles["merchant"].getNameForValue(row.roleValue),
                    },
                    { name: "Status", selector: row => entity_location_statuses[row.user.status] },
                ]}
                onRowClicked={(row, e) => navigate(`/merchant/user?uri=/v2/admin${row.uri}`)}
                loadingMessage={"Loading Users..."}
                emptyMessage={"No Users"}
            />
        </>
    )
}
