import React, { useState, useEffect } from "react";

import { entity_location_statuses } from 'globals';
import Location from "data/Location";

import ULinkService from "services/ULink";
import { toaster } from "global-ui";
import { useNavigate } from "react-router-dom";

// MUI
import {
    Select,
    SelectChangeEvent,
    Button,
    TextField,
    FormControl,
    InputLabel,
    RadioGroup,
    FormLabel,
    MenuItem,
    FormControlLabel,
    Radio,
} from '@mui/material'


export default function(props) {
    const isNew = props.location == null 

    const navigate = useNavigate()

    let [ location, setLocation ]     = useState(props.location ?? new Location()) 
    let [ state, setState ]           = useState({});

    useEffect(() => {
        console.log(state)
    }, [state]);

    const mergeState = (update) => { 
        props.setHasUnsaved(true) 
        setState(old => Object.assign( {}, old, update) ) 
    }

    const onSave = () => { 
        const obj = Object.assign(location, state)

        // What is this!?!?!?
        // TODO: Make this legit and not hardcoded eventually
        obj.brand = {
            name: "Placeholder brand",
            category: 2,
        }
        // -------- 
        
        if(isNew) { 
            ULinkService.locations.createInMerchant(props.merchantObj, obj)
                .then( newLocation => {
                    newLocation.uri = '/v2/admin/locations/' + newLocation.id
                    toaster.created('Location')
                    navigate('/location?uri=' + newLocation.uri)
                })
                .catch(toaster.catchULink)

        } else { 
            ULinkService.updateObject(Object.assign(obj, state))
                .then(updated => { 
                    props.setHasUnsaved(false) 
                    setState({}) 
                    setLocation(updated)

                    toaster.saved()
                })
                .catch(toaster.catchULink)
        }    
    }

    const onDelete = () => { 
        const obj = Object.assign(location, state)
        let confirm = window.confirm("Are you sure?  This action cannot be undone within the admin site.  However, the data will be preserved and this can be manually reversed by Sonic.")
        if(! confirm) return 
        obj.status = 2;
        ULinkService.updateObject(obj)
            .then(() => navigate(-1))
            .catch(toaster.catchULink) 
    }

    const tipSelectorChange = (event: SelectChangeEvent) => {
        // This switch is needed bc an MUI form input can't have a bool value 🙄
        switch(event.target.value) {
            case "null": {mergeState({ override_allow_tips: null })}
            break;
            case "false": {mergeState({ override_allow_tips: false })}
            break;
            case "true": {mergeState({ override_allow_tips: true })}
            break;
        }
    };

    let current = Object.assign(location, state) 

    return ( 
        <>
                <div className="header-and-button">
                    <h2>Location Information</h2>
                </div>

                <div className="flex-column">
                    {/* Status Select */}
                    <FormControl sx={{margin: "1rem 0rem"}} fullWidth>
                        <InputLabel> Status </InputLabel>
                        <Select
                            label="Status"
                            value={current.status}
                            onChange={ (e) => mergeState({ status: e.target.value }) }
                        >
                            { Object.keys(entity_location_statuses).map( num => (
                                <MenuItem key={num} value={num}>{entity_location_statuses[num]}</MenuItem>
                            ) ) }
                        </Select>
                    </FormControl>

                    <TextField
                        label="Name"
                        value={current.name || "" }
                        required={true}
                        margin="normal"
                        onChange={ (e) => mergeState({ name: e.target.value }) }
                    />

                    <TextField
                        label="URL Slug"
                        value={current.url_slug || "" }
                        margin="normal"
                        onChange={ (e) => mergeState({ url_slug: e.target.value }) }
                    />


                    {/* Tip Option Selector */}
                    <FormControl>
                        <FormLabel>Tips</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={null}
                            name="radio-buttons-group"
                            value={current.override_allow_tips}
                            onChange={tipSelectorChange}
                        >
                            <FormControlLabel value={"null"} control={<Radio />} label="Use business default" />
                            <FormControlLabel value={"true"} control={<Radio />} label="Always allow tips" />
                            <FormControlLabel value={"false"} control={<Radio />} label="Never allow tips" />
                        </RadioGroup>
                    </FormControl>

                    <TextField
                        label="Phone"
                        margin="normal"
                        required={true}
                        value={ current.phone || "" } 
                        onChange={ (e) => mergeState({ phone: e.target.value }) }
                    />

                    <TextField
                        label="Address Line 1"
                        margin="normal"
                        required={true}
                        value={ current.address_1 || "" } 
                        onChange={ (e) => mergeState({ address_1: e.target.value }) }
                    />
                    
                    <TextField
                        label="Address Line 2"
                        margin="normal"
                        required={true}
                        value={ current.address_2 || "" } 
                        onChange={ (e) => mergeState({ address_2: e.target.value }) }
                    />

                    <TextField
                        label="City"
                        margin="normal"
                        required={true}
                        value={ current.city || "" } 
                        onChange={ (e) => mergeState({ city: e.target.value }) }
                    />

                    <TextField
                        label="State/Region"
                        margin="normal"
                        required={true}
                        value={ current.region || "" } 
                        onChange={ (e) => mergeState({ region: e.target.value }) }
                    />

                    <TextField
                        label="Zip"
                        margin="normal"
                        required={true}
                        value={ current.postal_code || "" } 
                        onChange={ (e) => mergeState({ postal_code: e.target.value }) }
                    />
                </div>

                <div className="right-aligned-buttons">
                    <Button sx={{marginRight: "1rem"}} color="secondary" variant="contained" onClick={onSave}>{isNew ? "Create" : "Save"}</Button>
                    {!isNew && (
                        <Button variant="contained" color="error" onClick={onDelete}>
                            Delete
                        </Button>
                    )}
                    {isNew && (
                        <Button variant="outlined" color="error" onClick={() => navigate(-1)}>
                            Cancel
                        </Button>
                    )}
                </div>

        </>
    )
}