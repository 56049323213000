import React, { useState, useEffect } from "react";
import CpayUser from "data/CpayUser";
import { useParams, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import ULinkService from "services/ULink";
import * as GlobalUI from 'global-ui'
import PopUp from "widgets/Popup";


export default function(props) { 
    let params = useParams();
    let uri = useSearchParams()[0].get('uri') ?  useSearchParams()[0].get('uri') : null
    let navigate = useNavigate();

    let [ showPopup, setShowPopup ]     = useState(false);

    // Form state
    let [role, setRole]                 = useState(null)
    let [ firstName, setFirstName ]     = useState('');
    let [ lastName, setLastName ]       = useState('');
    let [ email, setEmail ]             = useState('');
    let [ phone, setPhone ]             = useState('');


    // Object state

    /** @type {[ CpayUser, function(CpayUser) ]} */
    let [ user, _set_user ] = useState(null); 

    /**
     * Updates the merchant from a fetch, loading form state in the process
     * @param {user} user 
     */
    let setUser = (user) => {
        console.log(user)
        _set_user       (user);

        setFirstName    (user.name_first);
        setLastName     (user.name_last);
        setEmail        (user.email);
        setPhone        (user.phone);

    }

    useEffect(() => { 
        if(uri){
            ULinkService.cpayUsers.merchantUsers(uri)
            .then(res =>{
                console.log(res)

            })
        }
        ULinkService.cpayUsers.byID(params.cpay_user_id)
        .then( setUser )
        .catch(err => {
            console.log(err);
            ULinkService.notifyError()
        })

    }, [])

    //
    // Data updating
    //

    function updateUser() { 
        user.name_first     = firstName;
        user.name_last      = lastName;
        user.email          = email;
        user.phone          = parseInt(phone.replace(/\D/g,''));

        ULinkService.update(user) //user is undefined on 2nd req
            .then( res => {
                if(!res.ok) ULinkService.notifyError(res.status);
                if(res.ok) {
                    ULinkService.notifySucess();
                    return res.json();
                }
            })
            .then( json => {
                setUser( new CpayUser(json))
                navigate(-1)
            } )
            .catch(err => ULinkService.notifyError());
    }


    if(! user) return null; 

    let roleDropdown;

    if(uri){
        roleDropdown = (
            <tr>
                <td className="field-label">Role</td>
                <td>
                </td>
            </tr>
        )
    }

    return (
        <>
            { showPopup ? <PopUp setShowPopup={setShowPopup} /> : null }
            <div id="ViewCpayUser">
            <h1>CODE Pay User: { user.name() }</h1>

            <table id="basic-edit">
                <tbody>
                    {roleDropdown}
                    <tr>
                        <td className="field-label">First name</td>
                        <td>
                            <input type="text" value={ firstName } onChange={(e) => setFirstName(e.target.value) } />
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label">Last name</td>
                        <td>
                            <input type="text" value={ lastName } onChange={(e) => setLastName(e.target.value) } />
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label">Email</td>
                        <td>
                            <input type="text" value={ email } onChange={(e) => setEmail(e.target.value) } />
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label">Phone number</td>
                        <td>
                            <input type="text" value={ phone } onChange={(e) => setPhone(e.target.value) } />
                        </td>
                    </tr>
                    <tr>
                        <td className="field-label">Created at</td>
                        <td className="field-value">{ GlobalUI.timestamp(user.created_at) }</td>
                    </tr>
                    <tr>
                        <td className="field-label">Updated at</td>
                        <td className="field-value">{ GlobalUI.timestamp(user.updated_at) }</td>
                    </tr>
                    <tr>
                        <td className="field-label">Last login at</td>
                        <td className="field-value">{ GlobalUI.timestamp(user.last_login_at) }</td>
                    </tr>
                    <tr>
                        <td className="field-label">Password set at</td>
                        <td className="field-value">{ GlobalUI.timestamp(user.pw_set_at) }</td>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <button className="primary btn-size-jumbo" onClick={ updateUser }>Save</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        </>
    )
}