import React, { useState }  from 'react';

import * as MenuData from 'lib/lib-smb-menus/data/Menu'

import GlobalInput from '../../widgets/GlobalInput.jsx';
import { Button } from 'widgets/Button.jsx';
import EditorState, { Change } from '../../EditorState.js';

/**
 * @param {object} props 
 * @param {MenuData.MenuSection} props.section 
 * 
 * @param {EditorState} props.editorState
 * @param {React.Dispatch<EditorState>} props.setEditorState 
 * @returns 
 */
export default function(props) {

    //TODO: time-of-day 
    let [ restrict, setRestrict ] = useState(false)

    const change = props.editorState.getChange(props.section) 
    const current = change?.getResult(props.section) ?? props.section

    //TODO: New items different method
    //TODO: Delete (UX?) 

    /** @param {object} chg */
    const changeExisting = (chg) => { 
        props.setEditorState(
            props.editorState.copy().withChange(
                (change ?? Change.NewEditEntity(props.section)).withApply(chg) 
            )
        )
    }

    return (
        <div className="card MenuSection">
            <GlobalInput name="name" label="Section Name" 
                value={ current.name } onChange={ e => changeExisting({ name: e.target.value }) }
                edittip="Click to edit section name" 
                infotip="Name of section goes here" />

            <Button aria-pressed={restrict} onClick={ () => setRestrict(x => !x) }
                icon={ restrict ? 'check_box' : 'check_box_outline_blank' } 
                >Restrict by time-of-day</Button>

            <GlobalInput name="description" multiline 
                value={ current.description } onChange={ e => changeExisting({ description: e.target.value })}
                label="Section Description"
                edittip="Click to edit section description" 
                infotip="Give your section a description here" />
        </div>
    )
}