import Reseller from "./Reseller"

export class ResellerGroup {
    id: string
    group_name: string
    parent_reseller_id: string
    rev_share_rate: number
    uri: string
    resellers: Array<Reseller>

    _links: {
        invite: string
        members: string
    }

    constructor(obj: any) {
        this.id = obj.id
        this.group_name = obj.group_name
        this.parent_reseller_id = obj.parent_reseller_id
        this.rev_share_rate = obj.rev_share_rate
        this.uri = obj.uri
        this.resellers = obj.resellers
        this._links = obj._links
    }
}
