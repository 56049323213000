import React, { useEffect, useRef, useState } from 'react'
import { toaster } from '/global-ui'
import { ulink, ULINK_MIRROR } from '/services/ULink'

import { Dialog } from "/utils"

/**
 * Generic dialog to manage file uploads.
 * 
 * Only images directly to `props.url` for now, 
 * since that's our only use case, but this could perhaps be expanded or repurposed
 * 
 * @param {object} props 
 * @param {string} props.url The URL to read to/write from; automatic for now 
 * 
 * @param {function} props.close Close function to pass to the `Dialog`
 * @param {string} props.title Title to pass to `Dialog`
 * 
 */
export default function(props) { 
    let input   = useRef(null)

    /**
     * Uploaded file 
     * @type {[ File, React.Dispatch<React.SetStateAction<File>> ]}
     */
    let [ file, setFile ] = useState(null) 

    /** @typedef {"EMPTY"|"BROKEN"|"EXISTS"|null} AttachmentStatus */
    /** @type {[ AttachmentStatus, React.Dispatch<React.SetStateAction<AttachmentStatus>> ]} */
    let [ status, setStatus ] = useState(null) 

    /** @type {[ string, React.Dispatch<React.SetStateAction<string>> ]} */
    let [ blobURL, setBlobURL ] = useState(null)

    
    useEffect(() => { 
        if(status) return 

        ulink(props.url, { 
            cache: 'no-cache',
            headers: new Headers({ 'cache-control': 'no-store' }),
            method: "HEAD",
            redirect: 'manual'
        }) 
        .then(res => { 
            if(res.type == 'opaqueredirect') setStatus("EXISTS")
            else if(res.status == 204) setStatus("EMPTY")
            else if(res.ok) setStatus("EXISTS")
            else { 
                console.error("Unhandled HTTP response while loading attachment") 
                console.error(res) 
                setStatus("BROKEN")
            }
        })

    }, [status])


    const onUploadFile = () => { 
        if(! file) { 
            console.error("No file selected") 
            return 
        }

        var data = new FormData() 
        data.append('file', file) 

        ulink(props.url, { method: 'PUT', body: data })
        .then(res => { 
            if(res.ok) { 
                setFile(null)
                setBlobURL(URL.createObjectURL(file))
                setStatus("EXISTS")
            }
            else toaster.catchULink(res) 
        })
    }


    const onDeleteFile = () => { 
        if(! window.confirm("Are you sure you want to delete the uploaded file?  This cannot be undone.")) return 

        ulink(props.url, { method: 'DELETE' })
        .then(res => { 
            if(res.ok) { 
                toaster.deleted("image");
                setStatus("EMPTY")
                setBlobURL(null) 
            }
            else toaster.catchULink(res) 
        })
    }


    var $display = null 
    if(file) { 
        $display = <> 
            <p>Preview:</p>
            <img id="UploadingImage" src={ URL.createObjectURL(file) } />
        </>
    }
    else switch(status) { 
        case "EMPTY": 
            $display = <p>
                This file is currently empty.
                You may choose a file to upload with the button below. 
            </p>
            break 
        case "BROKEN":
            $display = <p>
                There was an error fetching the current status of this file.  
                Please contact the try again or upload another file.
            </p>
            break 

        case "EXISTS":
            $display = <>
                <p>Current file:</p>
                <img id="UploadedImage" src={ blobURL ?? ULINK_MIRROR + props.url } 
                    onError={ () => { console.warn("Error displaying LOADED image"); setStatus("BROKEN") } } 
                    /> 
            </>
            break 

        default:
            $display = <p>Loading...</p>
    }


    /** 
     * @param {React.ChangeEvent<HTMLInputElement>} e 
     */
    const onSelectFile = e => { 
        if(e.target.files.length == 1) setFile(e.target.files[0]) 
        else toaster.customError("Please select only one file.");
    }


    return <Dialog id="Upload" close={props.close} title={ props.title ?? "Upload file" }>
        <input type="file" ref={input} accept="image/*" style={{ visibility: "hidden" }} onChange={ onSelectFile } />

        {$display} 

        <div className="actions">
            { <button className='btn-size-jumbo' onClick={ () => input.current.click() }>{ file ? "Change file" : "Select file" }</button> }
            { file && <button className='btn-size-jumbo primary' onClick={ onUploadFile }>{ blobURL ? "Replace with selected file" : "Upload selected file" }</button>}
            { status == "EXISTS" && <button className="btn-size-jumbo destructive" onClick={ onDeleteFile }>Delete uploaded file</button> }
        </div>
    </Dialog>
}