import React from "react";
import GlobalInput from '../widgets/GlobalInput.jsx';

export default function(props) {
    let kernel = props.kernel;

    let ModCard = function(props) {
        let mod = props.mod;
        
        function filterMenuItemMods() {
            let filteredMods = kernel.menu_item_mods.filter((x) => x.mod_id == mod.id);
            return filteredMods.map((obj, index) => {
                if(index >= filteredMods.length - 1) {
                    return kernel.getItem(obj.menu_item_id).name;
                } else {
                    return kernel.getItem(obj.menu_item_id).name + ", ";
                }
            });
        }

        return (
            <React.Fragment>
                <div className="mod-card">
                    <div className="title">
                        <h4>{mod.name}</h4>
                    </div>
                    <div className="info-preview">
                        <p>
                            Used in: {filterMenuItemMods()}
                        </p>
                        <p>
                            Options: 
                            {
                                mod.items.map((item, index) => {
                                    if(index == 2) {
                                        return " " + item.name + ", etc...";
                                    }
                                    if(index == mod.items.length - 1) {
                                        return " " + item.name;
                                    }
                                    if(index < 2) {
                                        return " " + item.name + ",";
                                    } else {
                                        return null;
                                    }
                                })
                            }
                        </p>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    let AllModCards = function() {
        return(
            kernel.mods.map((mod) => <ModCard mod={mod} />)
        )
    }

    return (
        <div id="Modal" className="modal-main">
            <div className="modal-card add-mods">
                <div className="mods-modal-header">
                    <h2>Adding a Choice to this Item</h2>
                    <img onClick={ props.closemodal } src="/merchant-center/images/window-close.svg" alt="close modal" />
                </div>
                <div className="body-flex">
                    <div className="left-flex">
                        <h3>Create a new Choice</h3>
                        <GlobalInput displaybuttons="none" label="Name" multiline={false} />
                        <button>Continue</button>
                    </div>
                    <div className="right-flex">
                        <h3>Re-use from another Item</h3>
                        <div className="all-mods-scroll-wrapper">
                            <AllModCards />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}