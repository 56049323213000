import React, { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import ULinkService from "services/ULink"
import { ResellerInfo } from "./ResellerInfo"
import DDAconfig from "widgets/configs/DDAConfig"
import DataTable from "widgets/DataTable"
import { GlobalState } from "GlobalState"
import Reseller from "data/Reseller"

// MUI
import {
    Container,
    Paper,
    Box,
    Tab,
    Tabs,
} from "@mui/material"

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ marginTop: 2 }}>{children}</Box>}
        </div>
    )
}

export const ViewReseller = () => {
    const query = useSearchParams()[0]
    const uri = query.get("uri")
    const { context } = GlobalState.useContainer()

    const [reseller, setReseller] = useState<Reseller>(null)
    const [resellerTitle, setResellerTitle] = useState("")
    const [state, setState] = useState({})
    const [ resellerFormValid, setResellerFormValid ] = useState(false);

    const mergeState = (update: any) => {
        setState(old => Object.assign({}, old, update))
    }

    const { scrollToTop, catchMain } = GlobalState.useContainer()

    // Form Validation States
    const [ ddaFormValid, setDDAFormValid ] = useState(false);

    useEffect(() => {
        scrollToTop()
    }, [])

    useEffect(() => {
        ULinkService.resellers
            .get(query.get("uri"))
            .then(reseller => {
                setReseller(reseller)
                setResellerTitle(reseller.name)
            })
            .catch(catchMain)
    }, [uri])

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        }
    }

    const [value, setValue] = React.useState(0)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    if (!reseller) {
        return null
    }

    const $Reseller_Info = (
        <ResellerInfo
            isNew={false}
            state={Object.assign({}, reseller, state)}
            mergeState={mergeState}
            resetState={x => {
                setReseller(x)
                setState({})
            }}
            isInvitation={false}
            resellerFormValid={resellerFormValid}
            setResellerFormValid={setResellerFormValid}
        />
    )

    const $DDA_Config = <DDAconfig ddaFormValid={ddaFormValid} setDDAFormValid={setDDAFormValid} uri={reseller.uri + "/configs/mid-dda"} />

    const $Reseller_Resource = (

        <Paper
            sx={{ padding: ".5rem 0" }}
            children={
                <DataTable
                    //@ts-ignore
                    firstFetch={() => ULinkService.uploads.listResellerResources()}
                    columns={[
                        //@ts-ignore -- We don't know why jakes iterator is so broken
                        { name: "File name", selector: row => row.key}, //@ts-ignore
                        { name: "Type", selector: row => row.content_type}
                    ]}
                    onRowClicked={(row, e) => window.open("https://storage.cloud.google.com/ulink_admin/resources/resellers/" + row.key, "_blank")}
                    loadingMessage={"Loading Resources..."}
                    emptyMessage={"No Resources"}
                />
            }
        />
    )

    let tabArray
    let tabPanelArray

    if (context.type == "sionic" || context.business == resellerTitle) {
        tabArray = ["Agent Info", "DDA Configuration", "Resources"]
        tabPanelArray = [$Reseller_Info, $DDA_Config, $Reseller_Resource]
    } else {
        tabArray = ["Agent Info", "Resources"]
        tabPanelArray = [$Reseller_Info, $Reseller_Resource]
    }

    return (
        <>
            <Container>
                <section role="banner">
                    <h1 id="reseller-title">{resellerTitle}</h1>
                </section>

                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        {tabArray.map((label, i) => {
                            return <Tab key={i} label={label} {...a11yProps(i)} />
                        })}
                    </Tabs>
                </Box>

                <Box>
                    {tabPanelArray.map((child, i) => {
                        return (
                            <TabPanel key={i} value={value} index={i}>
                                <Paper sx={{ padding: "2rem" }} elevation={3} children={child} />
                            </TabPanel>
                        )
                    })}
                </Box>
            </Container>
        </>
    )
}
