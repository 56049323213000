import React, { useEffect } from "react"
import ULinkService from "services/ULink"
import { useNavigate, useSearchParams } from "react-router-dom"
import { phoneNumber } from "global-ui"
import { GlobalState } from "GlobalState"

import DataTable from "widgets/DataTable"

export default function (props) {
    const { scrollToTop } = GlobalState.useContainer()
    const uri = useSearchParams[0].get("uri")
    let navigate = useNavigate()
    useEffect(() => {
        scrollToTop()
    }, [])

    console.log(uri)

    return (
        <>
            <div id="ListCpayUsers">
                <h1>CODE Pay Users</h1>

                <DataTable
                    firstFetch={() => ULinkService.merchants.getList(uri)}
                    columns={[
                        { name: "Name", selector: row => row.name() },
                        { name: "Email", selector: row => row.email },
                        { name: "Phone", selector: row => phoneNumber(row.phone) },
                    ]}
                    onRowClicked={(row, e) => navigate("" + row.id)}
                    loadingMessage={"Loading CODE Pay Users..."}
                    emptyMessage={"No CODE Pay Users"}
                />
            </div>
        </>
    )
}
