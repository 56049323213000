import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'; 

import ULinkService from "services/ULink";
import Transaction from "lib/lib-sionic/data/Transaction";
import PopUp from "widgets/Popup";
import { timestamp, toaster } from "global-ui";


export default function(props) { 
    let params = useParams();

    let [ showPopup, setShowPopup ] = useState(false);

    // Form state

    let [ name, setName ]                       = useState(''); 
    let [ address_1, setAddress_1 ]             = useState('');
    let [ address_2, setAddress_2 ]             = useState('');
    let [ city, setCity ]                       = useState('');
    let [ region, setRegion ]                   = useState('');
    let [ zip, setZip ]                         = useState('');
    let [ created_at, setCreated_at ]           = useState(''); 

    /** @type {[ ?Transaction, function ]} */
    let [ transaction, _set_transaction ]       = useState(null);

    /**
     * Updates the transaction from a fetch, loading form state in the process
     * @param {Transaction} transaction 
    */
     let setTransaction = (transaction) => { 
        _set_transaction    (transaction);

        setName             (transaction.location.name);
        setAddress_1        (transaction.location.address_1);
        setAddress_2        (transaction.location.address_2);
        setCity             (transaction.location.city);
        setRegion           (transaction.location.region);
        setZip              (transaction.location.postal_code);
        setCreated_at       (transaction.setCreated_at);
    };

    useEffect(() => {
        props.scrollToTop();
    },[])

    //
    // Data fetching
    //

    useEffect(() => { 
        ULinkService.transactions.find(params.transaction_id)
        .then( setTransaction )
        .catch( toaster.catchULink )
    }, [])


    if(! transaction) return null;

    // If you want these fields to be editable, the input tags have been left
    // commented out for ease of conversion


    //DUMMY DATA UNTIL THE BACKEND CAN BE SORTED OUT
    const dummyObj = {
        customer_name: 'Joe Shmoe',
        customer_id: 12472628363891,
        customer_phone: '404-867-5309',
        customer_email: 'this.is.an.email@gmail.com',
        order_details: ['item 1', 'item 2', 'item 3'],
        payment_method: 'Bank',
        masked_card: 5382,
        settled_at: '11/27/2077',
        perk: 'N/A'

    }
    let items = dummyObj.order_details.map((item, i) =>{
        return (
            <p key={i}>{item}</p>
        )
    })

    return (
        <>
            { showPopup ? <PopUp setShowPopup={setShowPopup} /> : null }

            <main id="ViewTransaction">
                <section role="banner">
                    <h1>{ transaction.location.name } @ {transaction.location.address_1} </h1>
                </section>

                <hr/>

                <div className="paneled-grid">
                    
                    <section id="basic">
                        <div className="header">
                            <h2>Order Information</h2>
                        </div>
                        <hr/>
                        <table id="basic-edit" className='bordered transact-detail-table'>
                            <tbody>
                                <tr>
                                    <td>Customer Name</td>
                                    <td>
                                        <p>{dummyObj.customer_name}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Customer ID</td>
                                    <td>
                                        <p>{dummyObj.customer_id}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Customer Phone</td>
                                    <td>
                                        <p>{dummyObj.customer_phone}</p>
                                    </td>
                                </tr> 
                                <tr>
                                    <td>Customer Email</td>
                                    <td>
                                        <p>{dummyObj.customer_email}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Order Details</td>
                                    <td>
                                        {items}
                                    </td>
                                </tr> 
                                <tr>
                                    <td>Cashier Name</td>
                                    <td>
                                        <p>{transaction.cashier_name || "Not listed"}</p>
                                    </td>
                                </tr> 
                            </tbody>
                        </table>
                    </section>

                    <section id='basic'>
                        <div className="header">
                            <h2>Transaction Information</h2>
                        </div>
                        <hr/>
                        <table id="basic-edit" className='bordered transact-detail-table'>
                                <tbody>
                                    <tr>
                                        <td>Date & Time</td>
                                        <td> 
                                            <p>{ timestamp(transaction.created_at) }</p> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Payment Method</td>
                                        <td>
                                            <p>{`${dummyObj.payment_method} ${dummyObj.masked_card}`}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Settlement Date</td>
                                        <td>
                                            <p>{dummyObj.settled_at}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Subtotal</td>
                                        <td> 
                                            <p>$ {parseFloat(transaction.subtotal).toFixed(2)}</p> 
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td>Tax</td>
                                        <td> 
                                            <p>$ {!transaction.tax ? parseFloat(0.00).toFixed(2) : parseFloat(transaction.tax).toFixed(2)}</p> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Tip</td>
                                        <td> 
                                            <p>$ {!transaction.tip ? parseFloat(0.00).toFixed(2) : parseFloat(transaction.tip).toFixed(2)}</p> 
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td>Fees</td>
                                        <td> 
                                            <p>$ {parseFloat(transaction.fees).toFixed(2)}</p> 
                                        </td>
                                    </tr> 
                                    <tr>
                                        <td>Total</td>
                                        <td> 
                                            <p>$ {parseFloat(transaction.total).toFixed(2)}</p> 
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Perk</td>
                                        <td> 
                                            <p>{dummyObj.perk}</p> 
                                        </td>
                                    </tr>                                   
                                    <tr>
                                        <td>Transaction ID</td>
                                        <td>
                                            <p>{transaction.location.id}</p>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                    </section>

                    <section id="basic">
                        <div className="header">
                            <h2>Location Information</h2>
                        </div>
                        <hr/>
                        <table id="basic-edit" className='bordered transact-detail-table'>
                            <tbody>
                                <tr>
                                    <td>Name</td>
                                    <td>
                                        <p>{name}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>
                                        <p>{address_1} {address_2}, {city}, {region} {zip}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Location ID</td>
                                    <td>
                                        <p>{transaction.id}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </section>
                </div>
        </main>
        </>
    )
}