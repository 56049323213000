import Big from 'big.js';

import Hours from './Hours'; 
import Order, { OrderItem, OrderMod } from './Order';

import { currency } from '../utils';

/**
 * This class contains **all** Menu information pertaining to a specific location, 
 * as well as methods to get data about specific ordering options within the context of this location's menus. 
 * 
 * This class does most of the heavy lifting with regards to data searching, 
 * and performs most computations that involve reading data between classes.  
 * 
 * This is not to be confused with the specific concept of a "root-level menu",
 * which is the highest-level grouping of menu items and sections that have their own specific properties,
 * nor a "menu section" which are the sections within the root-level menus.
 * 
 * This is a bit confusing and perhaps could use a rename 
 */
export default class Menu { 

    /** 
     * @typedef {object} MenuNest 
     * @property {string} parent ID of the parent menu
     * @property {string} child ID of the child menu
     */

    /**
     * @typedef {object} MenuJson 
     * @property {object} location Location data from the SMB service; shouldn't really be used here 
     * @property {[object]} menus Root-level menus; already sorted by position from the API 
     * @property {[object]} menu_sections Child menus, joined by `menu_nest` 
     * @property {[object]} menu_items Orderable items belonging to a specific menu or section; already sorted by position from the API 
     * 
     * @property {[MenuNest]} menu_nest Pairs of parent/child menu sections; already sorted by position from the API 
     * 
     * @property {[object]} mods 
     * @property {[object]} mod_items
     * @property {[ { menu_item_id: string, mod_id: string }]} menu_item_mods 
     * 
     * @property {[ { id: string, global: boolean} ]} taxes 
     * @property {[ { menu_item_id: string, tax_id: string } ]} menu_item_taxes
     */

    /**
     * @param {MenuJson} json 
     */
    constructor(json) { 

        this.hours = new Hours( json.hours ); 

        /** @type {MenuJson} */
        this.menu = json

        /** @type {[Tax]} */
        this.globalTaxes = json.taxes
            .filter(tax => tax.global)
            .map(json => new Tax(json))
    }


    /**
     * Top-level menus 
     * @returns {[MenuSection]}
     */
    menus() { 
        return this.menu.menus.map(json => new MenuSection(json))
    }

    /**
     * Get menu section by ID; **either** a root-level OR nested section
     * 
     * This function currently searches through both, 
     * but this may have to change if we make the root-level sections a different class
     * 
     * @param {string} id 
     * @returns {MenuSection}
     */
    getSection(id) { 
        let json = this.menu.menu_sections.find(x => x.id === id) 
        if(json) return new MenuSection(json) 

        json = this.menu.menus.find(x => x.id === id) 
        if(!json) throw `No such menu section #${id}`
        else return new MenuSection(json)
    }

    /**
     * Sections within the given menu section ID
     * @param {string} id ID of the parent section, in which to look for chidlren
     * @returns {[MenuSection]}
     */
    menuSections(id) { 
        let child_ids = this.menu.menu_nest.filter(x => x.parent == id).map(x => x.child)
        return this.menu.menu_sections
            .filter( sec =>  child_ids.includes(sec.id) )
            .map(json => new MenuSection(json))
    }

    /**
     * Get a menu item by ID, or throw if this item doesn't exist
     * @param {string} id 
     * @returns {?MenuItem}
     */
    getMenuItem(id) { 
        let json = this.menu.menu_items.find(json => json.id === id) 
        return new MenuItem(json)
    }

    /**
     * Items within the given menu
     * @param {string} id 
     * @returns {[MenuItem]}
     */
    menuItems(id) { 
        return this.menu.menu_items
            .filter(x => x.menu_id === id)
            .map(json => new MenuItem(json)) 
    }


    /**
     * Checks if the menu section, or any of its ancestors, are unavailable at the current time for any reason.
     * @param {string} menu_id 
     * @returns {boolean}
     */
    isMenuSectionAvailableNow(menu_id) { 
        if(! this.getSection(menu_id).isAvailableNow()) return false; 

        let pair = this.menu.menu_nest.find( x => x.child == menu_id )
        if(pair) return this.isMenuSectionAvailableNow(pair.parent) 
        else return true 
    }

    /**
     * Checks if the menu item, or any of its parent menu sections, are unavailable at the current time for any reason.
     * @param {string} item_id 
     * @returns {boolean}
     */
    isMenuItemAvailableNow(item_id) { 
        let item = this.getMenuItem(item_id) 
        if(! item) throw "No such menu item " + item_id
        return item.available && this.isMenuSectionAvailableNow(item.menu_id)
    }


    /**
     * Filter this menu for items matching the given text 
     * @param {string} query 
     * @returns {[{ section: MenuSection, item: MenuItem }]} An array of items grouped with their section
     */
    filterItems(query) { 
        return this.menu.menu_items
            .filter(   menuItem => menuItem.name.toLowerCase().includes( query.toLowerCase() )   )
            .map( filteredItem => { 
                let newItem = new MenuItem(filteredItem) 
                return { 
                    item: newItem, 
                    section: this.getSection(newItem.menu_id), 
                }
            })
    }


    /**
     * Legacy method to get all 2nd-level menu sections.
     * This may be removed if we change display for root-level menus. 
     * @returns {[MenuItem]}
     */
    allCategories() { 
        const root_ids = this.menu.menus.map(x => x.id)
        return this.menu.menu_nest
            .filter(x => root_ids.includes(x.parent))
            .map(nest => { 
                let json = this.menu.menu_sections.find(obj => obj.id === nest.child) 
                if(! json) throw `Could not find menu section #${nest.child}`
                return new MenuSection(json) 
            })
    }


    getMenuItemMods(item_id) { 
        const mod_ids = this.menu.menu_item_mods
            .filter(x => x.menu_item_id === item_id) 
            .map(pair => pair.mod_id)

        return this.menu.mods 
            .filter(x => mod_ids.includes(x.id))
            .map(json => new Mod(json))
    }


    /**
     * Returns the `Mod` that determines the menu item's pricing, if applicable.
     * 
     * We plan on replacing this concept with a more intentional "sizing options" feature in the menu builder.
     * For now, the "pricing mod" is the first mod where the below conditions are satisfied, if any:
     * 
     *  * The `MenuItem` must have a base price of zero 
     *  * The mod must have a minimum and maximum selection quantity of 1 (i.e., exactly 1) 
     *  * All items on the mod must have a nonzero price
     * 
     * @param {string} item_id ID of the item to search 
     * @returns {Mod} or `null` if no pricing mod exists
     */
    getItemPricingMod(item_id) {
        const ZERO = new Big(0)

        const item = this.getMenuItem(item_id) 
        if(item.price.gt(ZERO)) return null 

        for (const mod of this.getMenuItemMods(item_id)) {
            if(!(mod.sel_min == 1 && mod.sel_max == 1)) continue 
            if( this.getModOptions(mod.id).find(item => item.price.eq(ZERO)) ) continue 
            return mod 
        }

        return null 
    }

    /**
     * Returns the lowest price of the pricing mod for the given item, if any
     * 
     * See `getItemPricingMod` 
     * @param {string} item_id ID of the item to search
     * @returns {Big} or `null` if no pricing mod is found for the given item
     */
    getItemStartingPrice(item_id) { 
        const mod = this.getItemPricingMod(item_id)
        if(! mod) return null 

        return this.getModOptions(mod.id).reduce((prev, next) => prev.price.lt(next.price) ? prev : next).price
    }


    /**
     * Gets the list of nested mod options for the specified mod item
     * 
     * (Not yet implemented; returns empty array)
     * @param {string} mod_item_id 
     * @returns {[Mod]}
     */
    getNestedMods(mod_item_id) { 
        //TAG: [ISSUE #5]
        return [] 
    }

    /**
     * 
     * @param {string} mod_id 
     * @returns {Mod}
     */
    getMod(mod_id) { 
        return this.menu.mods
            .find(x => x.id === mod_id) 
    }
    
    /**
     * @param {string} mod_item_id 
     * @returns {ModItem}
     */
    getModItem(mod_item_id) { 
        let json = this.menu.mod_items
            .find(x => x.id === mod_item_id) 
        if(! json) throw `No such mod item #${mod_item_id}`
        return new ModItem(json)
    }

    /**
     * 
     * @param {string} mod_id 
     * @returns {[ModItem]} The possible options for this `mod_id`
     */
    getModOptions(mod_id)  { 
        return this.menu.mod_items
            .filter(item => item.mod_id === mod_id)
            .map(json => new ModItem(json))
    }

    /**
     * Gets the **non-global** ("special") taxes applicable to a menu item
     * 
     * For global taxes, use `this.globalTaxes`
     * @param {string} item_id 
     * @returns {[Tax]}
     */
    getMenuItemTaxes(item_id) { 
        const tax_ids = this.menu.menu_item_taxes
            .filter(x => x.menu_item_id === item_id) 
            .map(x => x.tax_id)

        return this.menu.taxes 
            .filter(x => tax_ids.includes(x.id))
            .map(json => new Tax(json)) 
    }



    // 
    // Order calculations
    // 


    /**
     * Calculates the **subtotal price** (excluding taxes and what not) for the given OrderItem configuration.
     * 
     * Be aware that this does not perform any validation on the `item`. 
     * @param {OrderItem} orderItem 
     */
    calcOrderItemSubtotal(orderItem) { 
        const menu = this 
        
        /**
         * Recursively sums the price of mods within this `OrderItem`
         * @param {[OrderMod]} mods 
         * @returns {Big}
         */
        function sumMods(mods) { 
            return mods.map( mod => { 

                return mod.selections  
                    .map( sel => { 
                        let item = menu.getModItem(sel.mod_item_id)

                        return item.price
                            .add( sumMods(sel.mods) )
                            .mul( sel.quantity )
                    })
                    .reduce( (prev, next) => prev.add(next), new Big(0) )

            }).reduce( (prev, next) => prev.add(next), new Big(0) )
        }

        return menu.getMenuItem(orderItem.menu_item_id)
            .price
            .add( sumMods(orderItem.mods) )
            .mul( orderItem.quantity )
    }

    /**
     * Computes the subtotal of the item with `calcOrderItemSubtotal`,
     * and applies the appropriate taxes to it, 
     * returning both separately for display purposes.  
     * @param {OrderItem} orderItem 
     * @returns {{ sub: Big, tax: Big }}
     */
    calcOrderItemWithTax(orderItem) { 
        let sub = this.calcOrderItemSubtotal(orderItem) 

        let tax = this.globalTaxes.concat( this.getMenuItemTaxes(orderItem.menu_item_id) ) 
            .map( tax => tax.rate.mul(sub) )
            .reduce( (a, b) => a.add(b), new Big(0)) 

        return { sub, tax } 
    }


    /**
     * Calculates the subtotal by summing over all `OrderItem` subtotals.
     * @param {Order} order 
     * @returns {Big}
     */
    calcOrderSubtotal(order) { 
        return currency(
            order.items 
            .map( item => this.calcOrderItemSubtotal(item) )
            .reduce( (x, y) => x.add(y), new Big(0) )
        )
    }

    /**
     * Calculates the subtotal and total applicable tax by summing over all `OrderItem`s via `calcOrderItemWithTax`,
     * returning them separately for display purposes. 
     * @param {Order} order 
     * @returns {{ sub: Big, tax: Big }}
     */
    calcOrderWithTax(order) { 
        const calcs = order.items
            .map( item => this.calcOrderItemWithTax(item) )
            .reduce( 
                (x,y) => ({ sub: x.sub.add(y.sub), tax: x.tax.add(y.tax) }),
                { sub: new Big(0), tax: new Big(0) }
            )
        
        return { 
            sub: currency( calcs.sub ), 
            tax: currency( calcs.tax ),  
        }
    }
}



/**
 * Class representing a menu section **OR** a top-level menu
 * 
 * Currently these two things share the same data from the API; this is subject to change in the future 
 */
export class MenuSection { 
    constructor(json) { 
        /** @type {string} */
        this.id = json.id 

        /** @type {string} */
        this.name            = json.name
        /** @type {string} */
        this.description     = json.description

        /** @type {boolean} */
        this.available       = json.available

        this.hours           = new Hours(json.hours)
    }

    isAvailableNow() { return this.available && this.hours.isAvailableNow() }
}

export class MenuItem { 
    constructor(json) { 
        /** @type {string} */
        this.id = json.id 

        /**
         * ID of the parent MenuSection (which could also be a root-level menu) 
         * @type {string}
         */
        this.menu_id = json.menu_id

        /** @type {string} */
        this.name                   = json.name 
        /** @type {string} */
        this.description            = json.description 


        /** @type {Big} */
        this.price                  = new Big(json.price)

        /**
         * `false` if the menu item is marked as unavailable 
         * @type {boolean} 
         */
        this.available              = json.available 

        /**
         * `true` if custom instructions are allowed for this item
         * @type {boolean}
         */
        this.custom_instructions    = json.custom_instructions 


        /** @type {?string} */
        this.image_url = json.image_url 
    }
}


export class Mod { 
    constructor(json) { 
        /** @type{string} */
        this.id         = json.id
        /** @type{string} */
        this.name       = json.name

        /**
         * The minimum number of items in this mod that must be selected
         * (may be zero)
         * @type {number}
         */
        this.sel_min    = json.sel_min

        /**
         * The maximum number of items in this mod that may be selected,
         * or null if there is no maximum
         * @type {?number}
         */
        this.sel_max    = json.sel_max
    }

    //TODO: Validation
}

export class ModItem { 
    constructor(json) { 
        /** @type {string} */
        this.id             = json.id
        /** @type {string} */
        this.mod_id         = json.mod_id

        /** @type {string} */
        this.name           = json.name
        /** @type {boolean} */
        this.available      = json.available

        /** @type {Big} */
        this.price          = new Big(json.price)
    }
}

export class Tax { 
    constructor(json) { 
        /** @type {string} */
        this.id         = json.id
        /** @type {string} */
        this.name       = json.name

        /** @type {Big} */
        this.rate       = new Big(json.rate)

        /**
         * If `true`, this tax applies to every menu item in addition to its special taxes
         * @type {boolean} 
         */
        this.global     = json.global
    }
}