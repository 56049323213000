import CpayUser from "./CpayUser"
import Incentive from "./Incentive"

import { entity_location_statuses } from "globals"

/**
 * A merchant, represented by a ULink entity. 
 */
export default class Merchant { 


    constructor(obj) { 
        if(! obj) obj = { 
            status: Object.keys(entity_location_statuses)[0],

            name: "", 
            address_1: "", 
            address_2: "", 
            city: "", 
            region: "", 
            zip: "", 

            _links: {}
        }

        // https://paste.ubuntu.com/p/4Gb5FbWdvM/

        /** @type {string} */
        this.id = obj.id 
        /** @type {string} */
        this.uri = obj.uri 

        /** @type {number} */
        this.status = obj.status

        /** @type {string} */
        this.name = obj.name 
        /** @type {string} */
        this.address_1 = obj.address_1
        /** @type {string} */
        this.address_2 = obj.address_2
        /** @type {string} */
        this.city = obj.city 
        /** @type {string} */
        this.region = obj.region 
        /** @type {string} */
        this.zip = obj.zip 
        

        this.created_at = new Date(obj.created_at)
        this.updated_at = new Date(obj.updated_at)

        /**
         * @type {{ locations: string, codepay_users: string, transactions: string }} 
         * @readonly
         */
        this._links = obj._links 
    }


    /**
     * Returns the full address as a one-line string,
     * or `null` if there are no address fields set. 
     * @returns {?string}
     */
    fullAddress() { 
        const res = [ this.address_1, this.address_2, this.city, this.region, this.postal_code ].filter(x => x).join(', ') 
        return res == '' ? null : res 
    }
}



/**
 * Represents a **CODEPAY** user belonging to a merchant.
 * 
 * This will have to be expanded if we have non-codepay users
 */
export class MerchantUser { 
    constructor(obj) { 
        /** @type {string} */
        this.uri = obj.uri 

        /**
         * @type {number}
         */
        this.role           = obj.role 

        /**
         * @type {CpayUser} 
         */
        this.cpay_user      = new CpayUser(obj.cpay_user)
    }
}