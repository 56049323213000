import React, { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import ULinkService from "services/ULink"
import { ResellerGroup } from "data/ResellerGroup"
import { GlobalState } from "GlobalState"

import DataTable from "widgets/DataTable"

import * as GlobalUI from "global-ui"
import Reseller from "data/Reseller"
import { Button, Paper } from "@mui/material"

export const ViewResellerGroup = () => {
    const navigate = useNavigate()
    const uri = useSearchParams()[0].get("uri")
    const { scrollToTop, setIsLoading, context } = GlobalState.useContainer()

    const [group, setGroup] = useState<ResellerGroup>(null)

    useEffect(() => {
        scrollToTop()

        setIsLoading(true)
        ULinkService.getInterface<ResellerGroup>(uri)
            .then(g => {
                setGroup(g)
            })
            .catch(GlobalUI.toaster.catchULink)
            .finally(() => setIsLoading(false))
    }, [])

    const inviteReseller = () => {
        navigate("/reseller/invite?name=agent&uri=" + context.invite_links.reseller)
    }

    if (!group) return null

    return (
        <>
            <div id="ListMerchants">
                <div className="header-and-button">
                    <h1>All Agents</h1>
                    {/* {context.type === "sionic" && (
                        <>
                            <TextField
                                type="number"
                                id="outlined-required"
                                label="Revenue Share Rate %"
                                value={rev_share_rate}
                                required={true}
                                margin="normal"
                                onChange={e => set_rev_share_rate(e.target.value)}
                                onBlur={formatInput}
                            />
                            <Button onClick={updateRevShare} variant="outlined">
                                Update Share Rate
                            </Button>
                        </>
                    )} */}
                    <Button
                        variant="outlined"
                        onClick={() => {
                            navigate("/reseller/invite?name=agent&uri=" + group._links.invite)
                        }}
                        id="invite-agent"
                    >
                        Invite Agent
                    </Button>
                </div>

                <Paper
                    sx={{ padding: ".5rem 0" }}
                    children={
                        <DataTable
                            firstFetch={() =>
                                ULinkService.resellers.getResellerGroupMembers(group._links.members)
                            }
                            columns={[{ name: "Business Name", selector: row => row.name }]}
                            onRowClicked={(row, e) => navigate("/reseller?uri=" + row.uri)}
                            loadingMessage={"Loading Resellers..."}
                            emptyMessage={"No Resellers"}
                        />
                    }
                />
            </div>
        </>
    )
}
