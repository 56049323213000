import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import ULink from "services/ULink"
import DataTable from "widgets/DataTable"
import { currency, timestamp } from "global-ui"
import { GlobalState } from "GlobalState"

// MUI
import { Container, Paper } from "@mui/material"

export const ListTransactions = () => {
    const navigate = useNavigate()

    const { scrollToTop } = GlobalState.useContainer()
    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <>
            <Container id="ListTransactions">
                <header>
                    <h1 id="transactions-header">All Transactions</h1>
                </header>

                <Paper
                    sx={{ padding: "2rem" }}
                    children={
                        <DataTable
                            firstFetch={ULink.transactions.all}
                            columns={[
                                { name: "Location", selector: row => row.location.name },
                                { name: "Total", selector: row => currency(row.total) },
                                { name: "Date & Time", selector: row => timestamp(row.created_at) },
                                { name: "Transaction ID", selector: row => row.id },
                                { name: "Location ID", selector: row => row.location_id },
                            ]}
                            onRowClicked={(row, e) => navigate("" + row.id)}
                            loadingMessage={"Loading Transactions..."}
                            emptyMessage={"No Transactions"}
                        />
                    }
                />
            </Container>
        </>
    )
}
