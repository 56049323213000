import Admin from './Admin';

export default class ContextUser {
    uri: string
    user: Admin
    roleValue: number

    constructor(obj: any){
        this.uri = obj.uri
        this.user = new Admin(obj.user)
        this.roleValue = obj.role_value
    }

}