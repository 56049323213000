import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom'; 

import ULinkService from "services/ULink";
import Admin from "data/Admin";
import { admin_statuses } from "globals";
import { GlobalState } from "GlobalState"

import PopUp from "widgets/Popup";

export default function(props) {
    const { scrollToTop } = GlobalState.useContainer()

    let navigate = useNavigate();

    let params = useParams();

    let [ showPopup, setShowPopup ] = useState(false);

    // Form state
    let [ name, setName ]       = useState(''); 
    let [ email, setEmail ]     = useState(''); 
    let [ status, setStatus ]   = useState(''); 


    /** @type {[ ?Location, function ]} */
    let [ admin, _set_admin ]  = useState(null);

     /**
     * Updates the admin from a fetch, loading form state in the process
     * @param {Admin} admin 
    */
      let setAdmin = (admin) => { 
        _set_admin      (admin)

        setName         (admin.name);
        setEmail        (admin.email);
        setStatus       (admin.status);
    };

    useEffect(() => {
        scrollToTop();
    },[])


    //
    // Data fetching
    //

    useEffect(() => {
        ULinkService.admins.find(params.admin_id)
        .then( setAdmin )
        .catch(err => {
            console.log(err);
            ULinkService.notifyError()
        });
    }, []);

    //
    // Data updating
    //

    function updateAdmin() { 
        admin.name       = name;
        admin.email      = email;
        admin.status     = status;

        ULinkService.update(admin)
            .then( res => {
                if(!res.ok) ULinkService.notifyError(res.status);
                if(res.ok) {
                    ULinkService.notifySucess();
                    return res.json();
                }
            })
            .then( json => {
                setAdmin( new Admin(json));
                navigate(-1);
            } )
            .catch( err => { 
                console.error(err);
                ULinkService.notifyError();
            });
    }

    

    if(! admin) return null; 

    return(
    <>
        { showPopup ? <PopUp setShowPopup={setShowPopup} /> : null }
        <main id="ViewAdmin">


            <section role="banner">
                <h1> Administrator: { admin.name } </h1>
            </section>

            <div className="paneled-grid">

                <section id="basic">
                    <table id="basic-edit">
                        <tbody>
                            <tr>
                                <td>Name</td>
                                <td>
                                    <input type="text" value={ name } onChange={ (e) => setName(e.target.value) } />
                                </td>
                            </tr>
                            <tr>
                                <td>Email</td>
                                <td>
                                    <input type="text" value={ email } onChange={ (e) => setEmail(e.target.value) } />
                                </td>
                            </tr>
                            <tr>
                                <td>Admin ID</td>
                                <td>
                                    <p>{admin.id}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>
                                    <select value={ status } onChange={ (e) => setStatus(e.target.value) } >
                                        { Object.keys(admin_statuses).map( num =>   <option key={num} value={num}>{ admin_statuses[num] }</option>   ) }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <button className="primary btn-size-lg" onClick={ updateAdmin }>Save</button>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2">
                                    <button className="btn-size-lg destructive" onClick={ () => navigate(-1) }>Cancel</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>

            </div>


        </main>
    </>
    )}