import React, { useState, useEffect } from "react"
import { ulink } from "services/ULink"
import { toaster } from "global-ui"
import { useParams, useSearchParams } from "react-router-dom"
import { GlobalState } from "GlobalState"

// MUI
import { Button, Container, Paper } from "@mui/material"

interface Props {}

export const CreateInvite: React.FC<Props> = ({}) => {
    const [sharableLink, setSharableLink] = useState<string>(null)
    const [isSingleUse, setSingleUse] = useState(false)

    const { scrollToTop, context } = GlobalState.useContainer()
    const { inviteType } = useParams() as {
        inviteType: "reseller" | "merchant" | "user"
    }
    const searchParams = useSearchParams()[0]
    const uriParam = searchParams.get("uri")

    let uri = null
    let displayType: string = searchParams.get("name") ?? inviteType

    if (uriParam) uri = searchParams.get("uri")
    else
        switch (inviteType) {
            case "reseller":
                uri = context.invite_links.reseller
                break
            case "merchant":
                uri = context.invite_links.merchant
                console.log(uri)
                break
            case "user":
                uri = context.invite_links.user
                break
        }
    if (!uri) {
        throw "Unrecognized invite type"
    }

    const getSharableLink = () => {
        ulink(uri, { method: "POST" })
            .then(res => res.json())
            .then(res => {
                const address = `${location.protocol}//${
                    location.host
                }/invites/${inviteType}?uri=${encodeURIComponent(res.uri)}`
                setSharableLink(address)
                setSingleUse(res.singleUse)
            })
            .catch(toaster.catchULink)
    }

    useEffect(() => {
        scrollToTop()
        getSharableLink()
    }, [])

    return (
        <Container>
            <header>
                <h1>Invite a new {displayType}</h1>
            </header>
            <Paper
                sx={{ padding: "2rem" }}
                children={
                    <>
                        <h2>
                            Share this link with the
                            {inviteType == "reseller" ? " agents" : ` ${inviteType}s`} you want to
                            invite
                        </h2>
                        <div
                            id="share-link"
                            className="sharable-link-box"
                            style={{ overflow: "auto", overflowWrap: "break-word" }}
                        >
                            {sharableLink}
                        </div>

                        {sharableLink && (
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    navigator.clipboard.writeText(sharableLink);
                                    toaster.copiedToClipboard("Link");
                                }}
                            >
                                Copy to clipboard
                            </Button>
                        )}
                    </>
                }
            />
        </Container>
    )
}
