import * as React from "react"
import { Link } from "react-router-dom"

// Class Data
import { LinkType, SessionContextLink } from "data/Admin"

// ENV vars
import { ENV } from "../globals"

//Material Icons
import BusinessIcon from "@mui/icons-material/Business" // Businesses
import DeviceHubIcon from "@mui/icons-material/DeviceHub" // Resellers
import StoreIcon from "@mui/icons-material/Store" // Locations
import PaidIcon from "@mui/icons-material/Paid" // Transactions
import AccountCircleIcon from "@mui/icons-material/AccountCircle" // User / Global User
import PeopleIcon from "@mui/icons-material/People" //Agents "Reseller Group"
import Groups2Icon from "@mui/icons-material/Groups2" // Users
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts" // Profile
import SettingsIcon from "@mui/icons-material/Settings"

// MUI Components
import {
    Typography,
    Box,
    Drawer,
    CssBaseline,
    AppBar,
    Toolbar,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Button,
    Link as MUILink, //React Router already has a Link component, we use this for the MUI styles
} from "@mui/material"

// Global State
import { GlobalState } from "GlobalState"

const drawerWidth = 240

function NavLink(props: { href: string; icon: JSX.Element; display: string; id?: string }) {
    return (
        <Link style={{ textDecoration: "none" }} to={props.href} id={props.display}>
            <ListItem disablePadding color="primary">
                <ListItemButton>
                    <ListItemIcon sx={{ ".MuiListItemIcon-root": { color: "black" } }}>
                        {props.icon}
                    </ListItemIcon>
                    <ListItemText primary={props.display} />
                </ListItemButton>
            </ListItem>
        </Link>
    )
}

function SessionLinkElement(link: SessionContextLink): JSX.Element {
    function icon(type: LinkType) {
        switch (type) {
            case "location":
                return <StoreIcon />
            case "transaction":
                return <PaidIcon />

            case "merchant":
                return <BusinessIcon />
            case "reseller":
                return <DeviceHubIcon />
            case "reseller-group":
                return <PeopleIcon />

            case "user":
                return <Groups2Icon />
            case "global-user":
                return <AccountCircleIcon />

            default:
                console.warn("No link icon for type " + type)
                return null
        }
    }
    return (
        <NavLink
            icon={icon(link.type)}
            display={link.name}
            href={link.getHref()}
            id={link.name}
            key={link.name}
        />
    )
}

export default function NavDrawer() {
    const { context } = GlobalState.useContainer()

    return (
        <>
            {context && (
                <Box sx={{ display: "flex" }} id="nav-drawer">
                    <AppBar
                        position="fixed"
                        sx={{ zIndex: theme => theme.zIndex.drawer + 1, height: "4em" }}
                    >
                        <Toolbar>
                            <img
                                style={{
                                    height: "4em",
                                }}
                                src="/img/new-ulink-logo.png"
                                alt="ULink logo"
                            />
                        </Toolbar>
                    </AppBar>
                    <Drawer
                        variant="permanent"
                        sx={{
                            width: drawerWidth,
                            flexShrink: 0,
                            [`& .MuiDrawer-paper`]: {
                                width: drawerWidth,
                                boxSizing: "border-box",
                                background: "#ffffff",
                            },
                        }}
                    >
                        <Toolbar />
                        <Box
                            sx={{
                                overflow: "auto",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                height: "100%",
                            }}
                        >
                            <List>
                                {context.links.map(SessionLinkElement)}

                                {context.type == "sionic" && (
                                    <NavLink
                                        id="system-config"
                                        display="System Configuration"
                                        icon={<SettingsIcon />}
                                        href="/system-configuration"
                                    />
                                )}

                                <NavLink
                                    id="profile-link"
                                    display="Profile"
                                    icon={<ManageAccountsIcon />}
                                    href="profile?uri=/v2/admin/session/user/"
                                />
                            </List>

                            <div className="flex-column padding-16px">
                                <MUILink
                                    color="secondary"
                                    href="https://sionic.io/agreements/privacy-policy.html"
                                    target="_blank"
                                >
                                    Privacy Policy
                                </MUILink>
                                <MUILink
                                    color="secondary"
                                    href="https://sionic.io/agreements/merchant-tos.html"
                                    target="_blank"
                                >
                                    Terms of Service
                                </MUILink>
                                <Typography
                                    mt=".25rem"
                                    align="left"
                                    variant="body1"
                                    sx={{ fontSize: "0.6em" }}
                                >
                                    Copyright 2022 | Sionic Mobile Corporation. All Rights Reserved
                                </Typography>
                            </div>
                        </Box>
                    </Drawer>
                </Box>
            )}
        </>
    )
}
