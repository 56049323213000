import React, {useEffect, useState} from "react";
import { InputValidator, validateInput } from "./InputValidator";

/**
 * 
 * @param props 
 * @description Wraps TextInput.tsx components and can tell parent component if all fields are valid.
 * @returns props.children
 */
const FormValidator = (props: {
    children        :any,
    fieldsValid     :boolean,
    setFieldsValid: (valid: boolean) => void,
}) => {
    useEffect(() => {
        const fieldsAreValid: boolean = testAllFieldsValidState();
        props.setFieldsValid(fieldsAreValid);
    }, [props.children]);

    const testAllFieldsValidState = () => {
        const validComponents: boolean[] = props.children.map((inputComponent: any) => {
            const inputType     :string = Object.keys(InputValidator).find((key) => key === inputComponent.props.type);
            const fieldIsValid  :boolean = inputComponent.props.required ? validateInput(inputType, inputComponent.props.value) : true; // if the field isn't required, we don't need to validate (this might be wrong but sue me, future me)
            return fieldIsValid;
        });
        return !validComponents.includes(false); // the "includes" method returns true if any vals are false so we reverse the boolean for state value readability
    }

    return <>{props.children}</>
}

export default FormValidator;