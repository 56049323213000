import React, { useState } from "react"

import Loader from "widgets/Loader"

import { toaster } from "global-ui";
import { Session } from "data/Admin"
import { GlobalState } from "GlobalState"

import { Button, TextField, Typography } from "@mui/material"

interface Props {}

export const LogIn: React.FC<Props> = () => {
    const [email, setEmail] = useState("")
    const [pass, setPass] = useState("")

    const { isLoading, setIsLoading, setSession } = GlobalState.useContainer()

    const doLogIn = () => {
        setIsLoading(true)
        fetch("/ulink/log-in", {
            method: "POST",
            body: JSON.stringify({ email, password: pass }),
            headers: { "Content-Type": "application/json" },
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw new Error(res.statusText)
                }
            })
            .then(json => setSession(new Session(json)))
            .catch(err => {
                setIsLoading(false)
                toaster.invalidCredentials();
                console.log(err)
            })
    }

    const logInJSX = () => {
        return (
            <>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: "1",
                        alignItems: "center",
                    }}
                >
                    <header>
                        <img
                            style={{ width: "200px" }}
                            src="/img/ulink-logo.png"
                            alt="ULink Logo"
                        />
                        <Typography color="secondary" sx={{ fontSize: "2em", fontWeight: "bold" }}>
                            Admin Portal
                        </Typography>
                    </header>

                    <div id="LoginLeft">
                        <TextField
                            id="email"
                            label="Email"
                            value={email}
                            margin="normal"
                            onKeyPress={e => {
                                e.key === "Enter" && doLogIn()
                            }}
                            onChange={e => setEmail(e.target.value)}
                        />

                        <TextField
                            id="password"
                            label="Password"
                            type="password"
                            value={pass}
                            margin="normal"
                            onKeyPress={e => {
                                e.key === "Enter" && doLogIn()
                            }}
                            onChange={e => setPass(e.target.value)}
                        />

                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                            }}
                        >
                            <a href="/forgot-password">Forgot Password?</a>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={doLogIn}
                                id="login-button"
                            >
                                Log In
                            </Button>
                        </div>
                    </div>
                </div>

                <div id="LoginRight">
                    <img
                        src="/img/login-bg.png"
                        alt="digital art photo of transparent light squares converging on a bright white light in the distance"
                    />
                </div>
            </>
        )
    }

    return <div id="LogIn">{isLoading ? <Loader title="Logging in" /> : logInJSX()}</div>
}
