import { 
    containsOnlyLetters,
    containsOnlyNumbers,
    emailValid
 } from "./regex/validation";

export const helperText = {
    generic: {
        default: "Field must not be empty"
    },
    accountNumber: {
        default: "Must be 8-17 numerical digits - (no letters, spaces)"
    },
    routingNumber: {
        default: "Must be exactly 9 numerical digits - (no letters, spaces)"
    },
    password: {
        default: "Password must be eight characters or longer",
        doNotMatch: "Passwords do not match"
    },
    email: {
        default: "Your email must match the standard email formatting",
        inUse: "The email you entered is already in our records",
    },
    addressZip: {
        default: "Zip code must be exactly five numerical digits",
    },
    addressRegion: {
        default: "State/Region must be exactly two letters",
    }
}


export const InputValidator = {
    generic: {
        validate: (value: any) => value ? true : false,
    },
    accountNumber: {
        validate: (value: any) => {
            if(value.length < 7 || value.length > 17) return false;
            else if(!containsOnlyNumbers(value)) return false;
            else return true;
        }
    },
    routingNumber: {
        validate: (value: any) => {
            if(value.length !== 9) return false;
            else if(!containsOnlyNumbers(value)) return false;
            else return true;
        }
    },
    password: {
        validate: (value: any) => {
            return value.length >= 8 ? true : false;
        },
    },
    email: {
        validate: (value: any) => emailValid(value) ? true : false,
    },
    addressZip: {
        validate: (value: any) => {
            if(value.length !== 5) return false;
            else if(!containsOnlyNumbers(value)) return false;
            else return true;
        },
    },
    addressRegion: {
        validate: (value: any) => {
            if(value.length !== 2) return false;
            else if(!containsOnlyLetters) return false;
            else return true;
        },
    }
}


export const validateInput = (type: string, input: any): boolean => {
    return InputValidator[type || "generic"].validate(input);
};