import { ulid } from 'ulid';

import Menu, { Mod } from './Menu';


/**
 * Represents an in-progress order; for completed orders see `OrderReceipt`
 * 
 * Probably should have been named "OrderBuilder" or something
 * but it'd be a pain in the ass to refactor now. 
 */
export default class Order { 

    /**
     * Set of static initializers for the `method` property of an `Order`; see those docs
     */
    static Methods = class { 
        static TakeOut() { 
            return { $: "TakeOut" }
        }

        /**
         * 
         * @param {string} table Table number
         * @returns 
         */
        static DineIn(table) { 
            return { 
                $: "DineIn",
                table_number: table, 
            }
        }
    }


    /**
     * Creates an order from a restored order state.
     * 
     * **Note:** Use `Order.new()` to create a new order rather than restoring from localStorage. 
     * @param {object} obj 
     * @param {[object]} obj.items Array of **POJO** Order Items; they have to be deep cloned into ES6 objects on restore 
     * @param {{$: string}} obj.method 
     * @param {string} obj.location_id 
     */
    constructor(obj) {

        /**
         * Instantiates a proper ES6 mod from a POJO
         * @param {OrderMod} orderMod 
         * @returns {OrderMod}
         */
        function newMod(modPojo) { 
            /** @type {OrderMod} */
            let mod = Object.assign(Object.create(OrderMod.prototype), modPojo) 
            mod.selections = mod.selections.map(selPojo => { 
                /** @type {OrderModItem} */
                let sel = Object.assign(Object.create(OrderModItem), selPojo)
                sel.mods = sel.mods.map( p => newMod(p))
                return sel 
            })
            return mod
        }

        /**
         * Array of Order Item selections, represented by `OrderItem` instances
         * @type {[OrderItem]}
         */
        this.items = obj.items.map(pojo => { 
            /** @type {OrderItem} */
            let instance = Object.assign(Object.create(OrderItem.prototype), pojo) 
            instance.mods = instance.mods.map( p => newMod(p) )
            return instance
        })


        /**
         * The method of order fulfillment, such as dine-in or take-out. 
         * 
         * Do not create this object directly; instead use the static `Order.Methods.*` factory methods.  
         * 
         * @type {{$: string}}
         */
        this.method = obj.method

        /**
         * The ID of the location the order was created for 
         * @type {string}
         */
        this.location_id = obj.location_id
    }

    /**
     * Creates a new empty Order object
     * @param {string} location_id 
     * @param {object} method Method object; use `Order.Methods.*` methods to create 
     * @returns {Order}
     */
    static new(location_id, method) { 
        return new Order({ 
            location_id,
            method,

            items:  []
        })
    }


    /**
     * @param {string} key
     * @returns {OrderItem}
     */
    getOrderItem(key) { 
        return this.items.find(x => x.key === key)
    }



    // ----
    /*
        Setter interface (`with*` methods)
        All these methods return `this` so that they can be chained with `copy()` where convenient,
            so that they can force a state update when passed to React. 
     */
    // ----

    /**
     * @returns {Order}
     */
    copy() { 
        return Object.assign( Object.create(Object.getPrototypeOf(this)), this ) 
    }


    /**
     * Sets or clears the Order Method configuration object. 
     * The object should be created by the static `Order.Methods.*` factory instead of directly
     * 
     * Clearing the method should force the method selection modal to show via `app.jsx`
     * @param {?object} method Method type, or `null` to clear the method
     * @returns {Order}
     */
    withMethod(method) { 
        this.method = method 
        return this 
    }


    //
    // Contents editing
    //


    /**
     * Creates a new `this.items` array with `item` added.  Beware of duplicates. 
     * @param {OrderItem} item 
     * @returns {Order}
     */
    withAddItem(item) { 
        this.items = [... this.items, item] 
        return this 
    }

    /**
     * Replaces the item matching `item.key` with the provided `item` in `this.items`. 
     * No effect if no such item exists.
     * @param {OrderItem} item 
     * @returns {Order} 
     */
    withReplaceItem(item) { 
        this.items = this.items.map(old => old.key === item.key ? item : old) 
        return this 
    } 

    /**
     * Removes an item from `items`. 
     * @param {OrderItem} item
     * @returns {Order}
     */
    withRemoveItem(item) { 
        this.items = this.items.filter( x => x.key !== item.key ) 
        return this 
    }

    /**
     * Clears the entire `items` array.
     * @returns {Order}
     */
    withClearItems() { 
        this.items = []
        return this 
    }
    

    /*
    pub(crate) struct Order { 
    pub customer_ulink:     ULinkID,
    pub pickup_name:        String,
    pub pickup_phone:       String, 
    
    pub items:              Vec<OrderItem>, 
    pub method:             Method, 
    pub custom_instructions: Option<String>,

    #[serde(with="decimal_opt")]
    #[serde(default)]
    pub expected_subtotal:  Option<BigDecimal>,
    #[serde(with="decimal_opt")]
    #[serde(default)]
    pub expected_tax:       Option<BigDecimal>, 
}
    */
}



export class OrderItem { 


    /**
     * @param {string} key The ULID key to refer to this instance
     * @param {string} menu_item_id 
     * @param {number} quantity 
     * @param {?string} custom_instructions
     * @param {[OrderMod]} mods
     */
    constructor(key, menu_item_id, quantity, custom_instructions, mods)  { 
        /**
         * Unique key for this specific OrderItem instance
         * @type {string} 
         */
        this.key = key

        /**
         * ID of the `MenuItem` that this `OrderItem` is for 
         * @type {string} 
         */
        this.menu_item_id = menu_item_id

        /** @type {number} */
        this.quantity = quantity

        /** 
         * Custom instructions for this Order Item, or `null` if they are not allowed in this context
         * @type {?string} 
         */
        this.custom_instructions = custom_instructions 


        /**
         * Mod selections for this Order Item, represented by `OrderMod` objects
         * @type {[OrderMod]}
         */
        this.mods = mods
    }

    /**
     * @returns {OrderItem}
     */
    copy() { 
        return Object.assign( Object.create(Object.getPrototypeOf(this)), this ) 
    }

    /**
     * 
     * @param {number} quantity 
     * @returns {OrderItem}
     */
    withQuantity(quantity) { 
        this.quantity = quantity
        return this 
    }
}


/**
 * Helper class for managing all mod selections for an order item. 
 * 
 * This is unwrapped to its inner array when saving to global state
 * so that the resulting `OrderItem` can be serialized to JSON. 
 */
export class OrderItemModState { 

    /**
     * Constructs the helper object with the given state. 
     * 
     * This constructor is used to create a helper object for a state that already exists.
     * To create an entirely new state for a menu item, use `OrderItemModState.ForMenuItem`. 
     * 
     * @param {[OrderMod]} state 
     */
    constructor(state) { 
        /** @type {[OrderMod]} */
        this.state = state
    }

    /**
     * Creates a new helper object for the specified menu item
     * @param {Menu} menu 
     * @param {string} menu_item_id 
     */
    static ForMenuItem(menu, menu_item_id) { 

        /** @type {[OrderMod]} */
        let state = menu.getMenuItemMods(menu_item_id)
            .map(mod => { 
                var defaultSelection = null
                if(menu.getMod(mod.id).sel_min >= 1) { 
                    let options = menu.getModOptions(mod.id) 
                    if(options.length == 0) console.warn(`Mod #${mod.id} has a positive minimum but no available options for default`)
                    defaultSelection = new OrderModItem(menu, options[0].id, 1)
                    //TAG: [ModItemQuantity] 
                    // We default this quantity to 1 
                    // Probably won't need to change this but tagging it just in case 
                }

                return new OrderMod(mod.id, defaultSelection)
            })

        return new OrderItemModState(state) 
    }

    /**
     * Returns the resulting inner state of this object
     * @returns {[OrderMod]}
     */
    unwrap() { 
        return this.state
    }

    /**
     * 
     * @returns {OrderItemModState}
     */
    copy() { 
        return Object.assign(Object.create(Object.getPrototypeOf(this)), this) 
    }

    /**
     * Gets the quantity of the specified mod item in the selections of the mod
     * @param {ModPath} path Path - see `getMod` docs 
     * @param {string} mod_item_id 
     * @return {number} The quantity, defaulting to zero if the selection isn't present (since it has the same result)
     */
    getSelectionQuantity(path, mod_item_id) { 
        let mod = this.getMod(path) 
        let selection = mod.selections.find(x => x.mod_item_id === mod_item_id)
        return selection ? selection.quantity : 0 
    }


    /** 
     * @typedef {[string | [string]]} ModPath
     */



    /**
     * Gets a reference to the `OrderMod` instance corresponding to the given ID path.
     * 
     * The "path" concept exists to support arbitrarily nested modifiers.
     * It is a sequence of Mod IDs and Mod Item IDs that takes the following form:
     * 
     * `[ mod_id, [mod_item_id, mod_id] ...]`
     * 
     * @param {ModPath} modPath The path, see above
     * @returns {OrderMod}
     */
    getMod(modPath) { 

        var mod     = null 
        var path    = modPath.map(x => x) // copy

        if(path.length == 0) throw "Empty path" 

        // First mod
        var mod_id = path.shift()
        mod = this.state.find( x => x.mod_id === mod_id)
        if(! mod) { 
            console.error("No such mod in path", mod_id)
            throw "Inconsistent mod path state"
        }
        
        while(path.length > 0) { 
            let pair = path.shift()
            if(!Array.isArray(pair) || pair.length != 2) throw "Invalid path pairing"

            let mod_item_id = pair[0] 
            let mod_id      = pair[1] 

            /** @type {OrderModItem} */
            let mod_item = mod.selections.find(x => x.mod_item_id === mod_item_id)
            if(! mod_item) { 
                console.error("No such mod item in selections", mod_item_id)
                throw "Inconsistent mod path state"
            }

            mod = mod_item.mods.find(x => x.mod_id === mod_id)
            if(! mod) { 
                console.error("No such mod in mod item mods", mod_item_id)
                throw "Inconsistent mod path state"
            }
        }

        return mod
    }


    //
    // Validation
    //



    /**
     * `true` if all mods within the current state have a valid set of item selections
     * @param {Menu} menu 
     */
    isValid(menu) { 
        
        /**
         * @param {Menu} menu 
         * @param {[OrderMod]} orderMods 
         * @returns {boolean}
         */
        function isModListValid(menu, orderMods) {
            for(const modState of orderMods) { 
                if(! modState.isQuantityValid( menu.getMod(modState.mod_id), modState.currentQuantity() )) return false 
                
                for(const selection of modState.selections) { 
                    if(! isModListValid(menu, selection.mods)) return false 
                }
            }
            return true 
        }

        return isModListValid(menu, this.state) 
    }



    // 
    // Update interface
    //


    /**
     * Changes the quantity of a ModItem within the mod specified by `path`, creating a new selection if necessary
     * 
     * @param {Menu} menu Menu data; used to pull data when adding new selections
     * @param {ModPath} path Path - see `getMod` docs 
     * @param {string} mod_item_id 
     * @param {number} quantity Quantity, if set to zero it removes this selection
     * @returns {OrderItemModState}
     */
    withItemQuantity(menu, path, mod_item_id, quantity) { 
        let mod = this.getMod(path) 

        var modItem = mod.selections.find(x => x.mod_item_id === mod_item_id) 
        
        if(! modItem) { 
            mod.selections.push( new OrderModItem(menu, mod_item_id, quantity) )
        } else { 

            if(quantity == 0) { 
                mod.selections = mod.selections.filter(x => x.mod_item_id != mod_item_id) 
            } else { 
                modItem.quantity = quantity
                mod.selections = mod.selections.map(x => x.mod_item_id === mod_item_id ? modItem : x )
            }

        }

        return this
    }

    /**
     * Replaces all selections of the mod at the given `path` with a selection of `mod_item_id` and quantity 1.
     * 
     * Used for radio-button-style mods; with min 1 and max 1. 
     * 
     * @param {Menu} menu 
     * @param {ModPath} path Path - see `getMod` docs 
     * @param {string} mod_item_id 
     * @returns {OrderItemModState}
     */
    withReplacingItem(menu, path, mod_item_id) { 
        let mod = this.getMod(path) 
        mod.selections = [ new OrderModItem(menu, mod_item_id, 1) ]
        return this
    }

    /**
     * Clears all selections from the specified mod
     * @param {*} path Path - see `getMod` docs 
     * @returns {OrderItemModState}
     */
    withClearSelections(path) { 
        let mod = this.getMod(path) 
        mod.selections = [] 
        return this
    }
}



export class OrderMod { 

    /**
     * Creates a new object with zero selections, or a provided default
     * @param {string} mod_id 
     * @param {?OrderModItem} defaultSelection If nonnull, `selections` is initialized with this value instead of being empty 
     */
    constructor(mod_id, defaultSelection) { 
        /** @type {string} */
        this.key = ulid() 

        /**
         * The ID of the `Mod` that this object represents
         * @type {string}
         */
        this.mod_id = mod_id 

        /**
         * The selected Mod Items, represented by `OrderModItem` objects
         * @type {[OrderModItem]}
         */
        this.selections = [] 
        if(defaultSelection) this.selections.push(defaultSelection) 
    }

    /**
     * The current number of selectd items
     * @returns {number}
     */
    currentQuantity() { 
        return this.selections.reduce((sum, item) => sum + item.quantity, 0)
    }

    /**
     * `true` if the current state of this mod is valid for submission.
     * @param {Mod} mod `Mod` data from the root `Menu` object
     * @param {number} qty The current number of selected items
     */
    isQuantityValid(mod, qty) { 
        return qty >= mod.sel_min && (mod.sel_max === null || qty <= mod.sel_max) 
    }
}

export class OrderModItem { 

    /**
     * Creates a new instance with the given quantity, pulling nested mod data from `menu` 
     * @param {Menu} menu 
     * @param {string} mod_item_id 
     * @param {number} quantity
     */
    constructor(menu, mod_item_id, quantity) { 
        /**
         * The ID of the selected `ModItem` that this object represents
         * @type {string}
         */
        this.mod_item_id = mod_item_id 

        /**
         * Nested mod options (not yet implemented)
         * @type {[OrderMod]}
         */
        this.mods = menu.getNestedMods(mod_item_id) 

        /** @type {number}  */
        this.quantity = quantity
    }
}