import React from 'react';
import { Icon } from 'utils';

export default function(props) {
    let mod = props.mod;

    function compareModSells(min, max) {
        if(min === 0 && min < max) return "choose up to " + max;
        if(min === max && min + max != 0) return "choose exactly " + min;
        if(min > 0 && max > 1) return "choose at least " + min;
        else {
            console.log(`Error: ${mod.name} item mod in kernel >> "sel_max" integer must be greater than 0`);
            return "ERR ";
        }
    }

    return(
        <div className="ModsList">
            <div className="title">
                <h5>{ mod.name }</h5>
                <span className='shared-badge'>shared</span>
                <Icon name="label_important" />
            </div>
            <div className="content">
                <h6>
                    { mod.items.length } options - {compareModSells(mod.sel_min, mod.sel_max)}
                </h6>
                <p>
                    {
                        mod.items.map((item, index) => {
                            if(index >= mod.items.length - 1) return item.name;
                            else return item.name + " · ";
                        })
                    }
                </p>
            </div>
            <div className="buttons">
                <Icon name="edit" onClick={ props.onEdit } aria-label="Edit" />
                <Icon name="remove_circle" className="destructive" onClick={ props.onRemove } aria-label="Remove" />
            </div>
        </div>
    );
};