import React, { useState } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import ULinkService from "services/ULink"
import {
    Container,
    Paper,
    Button,
    TextField,
    Grid,
    Toolbar,
    Box,
    AppBar,
} from "@mui/material"

import { toaster } from "global-ui"

export function ResetPassword(props) {
    const navigate = useNavigate()
    const uri = useSearchParams()[0].get("uri")

    const [newPass, setNewPass] = useState("")
    const [confirm, setConfirm] = useState("")

    const handleReset = () => {
        if (newPass.length < 8) {
            toaster.invalidPassword();
        } else if (newPass === confirm) {
            ULinkService.admins
                .setNewPassword({ password: newPass, uri: uri })
                .then(res => navigate("/login"))
                .then(err => {
                    if (err !== undefined) {
                        toaster.catchULink(err)
                    }
                })
        } else {
            alert("Passwords do not match!")
        }
    }

    return (
        <>
            <Box id="nav-drawer">
                <AppBar
                        position="fixed"
                        sx={{ zIndex: theme => theme.zIndex.drawer + 1, height: "4em" }}
                >
                    <Toolbar>
                        <img
                            style={{
                                height: "4em",
                            }}
                            src="/img/new-ulink-logo.png"
                            alt="ULink logo"
                        />
                    </Toolbar>
                </AppBar>
            </Box>
            <Container sx={{ display: "flex !important", justifyContent: "center", paddingTop: 10, width: "100%" }}>
                <Paper
                    sx={{
                        padding: "2rem",
                        maxWidth: "50%",
                    }}
                    children={
                        <>
                            <Grid item sx={{ textAlign: "center" }}>
                                <header>
                                    <h1>Reset Password</h1>
                                </header>
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: "1em" }}>
                                <TextField
                                    fullWidth
                                    label="New Password"
                                    type="password"
                                    onChange={e => setNewPass(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: "1em" }}>
                                <TextField
                                    fullWidth
                                    label="Confirm Password"
                                    type="password"
                                    helperText="Passwords must contain at least eight characters."
                                    onChange={e => setConfirm(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button onClick={handleReset}>Update Password</Button>
                            </Grid>
                        </>
                    }
                />
            </Container>
        </>
    )
}
