import Big from "big.js"
import { currency } from "../utils"

/**
 * The type that determines how the perk is displayed; inferred by the API based on its contents
 */
 export type IncentiveType = "DISCOUNT_RATE" | "DISCOUNT_FLAT" | "FREE_ITEM"
 /**
  * The type of payment rail that the customer is using for their purchase
  */
 export type IncentiveRailType = "bank" | "card"
 
 /**
  * `Incentive` is the original name for the "perks" concept;
  * this class represents an instance of a perk that may apply to a user's purchase. 
  * 
  * It also may represent an instance of an incentive that was applied to a historical transaction record,
  * in which case it will have some extra properties.
  * This is super sloppy and we should have a cleaner data model.
  */
 export default class Incentive { 
 
    $                        : IncentiveType
    value_flat              ?: Big 
    value_rate              ?: Big
    minimum_purchase        ?: Big
    subtracted_from_total    : boolean
    product_description     ?: string 
    rail_types               : [IncentiveRailType]

    amount_discounted   ?: Big 
    value               ?: Big 

    constructor(json: any){ 
        this.$                      = json.$
        this.value_flat             = json.value_flat && new Big(json.value_flat)
        this.value_rate             = json.value_rate && new Big(json.value_rate)
        this.minimum_purchase       = json.minimum_purchase && new Big(json.minimum_purchase)
        this.subtracted_from_total  = json.subtracted_from_total
        this.product_description    = json.product_description
        this.rail_types             = json.rail_types 

        this.amount_discounted      = json.amount_discounted && new Big(json.amount_discounted)
        this.value                  = json.value && new Big(json.value) 
    }


    /**
     * Returns a description of the perk,
     * **assuming it applies** (i.e. `applies()` would return `true`)
     */
    textDescription() : string { 
        var desc = "" 

        if(this.rail_types.length == 1 && this.rail_types[0] == "bank") { 
            desc += "Pay-by-bank perk: "
        }

        const pct   = this.value_rate && `${this.value_rate.mul(100)}%`
        const flat  = this.value_flat && `$${ this.value_flat.toFixed(2) }`

        if(flat) { 
            if(pct) desc += `${pct} + ${flat} off ` 
            else desc += `${flat} off `
        } else { 
            if(pct) desc += `${pct} off `
        }

        if(this.product_description) desc += this.product_description + ' ' 

        return desc.trimEnd()
    }


    /**
     * `true` if all constraints of this perk object are met 
     * @param subtotal The original amount of the checkout of the checkout
     * @param railType The currently-selected payment rail type 
     */
    applies(subtotal: Big, railType: IncentiveRailType) : boolean { 
        if(! this.rail_types.includes(railType)) return false 
        if(! subtotal.gte(this.minimum_purchase ?? new Big(0))) return false 

        return true 
    }

    /**
     * Computes the effective discount on (i.e., amount subtracted from the actual payment amount)
     * for this perk, **assuming it is valid** (i.e. `applies` would return `true),
     * or **null** if the perk is not actually subtracted from the total (i.e. for a free item)
     * @param subtotal The original amount of the checkout of the checkout
     */
    discountIfApplied(subtotal: Big) : Big | null { 
        if(this.subtracted_from_total) { 
            return currency( this.value_rate ?? new Big(0) ).mul(subtotal).plus( this.value_flat ?? new Big(0) ).round(2, Big.roundUp)
        }
        else return new Big(0)
    }

    /**
     * Applies all given incentives to the provided subtotal and payment rail type,
     * returning an object that can be displayed as a summary.
     * @param subtotal The original amount of the checkout of the checkout
     * @param railType The currently-selected payment rail type 
     */
    static ApplyAll(incentives: Incentive[], subtotal: Big, railType: IncentiveRailType) : { 
        lineItems: { incentive: Incentive, discount?: Big }[],
        totalDiscount: Big, 
    } { 
        const applied = incentives.filter( x => x.applies(subtotal, railType) )
        return { 
            lineItems: applied.map( inc => ({ incentive: inc, discount: inc.discountIfApplied(subtotal) }) ) ,
            totalDiscount: applied.reduce( (prev, inc) => prev.plus(inc.discountIfApplied(subtotal) ?? new Big(0)) , new Big(0) )
        }
    }
 }