import { createTheme } from "@mui/material/styles"

export const MainAdminThemeLight = createTheme({
    palette: {
        primary: {
            main: "#0450e7",
        },
        secondary: {
            main: "#93CC00",
        },
        background: {
            default: "#f1f1f1",
            paper: "#ffffff",
        },
        text: {
            primary: "#313131dd",
            secondary: "#0f0f0f88",
            disabled: "#47474792",
            //@ts-ignore
            hint: "rgba(109,249,149,0.38)",
        },
        divider: "#686868c2",
        error: {
            main: "#b34625",
        },
    },

    // props: {
    //     MuiAppBar: {
    //         color: "transparent",
    //     },
    // },

    // MUI Component Specific Override Styles for this theme
    components: {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: "#0450e7",
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: "1.15rem",
                    color: "#313131dd",
                },
            },
        },

        MuiToolbar: {
            styleOverrides: {
                regular: {
                    borderBottom: "5px solid #93CC00",
                },
            },
        },

        MuiDrawer: {
          styleOverrides: {
            paper: {
              boxShadow: "3px 0px 6px #00000061",
            }
          }
        }
    },
})

export const AdamsLightTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
          main: '#008386',
        },
        secondary: {
          main: '#93CC00',
        },
        background: {
          default:  '#f1f1f1',
          paper:    '#ffffff',
        },
        text: {
          primary:    '#313131dd',
          secondary:  '#0f0f0f88',
          disabled:   '#6060605f',
          //@ts-ignore
          hint:       '#00a078',
        },
        divider: '#a5a5a5c2',
        error: {
          main: '#ff3c3f',
        },
      },

      props: {
        MuiAppBar: {
          color: 'transparent',
        },
      },

      // MUI Component Specific Override Styles for this theme
      components: {
        MuiListItemIcon: {
          styleOverrides: {
            root: {
              color: '#006579',
            },
          },
        },
        MuiListItemText: {
          styleOverrides: {
            primary: {
              fontSize: '1.15rem',
              color: "#313131dd"
            }
          }
        },
        MuiOutlinedInput: {
          styleOverrides: {
            notchedOutline: {
              borderColor: '#182135'
            }
          }
        },

        MuiToolbar: {
          styleOverrides: {
            regular: {
              // borderBottom: "5px solid #93CC00"
            }
          }
        },
        MuiDrawer: {
          styleOverrides: {
            paper: {
              boxShadow: "3px 0px 6px #00000061",
            }
          }
        }
        // MuiTextField: {
        //   styleOverrides: {
        //     root: {
        //       margin: ;
        //     }
        //   }
        // }
      },
});

export const MainAdminTheme = createTheme({
    palette: {
        // type: "dark",
        primary: {
            main: "#4d50cc",
        },
        secondary: {
            main: "#93CC00",
        },
        background: {
            default: "#182135",
            paper: "#141c2d",
        },
        text: {
            primary: "rgba(255,255,255,0.87)",
            secondary: "rgba(255,255,255,0.54)",
            disabled: "rgba(235,235,235,0.38)",
            // hint: "rgba(109,249,149,0.38)",
        },
        divider: "rgba(255,255,255,0.43)",
        error: {
            main: "#b34625",
        },
    },

    // props: {
    //     MuiAppBar: {
    //         color: "transparent",
    //     },
    // },

    // MUI Component Specific Override Styles for this theme
    components: {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: "white",
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: "1.15rem",
                    color: "white",
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: "#ffffff95",
                },
            },
        },

        // MuiToolbar: {
        //     styleOverrides: {
        //         regular: {
        //             borderBottom: "5px solid green",
        //         },
        //     },
        // },
        // MuiTextField: {
        //   styleOverrides: {
        //     root: {
        //       margin: ;
        //     }
        //   }
        // }
    },
})
