import React, { useState, useRef, useEffect } from "react";
import { Icon } from "utils.jsx";
import { uniqid } from '../utils.js';

/**
 * Generic input component that's cool and pretty 
 * @param {object} props 
 * @param {string} props.name The programmatic name of the input, rendered as `data-name` on the outer element
 * @param {string} props.label The display name of the input, rendered within the `<label>` element
 * 
 * @param {string} props.value The value of the input
 * @param {string} props.placeholder The placeholder for the input 
 * @returns 
 */
export default function(props) {

    const key = useState(uniqid())[0]; 

    var input;
    let input_props = {
        name: props.name,
        id: key,
        value: props.value,
        placeholder: props.placeholder,
    };


    let editable = !! props.onChange; 
    if(editable) input_props.onChange = props.onChange; 
    else input_props.readOnly = true; 


    if(props.multiline) {
        input = <textarea {... input_props} ></textarea>
    } else { input = 
        <input id={ key } type="text" {... input_props} />
    };

    return (
        <div className="global-input" data-name={props.name}>
            {/* Initially there was a {...props} above or below, but it caused DOM validation issues.  I'm not sure where it was used. */}
            {/* We should be more explicit about where those props go anyways; the ID prop for instance goes on the input itself, only if rendered */}


            <label htmlFor={key}>{ props.label }</label>

            <div>
                { input }
                <Icon name="info" title={ props.infoTip } />
            </div>

        </div>
    );
}