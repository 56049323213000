export default class { 

    constructor(obj) { 
        /** @type {string} */
        this.id = obj.id 
        /** @type {string} */
        this.uri = obj.uri 
        /**
         * @type {obj} 
         * @readonly
         */
        this._links = obj._links 

        /** @type {string} */
        this.name_first = obj.name_first
        /** @type {string} */
        this.name_last = obj.name_last 
        /** @type {string} */
        this.email = obj.email 
        /** @type {string} */
        this.phone = obj.phone 

        this.created_at = new Date(obj.created_at)
        this.updated_at = new Date(obj.updated_at)
        this.last_login_at  = obj.last_login_at ? new Date(obj.last_login_at) : null 
        this.pw_set_at      = obj.pw_set_at ? new Date(obj.pw_set_at) : null 

    }

    /**
     * The user's full name, for display purposes.
     * I never liked that it was first/last name anyways, but that's what our mockups called for.
     * @returns {string}
     */
    name() { return this.name_first + ' ' + this.name_last }

}