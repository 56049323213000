import Menu from 'lib/lib-smb-menus/data/Menu';
import React from 'react'; 

export const TabState = Object.freeze({
    ON_MENU:    0,
    NEW_MENU:   1,
    HISTORY:    10,
    SETTINGS:   11,

    toString: x => { switch(x) { 
        case 0:  return "ON_MENU";
        case 1:  return "NEW_MENU"; 

        case 10: return "HISTORY"; 
    } }
});

/**
 * 
 * @param {object} props 
 * @param {object} props.tabController Props object from `SmbMenu.jsx` containing all tab control methods
 * @param {Menu} props.menuData The SMB Menu object containing all SMB Menu data
 * @returns 
 */
function TabBar(props) {
    const topMenus = props.menuData.menus()

    return <nav id="TabBar" role="tablist" aria-controls="ORDERING_CONTENT"
            aria-owns={ [ ...topMenus.map(x => 'tab-menu-' + x.id) , 'tab-add', 'tab-settings'] } 
    >
        <div>
            { 
                topMenus.map(menu => 
                    <div role="tab" key={menu.id} id={'tab-' + menu.id}>{ menu.name }</div>
                )
            }
            <div role="tab" id="tab-add" onClick={ () => props.setTab(TabState.NEW_MENU) }>+</div>
        </div>

        {/* This second div should still align right w/ the current flex CSS, so we can put History here later on - we just don't need Settings anymore b/c ULink */}
        {/* <div>
            <div role="tab" id="tab-settings" onClick={ () => props.setTab(TabState.SETTINGS) }>Location Settings</div>
        </div> */}
    </nav>
}




/**
 * 
 * @param {object} props 
 * @param {object} props.tabController Props object from `SmbMenu.jsx` containing all tab control methods
 * @param {Menu} props.menuData The SMB Menu object containing all SMB Menu data
 * @param {?number} props.currentMenuID The `id` of the `Menu` corresponding to the currently-selected tab
 * @returns 
 */
export default function(props) { 
    return <>
        <TabBar menuData={ props.menuData } currentMenuID={ props.currentMenuID } { ... props.tabController } />
        <div id="ORDERING_CONTENT" role="tabpanel">{ props.children }</div>
    </>
}