import React, { useState, useEffect } from "react"
import { useSearchParams, useNavigate } from "react-router-dom"
import ULinkService from "services/ULink"
import { GlobalState } from "GlobalState"
import {
    FormHelperText,
    Container,
    Paper,
    Button,
    TextField,
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material"

import { toaster } from "global-ui"

const themes = ["AdamsLightTheme", "MainAdminThemeLight"]

export function ViewProfile(props) {
    const { scrollToTop } = GlobalState.useContainer()
    const { context, setContext, session, setSession, mainRef, setIsLoading } =
        GlobalState.useContainer()
    const navigate = useNavigate()
    const uri = useSearchParams()[0].get("uri")

    useEffect(() => {
        scrollToTop()
    }, [])

    const [userName, setUserName] = useState(session?.user.name)
    const [email, setUserEmail] = useState(session?.user.email)
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const updateUsername = () => {
        const obj = {
            uri: `/v2/admin/admins/${session.user.id}`,
            name: userName,
        }

        ULinkService.update(obj)
            .then(res => {
                toaster.saved()
                session.user.name = userName
            })
            .catch(toaster.catchULink)
    }

    const updatePassword = () => {
        if (newPassword !== confirmPassword) {
            toaster.passwordsDoNotMatch();
            return
        }
        if (newPassword.length < 8) {
            toaster.invalidPassword();
            return
        }

        ULinkService.admins
            .updatePassword({
                current_password: currentPassword,
                new_password: newPassword,
            })
            .then(() => toaster.saved())
            .catch(err => {
                toaster.catchULink(err)
            })

        // need to check that "currentpw" is correct
    }

    const logOutUser = () => {
        fetch("/log-out", { method: "POST" })
            .then(() => {
                setSession(null)
                setContext(null)
                setIsLoading(false)
                navigate("/")
            })
            .catch(toaster.catchULink)
    }

    return (
        <>
            <Container>
                <header style={{ display: "flex", justifyContent: "space-between" }}>
                    <h1>Profile</h1>
                    <Button
                        id="logout-button"
                        sx={{ margin: "2rem 0" }}
                        variant="contained"
                        onClick={logOutUser}
                    >
                        Log Out
                    </Button>
                </header>
                <Paper
                    sx={{ padding: "2rem" }}
                    children={
                        <>
                            <Grid container rowSpacing={3} sm={6}>
                                <Grid item xs={12}>
                                    <h1>Info</h1>
                                </Grid>
                                <Grid container rowSpacing={1} item xs={12}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            type="text"
                                            label="Name"
                                            value={userName}
                                            onChange={e => {
                                                setUserName(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button onClick={updateUsername}>Update Username</Button>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={1} item xs={12}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Current Password"
                                            type="password"
                                            value={currentPassword}
                                            onChange={e => {
                                                setCurrentPassword(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="New Password"
                                            type="password"
                                            value={newPassword}
                                            onChange={e => {
                                                setNewPassword(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Confirm Password"
                                            type="password"
                                            value={confirmPassword}
                                            helperText="Passwords must contain at least eight characters."
                                            onChange={e => {
                                                setConfirmPassword(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button onClick={updatePassword}>Update Password</Button>
                                    </Grid>

                                    {
                                        //@ts-ignore
                                        GLOBALS.ENV === "development" && (
                                            <Grid item xs={12}>
                                                <FormControl sx={{ margin: "1rem 0rem" }} fullWidth>
                                                    <InputLabel id="demo-simple-select-label">
                                                        Theme
                                                    </InputLabel>
                                                    <Select
                                                        label="Theme"
                                                        value={localStorage.getItem("Theme")}
                                                        onChange={e => {
                                                            localStorage.setItem(
                                                                "Theme",
                                                                e.target.value
                                                            )
                                                            location.reload()
                                                        }}
                                                    >
                                                        {themes.map(theme => (
                                                            <MenuItem key={theme} value={theme}>
                                                                {theme}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    <FormHelperText>
                                                        Page will reload after selecting
                                                    </FormHelperText>
                                                </FormControl>
                                            </Grid>
                                        )
                                    }
                                </Grid>
                            </Grid>
                        </>
                    }
                />
            </Container>
        </>
    )
}
