import { GlobalState } from "GlobalState"
import React, { useState, useEffect } from "react"
import { ResellerInfo } from "./ResellerInfo"

interface Props {}

export const AddReseller: React.FC<Props> = () => {
    const [state, setState] = useState({})
    const { scrollToTop } = GlobalState.useContainer()

    useEffect(() => {
        scrollToTop()
    }, [])

    const mergeState = (update: any) => {
        setState(old => Object.assign({}, old, update))
    }

    return (
        <main id="AddMerchant">
            <section role="banner">
                <h1>Add a new reseller</h1>
            </section>

            <div className="paneled-grid">
                <section id="basic">
                    <h2>Reseller Info</h2>
                    <hr />
                    <ResellerInfo
                        isInvitation={false}
                        isNew={true}
                        state={state}
                        setState={setState}
                        mergeState={mergeState}
                    />
                </section>
            </div>
        </main>
    )
}
