import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import { phoneNumber } from "global-ui"
import { entity_location_statuses } from "/globals"

import ULink from "services/ULink"
import DataTable from "widgets/DataTable"
import { GlobalState } from "GlobalState"

// MUI
import {
    Paper,
    Container,
} from '@mui/material';

export const ListLocations = () => {
    const navigate = useNavigate()
    const uri = useSearchParams()[0].get("uri")
    const { scrollToTop } = GlobalState.useContainer()

    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <>
            <Container id="ListLocations">
                <header>
                    <h1>All Locations</h1>
                </header>

                <Paper sx={{padding: "2rem"}} children={
                    <DataTable
                        firstFetch={() => ULink.locations.all(uri)}
                        columns={[
                            { name: "Location Name", selector: row => row.name },
                            { name: "Business Name", selector: row => row.entity.name },
                            { name: "Address", selector: row => row.fullAddress() },
                            //{ name: "Phone", selector: row => phoneNumber(row.phone) ?? "Not set" },
                            { name: "Status", selector: row => entity_location_statuses[row.status] },
                        ]}
                        onRowClicked={(row, e) => navigate("/location?uri=" + encodeURI(row.uri))}
                        loadingMessage={"Loading Locations..."}
                        emptyMessage={"No Locations"}
                    />
                } />
            </Container>
        </>
    )
}
