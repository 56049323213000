/**
 * Wrapper class to interpret the Hours concept from the SMB Service, which describes when a **resource** (Location/Menu/etc) is **available**. 
 * 
 * This object is for version 1, the only currently-existing version.
 * When in doubt, refer to the SMB Service implementation.
 */
export default class Hours { 

    /** When `true`, all `Hours` are always available */
    static BYPASS = false 

    /**
     * Accepts the Hours object from the API, which is set up to be versioned in case we need it.
     * Use the `AlwaysAvailable` and `NeverAvailable` constructors rather than passing arguments directly to this constructor. 
     * 
     * Presumed to be valid as it's behind the API validation layer.
     * @param {{ version: string, available: [] }|null} hours API object, or `null` if the hours are meant to always be available  
     */
    constructor(hours) { 
        /**
         * If `true`, this object is a placeholder and its resource is always available
         * @type {boolean}
         */
        this.alwaysAvailable = hours == null 
        if(hours == null) return 

        switch(hours.version) { 
            case "1": 
                /** 
                 * Set of availability spans. 
                 * Guaranteed to be ordered by API validation. 
                 * @type {[ HoursSpan ]} 
                 */
                this.available = hours.available.map( x => new HoursSpan(x) )
                break 

            default: 
                console.error("Unrecognized Hours version");
                throw "E" // this error doesn't ever show up in console for some stupid reason
        }
    }

    /**
     * Constructs an `Hours` object that is always available.
     * @returns {Hours}
     */
    static AlwaysAvailable() { 
        return new Hours(null)
    }
    /**
     * Constructs an `Hours` object that is never available. 
     * @returns {Hours}
     */
    static NeverAvailable() { 
        return new Hours({ version: "1", available: [] })
    }


    /**
     * `true` if the resource is available now.
     * @returns {boolean}
     */
    isAvailableNow() { 
        if(this.alwaysAvailable || Hours.BYPASS) return true 
        return this.#getPositionFor(new Date()).exists 
    }

    /**
     * Formatted string showing the next available start time, or null if no such time could be determined.
     * 
     * See `Hours.#getPositionFor` for details about time computation 
     * @returns {string|null}
     */
    displayNextAvailableTime() { 
        if(this.alwaysAvailable || Hours.BYPASS) { 
            console.warn("displayNextAvailableTime() called on a resource that is always available, which is unintended")
            return null 
        }

        // No available opening time 
        if(this.available.length == 0) return null 

        const pos = this.#getPositionFor(new Date())
        if(pos.exists) console.warn("displayNextAvailableTime() called on a resource that is currently available, which is unintended")
        var i = pos.index 
        if(i >= this.available.length) i = 0 
        // Wrap around to start of week if necessary 

        return this.available[i].start_at.display()
    }

    /**
     * Formatted string showing the time at which this resource stops being available,
     * or `null` if the resource is always available. 
     * This function will throw if the resource is not currently available. 
     * 
     * see `Hours.#getPositionFor` for details about time computation 
     * 
     * @returns {string|null}
     */
    displayAvailableUntilTime() { 
        if(this.alwaysAvailable || Hours.BYPASS) return null 

        const pos = this.#getPositionFor(new Date())
        if(! pos.exists) throw "displayAvailableUntilTime() called on a resource that is not currently available" 

        return this.available[pos.index].stop_after.display()
    }



    /**
     * Returns the position within the schedule corresponding to the current moment of time, 
     * including an index within `available` and a boolean indicating whether or not it exists.
     * 
     * If `exists` is true, the resource is available at `date`, 
     * and `index` will be the valid index of `date` span represented within `available`.
     * 
     * If `exists` is false, the resource is not available at `date` 
     * and `index` will be the smallest index within `available` corresponding to a 
     * time span after `date` within the current week,
     * **or the length of the array** (out-of-bounds index after the array, similar to how `Arrays.splice` works)
     * if the `available` array is empty or if `date` is after every time span in `available`.
     * 
     * This is often the next available time span, but extra care must be taken to 
     * consider the **edge cases**: empty arrays, and wrapping around to the next week.
     * If `date` is after all time spans represented, or the array is empty, `index` will be the length of the array.
     * In this case, the caller will have to account for the edge cases. 
     * 
     * @param {Date} date 
     * @returns {{ index: number, exists: boolean }}
     */
    #getPositionFor(date) { 
        const hours = this 
        const now = HoursTime.FromDate(date).minutes
        
        for(var i = 0; i < hours.available.length; i++) { 
            const span = hours.available[i] 

            // `hours.available` is guaranteed to be in-order by the API; at least for V1 
            if(i > 0 && span.start_at.minutes <= hours.available[i - 1].start_at.minutes) { 
                // A little heads up in case the above assumption ever fails 
                console.warn("`hours.available` spans out of order; this should have been prevented by API")
            }

            // `now` is within `span` 
            if(now >= span.start_at.minutes && now <= span.stop_after.minutes) return { index: i, exists: true }   

            // `now` is before `span` - it therefore must have been after any previous `span`s 
            if(now < span.start_at.minutes) return { index: i, exists: false } 
        }

        // `now` is after every `span` 
        return { index: hours.available.length, exists: false }
    }
}


/**
 * A time span in which the resource represented by the parent `Hours` object is available,
 * delinieated by start and stop values. 
 */
export class HoursSpan { 

    /**
     * Converts an API span object into an `HoursSpan` instance. 
     * @param {{ start_at: number, stop_after: number }} span 
     */
    constructor(span) { 
        /** @type {HoursTime} */
        this.start_at   = new HoursTime(span.start_at)
        /** @type {HoursTime} */
        this.stop_after = new HoursTime(span.stop_after)
    }
}





/**
 * Represents a time within a week, within the context of the `Hours` object. 
 */
export class HoursTime { 

    /**
     * Accepts a time as a `number` of **minutes** since the start of the week **in the Location's local time**.
     * Weeks begin on Sunday, which is consistent between the JS `Date` API and the SMB Service representation.
     * @param {number} minutes 
     */
    constructor(minutes) { 
        this.minutes = minutes 
    }


    /**
     * Converts a `Date` into an `HoursTime` representation of its time within its week. 
     * @param {Date} date 
     * @returns {HoursTime}
     */
    static FromDate(date) { 
        return new HoursTime((date.getDay() * 24 * 60) + (date.getHours() * 60) + date.getMinutes()) 
    }


    /**
     * Renders this time as a string for the UI, relative to the current day. 
     * 
     * This string will be formatted in the 12-hour clock, and will include the name of the day if different from the current day.
     * @returns {string} 
     */
    display() { 
        var m = this.minutes; 

        const DAY = 24 * 60 
        const days = Math.floor(m / DAY)
        m -= days * DAY 

        const HOUR = 60
        const hours = Math.floor(m / HOUR) 
        m -= hours * HOUR 

        const minutes = m 
        

        var str = "" 

        const DAY_NAMES = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', ]; 
        if(days != new Date().getDay()) str += DAY_NAMES[days] + ', '

        var displayHour, period; 
        if(hours == 0)  { displayHour = 12;             period = "AM"; }
        if(hours  < 12) { displayHour = hours;          period = "AM"; }
        if(hours == 12) { displayHour = 12;             period = "PM"; } 
        if(hours  > 12) { displayHour = hours - 12;     period = "PM"; }
        if(!displayHour || !period) { console.error("Could not compute hours display"); throw 'E' }
        str += `${ displayHour.toString().padStart(2,'0') }:${ minutes.toString().padStart(2,'0') } ${period}`

        return str 
    }
}