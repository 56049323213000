
export function emailValid(email: string) {
    return email.match( /^[^\s@]+@[^\s@]+\.[^\s@]+$/ );
};

export function containsOnlyNumbers(value: string) {
    const onlyNumbers = /^[0-9]+$/
    return onlyNumbers.test(value) ? true : false;
};

export function containsOnlyLetters(value: string) {
    const onlyLetters = /^[A-Z]+$/
    return onlyLetters.test(value) ? true : false;
};