import * as GLOBALS from "../globals"

import React from "react" //Needed for Toaster

import parseLink, { Link } from "parse-link-header"

import Reseller from "data/Reseller"
import Merchant, { MerchantUser } from "data/Merchant"
import Location, { MIDConfig, MIDCybs, MIDdda } from "data/Location"
import Admin, { Session } from "data/Admin"
import { ResellerGroup } from "data/ResellerGroup"
import Upload from "data/Upload"
import CpayUser from "data/CpayUser"
import Transaction from "lib/lib-sionic/data/Transaction"

import Menu from "lib/lib-smb-menus/data/Menu"
import ContextUser from "data/ContextUser"
import GlobalUser from "data/GlobalUser"
import Big from "big.js"

export const ULINK_MIRROR = "/ulink/mirror"
interface ULinkFetchInit extends RequestInit {
    json?: object
    params?: object
}

interface Updateable {
    uri: string
}

/**
 * Performs a fetch to the ULink mirror endpoint, with the appropriate defaults and extensions.
 */
export function ulinkFetch(endpoint: string, options: ULinkFetchInit = null): Promise<Response> {
    options ||= {}

    var endpoint = endpoint
    if (!endpoint.startsWith("/")) {
        console.warn(
            "Expected an absolute path to send to the ULink mirror; treating as 404 by default"
        )
        return Promise.reject(404)
    }

    if (options.json) {
        var headers = Object.assign(options.headers || {}, { "Content-Type": "application/json" })
        options.headers = headers
        options.body = JSON.stringify(options.json)
    }

    if (options.params) {
        let pairs = Object.keys(options.params).map(
            key => `${key}=${encodeURIComponent(options.params[key])}`
        )
        endpoint = `${endpoint}?${pairs.join("&")}`
    }

    options.credentials = "include"
    options.mode = "cors"

    return fetch(ULINK_MIRROR + endpoint, options)
}

// Don't want to go around renaming everything rn
// Previously ULinkFetch
export const ulink = ulinkFetch

export type RangeInfo = { start: number; end: number }

export type IteratorLinks = { first?: Link; prev?: Link; next?: Link; last?: Link }

/**
 * Iterator helps to fetch result arrays from ULink and convert them to the desired data type.
 *
 * Iterator contains an `items` object containing the current page's items,
 * as well as a `next()` function to get the next page if one exists.
 *
 * The object can accept paginated (HTTP 206) or non-paginated (HTTP 200) results to provide forwards and backwards compatibility.
 * For paginated results, the Iterator works by reading header information from ULink, such as the link to the next page.
 * There is room in the headers for additional information information in the future, such as total page/result count and time to query.
 */
export class Iterator<T> {
    links: IteratorLinks

    /**
     * The indices of the first and last item in the result,
     * or `null` if that information is unknown
     */
    rangeInfo?: RangeInfo

    /**
     * The total number of items available in the resource,
     * or `null` if that information is unknown
     */
    totalItems?: number

    /**
     * The total number of pages available in the resource,
     * or `null` if that information is unknown
     */
    totalPages?: number

    items: T[]

    mapper?: (arg0: object) => T

    /**
     * `true` if the iterator has additional pages that can be fetched with `next`
     */
    hasNext: boolean

    /**
     * @param response The vanilla Response object, or `null` if this is a placeholder object
     * @param items The response array (instantiated directly; not mapped over `mapper` even if provided)
     * @param mapper A function that converts an item in the response array to an instance of T
     */
    constructor(response: Response | null, items: [T], mapper: (arg0: object) => T) {
        this.links = {}

        if (response) {
            // Only attempt to parse pagination headers on HTTP 206 (Partial Content)
            if (response.status == 206) {
                let header = response.headers.get("Link")
                if (header) {
                    let parsed = parseLink(header)
                    if (parsed == null) console.info("null parsed link", header)
                    this.links = parsed ?? {}
                } else {
                    console.warn("No `Link` header on HTTP 206")
                    this.links = {}
                }
            } else if (response.status != 200) console.warn("Iterator expected HTTP 206 or 200")

            this.rangeInfo = null
            this.totalItems = null
            this.totalPages = null

            const range = response.headers.get("content-range")
            if (range) {
                const parse = range.match(/^items (\d+)-(\d+)\/(\d+|\*)$/)
                if (parse) {
                    const rangeStart = parseInt(parse[1])
                    const rangeEnd = parseInt(parse[2])
                    this.rangeInfo = { start: rangeStart, end: rangeEnd }

                    if (parse[3] != "*") {
                        const resultCount = parseInt(parse[3])

                        if (rangeEnd != resultCount - 1) {
                            // we are on a full page,
                            // take the size of the page and divide, rounding up
                            const pageSize = rangeEnd - rangeStart + 1
                            this.totalPages = Math.ceil(resultCount / pageSize)
                        }

                        this.totalItems = resultCount
                    }
                } else {
                    console.warn("Iterator: Could not parse provided range header")
                }
            }
        }

        this.items = items
        this.mapper = mapper
        this.hasNext = !!this.links.next
    }

    /**
     * Creates a stub iterator that mocks a response with the given contents
     * @param {[T]} list
     * @returns {Iterator<T>}
     */
    static FromList(list) {
        return new Iterator(null, list, null)
    }

    /**
     * Constructs an Iterator as a promise; meant to be mapped from other promises returned by API invocations.
     * @param response
     * @param mapper A function that will be mapped over the response body array, if provided
     */
    static promise<T>(response: Response, mapper?: (arg0: object) => T): Promise<Iterator<T>> {
        return new Promise((resolve, reject) => {
            if (!response.ok) {
                reject(response.status)
                return
            }

            response
                .json()
                .then(json => {
                    /** @type {Array} */
                    var items = json
                    if (!Array.isArray(items)) throw "Response is not an array"

                    if (mapper) items = items.map(mapper)
                    resolve(new Iterator(response, items, mapper))
                })
                .catch(reject)
        })
    }

    /**
     * Fetches a promise to the Iterator for the next page, including its contents.
     * If there is no link provided for the next page, the promise will resolve to the `Empty` Iterator.
     * @returns {Promise<Iterator<T>>} a promise for the iterator of the next page
     */
    next() {
        let link = this.links.next

        if (link) return ulink(link.url).then(res => Iterator.promise(res, this.mapper))
        else return Promise.resolve(Iterator.FromList([]))
    }

    /**
     * Performs a request to the given `url` in the context of this iterator.
     */
    fetchLink(url: string): Promise<Iterator<T>> {
        return ulink(url).then(res => Iterator.promise(res, this.mapper))
    }
}

/**
 * State required to create a new CodePay user
 */
export interface NewCodepayUser {
    email: string
    role: number
}

/**
 * Global class for ULink interactions
 */
export default class ULinkService {
    //
    // Display & Semantics
    //

    /**
     * Returns an appropriate generic error message for the given argument.
     *
     * The argument is expected to be a Fetch `Response` or a status code,
     * but the function will exit cleanly with a default message regardless.
     *
     * @param response Response thrown from request, or status thrown directly
     * @param optionStr **DEPRECATED**; no longer does anything
     * @returns
     */
    static errorMsg(response: Response | number, optionStr?: string): string {
        var status
        if (response instanceof Response) status = response.status
        else status = response

        switch (status) {
            case 400:
                return "Error 400. Contact a Sionic administrator."
            case 401:
                return "Your session has expired. Refresh the page or log back in."
            case 403:
                return "You do not have permission for this action."
            case 404:
                return "Oops. Please refresh the page and try again."
            case 408:
                return "Oops. Your request took too long and timed out. Please try again."
            case 422:
                return "The current password is incorrect."
            default:
                return "An unexpected error has occured."
        }
    }

    //
    //
    //
    // API
    //
    //
    //

    //
    // Generics
    //

    /**
     * Sends a GET to `uri`, expecting a 2xx JSON response
     * @param {string} uri
     * @returns {Promise<any>} The JSON response
     */
    static getJSON(uri) {
        return ulink(uri).then(res => {
            if (!res.ok) throw res
            return res.json()
        })
    }

    /**
     * Fetches and constructs an instance of a class from the provided URI,
     * expecting a 2xx JSON response
     * @param clazz The class, with a constructor that takes a single `json: any` parameter
     * @param uri The URI to fetch
     * @returns A promise to the new class instance.
     */
    static getObject<T>(clazz: { new (json: any): T }, uri: string): Promise<T> {
        return ulink(uri)
            .then(res => {
                if (!res.ok) throw res
                return res.json()
            })
            .then(json => new clazz(json))
    }

    /**
     * Fetches a JSON object at the provided URI,
     * expecting a 2xx JSON response,
     * and generically casts it to the provided interface.
     * @param uri The URI to fetch
     * @returns A promise to the object, cast to the provided interface
     */
    static getInterface<T>(uri: string): Promise<T> {
        return ulink(uri).then(res => {
            if (!res.ok) throw res
            return res.json()
        })
    }

    /**
     * Sends a GET to `object`'s URI, expecting a 2xx JSON response, and returns a promise to the updated version of the same object class.
     * @template {Updateable} T
     * @param {T} object
     * @returns {Promise<T>} A promise to the updated instance of the same class.
     */
    static refreshObject(object) {
        const constructor = object.constructor
        return ulink(object.uri)
            .then(res => {
                if (!res.ok) throw res.status
                return res.json()
            })
            .then(json => new constructor(json))
    }

    /**
     * Sends a PATCH to `object`'s URI, updating it to the current state of `object`.
     * @param {Updateable} object
     */
    static update(object) {
        return ulink(object.uri, {
            method: "PATCH",
            json: object,
        })
    }

    /**
     * Sends a PATCH to `object`'s URI, expecting a 2xx JSON response
     * @template {Updateable} T
     * @param {T} object
     * @returns {Promise<T>} A promise to the updated instance of the same class.
     */
    static updateObject<T extends Updateable>(obj: T): Promise<T> {
        const constructor = obj.constructor
        return (
            ulink(obj.uri, { method: "PATCH", json: obj })
                .then(res => {
                    if (!res.ok) throw res.status
                    return res.json()
                })
                //@ts-ignore dunno how to do this properly lol
                .then(json => new constructor(json))
        )
    }

    static createIn<T>(uri: string, obj: T): Promise<T> {
        const constructor = obj.constructor
        return (
            //@ts-ignore
            ulink(uri, { method: "POST", json: obj })
                .then(res => {
                    if (!res.ok) throw res.status
                    return res.json()
                })
                // @ts-ignore We know we have a valid JSON constructor by convention - though we should probably solve this the right way
                .then(json => new constructor(json))
        )
    }

    /**
     * Generic HTTP PUT function that probably ought to be replaced
     * @param {string} uri
     * @param {object} body JSON body
     * @returns {Promise<Response>} Fetch response
     */
    static put(uri, body) {
        return ulink(uri, {
            method: "PUT",
            json: body,
        })
    }

    /**
     * Generic HTTP DELETE function
     * @param {string} uri
     * @returns {Promise<Response>} Fetch response
     */
    static delete(uri) {
        return ulink(uri, { method: "DELETE" }).then(res => {
            if (!res.ok) throw res.status
            return res
        })
    }

    /**
     * Generic HTTP LINK function
     * @param {string} uri
     * @param {object} links An object, whose values will be mapped as links in the `Link` header with a `rel` of their keys
     * @returns {Promise<Response>}
     */
    static link(uri, links) {
        return ulink(uri, {
            method: "LINK",
            headers: {
                Link: Object.keys(links)
                    .map(rel => `<${links[rel]}>; rel="${rel}"`)
                    .join(", "),
            },
        })
    }

    //
    // Namespaces
    //

    static session = class {
        static refresh(): Promise<Session> {
            return ulinkFetch("/v2/admin/session", { method: "GET" })
                .then(response => {
                    if (response.status == 401) return null
                    if (!response.ok) throw response

                    return response.json()
                })
                .then(json => json && new Session(json))
        }
    }

    static resellers = class {
        /**
         * @param {string} uri
         * @returns {Promise<Reseller>}
         */
        static get(uri) {
            if (!uri) return Promise.reject(404)

            return ulink(uri).then(res => {
                if (!res.ok) throw res.status

                return res.json().then(json => new Reseller(json))
            })
        }

        static getList(uri?: string) {
            return ulink(uri ?? "/v2/admin/resellers", { method: "GET" }).then(response => {
                if (!response.ok) throw response.status

                return Iterator.promise(response, x => new Reseller(x))
            })
        }

        static getResellerGroupMembers(uri: string) {
            return ulink(uri, { method: "GET" }).then(response => {
                if (!response.ok) throw response.status

                return Iterator.promise(response, x => new Reseller(x))
            })
        }

        static getResellerGroups(uri: string) {
            return ulink(uri, { method: "GET" }).then(response => {
                if (!response.ok) throw response.status
                return Iterator.promise(response, x => new ResellerGroup(x))
            })
        }

        static create(obj) {
            return ulink("/v2/admin/resellers", {
                method: "POST",
                json: obj,
            }).then(response => {
                if (!response.ok) throw response.status
                return response.json().then(json => new Reseller(json))
            })
        }

        static createResellerGroup(reseller_group: {
            group_name: string
            parent_reseller_id: string
            rev_share_rate: Big
        }) {
            return ulink("/v2/admin/resellers/group", {
                method: "POST",
                json: reseller_group,
            }).then(response => {
                if (!response.ok) throw response.status
                return response.json()
            })
        }

        static getResellerGroup(parent_reseller_id: string) {
            return ulink("/v2/admin/resellers/" + parent_reseller_id + "/group").then(response => {
                if (!response.ok) throw response.status

                return response.json()
            })
        }
    }

    static merchants = class {
        /**
         * @param {string} uri
         * @returns {Promise<Merchant>}
         */
        static get(uri) {
            if (!uri) return Promise.reject(404)

            return ulink(uri).then(response => {
                if (!response.ok) throw response.status

                return response.json().then(json => new Merchant(json))
            })
        }

        /**
         * @param {?string} uri The URI to fetch the list from; or `null` to fetch all merchants
         * @returns {Promise<Iterator<Merchant>>}
         */
        static getList(uri) {
            return ulink(uri ?? "/v2/admin/entities", { method: "GET" }).then(response => {
                if (!response.ok) throw response.status

                return Iterator.promise(response, x => new Merchant(x))
            })
        }

        /**
         *
         * @param obj The Merchant to create
         * @param uri The URI to post to, or the default if not provided
         * @returns
         */
        static create(obj: Merchant, uri: string = null) {
            return ulink(uri ?? "/v2/admin/entities", {
                method: "POST",
                json: obj,
            }).then(response => {
                if (!response.ok) throw response.status
                return response.json().then(json => new Merchant(json))
            })
        }

        static CodePayUsers(
            merchant: Merchant,
            req?: RequestInit
        ): Promise<Iterator<MerchantUser>> {
            return ulink(merchant._links.codepay_users, req).then(res => {
                if (!res.ok) throw res.status

                return Iterator.promise(res, json => new MerchantUser(json))
            })
        }

        /**
         * Adds a CodePay user by e-mail address to the given merchant, with the provided role within that merchant
         */
        static addCodepayUser(merchant: Merchant, user: NewCodepayUser): Promise<MerchantUser> {
            let req = {
                method: "POST",
                json: user,
            }

            return ulink(merchant._links.codepay_users, req).then(res => {
                if (!res.ok) throw res.status

                return res.json().then(json => new MerchantUser(json))
            })
        }

        /**
         * Fetches the list of transactions for a Merchant
         */
        static merchantTransactions(
            merchant: Merchant,
            req: RequestInit = {}
        ): Promise<Iterator<Transaction>> {
            return ulink(merchant._links.transactions, req).then(res => {
                if (!res.ok) throw res.status
                return Iterator.promise(res, json => new Transaction(json))
            })
        }
    }

    static locations = class {
        /**
         * @param {string} uri
         * @returns {Promise<Location>}
         */
        static get(uri) {
            if (!uri) return Promise.reject(404)

            return ulink(uri).then(response => {
                if (!response.ok) throw response.status

                return response.json().then(json => new Location(json))
            })
        }

        /**
         * @param {string} uri
         * @returns {Promise<Iterator<Location>>}
         */
        static getIterator(uri) {
            if (!uri) return Promise.reject(404)

            return ulink(uri).then(res => {
                if (!res.ok) throw res.status

                return Iterator.promise(res, json => new Location(json))
            })
        }

        /**
         * @returns {Promise<Iterator<Location>>}
         */
        static all(uri?) {
            return ulink(uri ?? "/v2/admin/locations").then(res => {
                if (!res.ok) throw res.status

                return Iterator.promise(res, json => new Location(json))
            })
        }

        /**
         * @param {Location} location
         * @returns {Promise<MIDConfig>}
         */
        static getMID(location) {
            return ulink(location._links.mid)
                .then(res => {
                    if (!res.ok) throw res.status
                    return res.json()
                })
                .then(json => json && new MIDConfig(json))
        }

        /**
         * @param {Merchant} merchant
         * @param {Location} location
         * @returns {Promise<Location>}
         */
        static createInMerchant(merchant, location) {
            return ulink(merchant._links.locations, { method: "POST", json: location }).then(
                res => {
                    if (!res.ok) throw res
                    return res.json().then(json => new Location(json))
                }
            )
        }
    }

    static admins = class {
        /**
         *
         * @returns {Promise<Iterator<Admin>>}
         */
        static all() {
            return ulink("/v2/admin/admins").then(res => {
                if (!res.ok) throw res.status

                return Iterator.promise(res, json => new Admin(json))
            })
        }

        static listUsers(uri: string) {
            return ulink(uri ?? "/v2/admin/admins", { method: "GET" }).then(response => {
                if (!response.ok) throw response.status

                return Iterator.promise(response, x => new ContextUser(x))
            })
        }

        static globalListUsers(uri: string) {
            return ulink(uri ?? "/v2/admin/admins", { method: "GET" }).then(response => {
                if (!response.ok) throw response.status

                return Iterator.promise(response, x => new GlobalUser(x))
            })
        }

        // These will have to be separated eventually - see issue #1

        /**
         * Accepts an Admin invitation
         * @param {object} body
         * @param {string} body.code The code from the original Invite
         * @param {string} body.name New Admin's human name
         * @param {string} body.email New Admin's email
         * @param {string} body.email New Admin's password
         * @throws InviteError
         * @returns { Promise<Response> }
         */
        static inviteAccept(body) {
            // No longer mirroring because of our new session handling logic
            // The general mirror endpoint presumes a valid session, which we will not have here
            return fetch("/ulink/invite-accept", {
                method: "POST",
                body: JSON.stringify(body),
                headers: { "Content-Type": "application/json" },
            })
        }

        /**
         * Creates an Admin invitation
         * @returns { Promise<Invite> }
         */
        static inviteCreate() {
            return ulink("/v2/admin/admins/invite-create", { method: "POST" }).then(res => {
                if (!res.ok) throw res.status
                return res.json().then(json => ({
                    code: json.code,
                    expires: new Date(json.expires),
                }))
            })
        }

        /**
         *
         * @param {string} id
         * @returns {Promise<Admin>}
         */
        static find(id: string) {
            return ulink("/v2/admin/admins/" + id).then(response => {
                if (!response.ok) throw response.status

                return response.json().then(json => new Admin(json))
            })
        }

        static updatePassword(password_object) {
            const req = {
                method: "POST",
                json: password_object,
            }

            return ulink("/v2/admin/session/user", req).then(res => {
                if (!res.ok) throw res.status
            })
        }

        static getResetLink(password_object) {
            const req = {
                method: "POST",
                json: password_object,
            }

            return ulink("/v2/admin/invites/password-reset", req).then(res => {
                if (!res.ok) throw res.status
                return res.json()
            })
        }

        static setNewPassword(password_object) {
            const req = {
                method: "POST",
                json: password_object,
            }

            return ulink(password_object.uri, req).then(res => {
                if (!res.ok) throw res.status
            })
        }

        static sendResetLink(password_object) {
            const req = {
                method: "POST",
                json: password_object,
            }

            return ulink("/v2/admin/invites/send-reset-email", req).then(res => {
                if (!res.ok) throw res.status

                return res.status
            })
        }
    }

    static cpayUsers = class {
        /**
         * @param {string} uri The URI to pull from, or `null` to fetch all users
         * @returns {Promise<Iterator<CpayUser>>}
         */
        static iterator(uri = null) {
            return ulink(uri ?? "/v2/admin/codepay-users").then(res => {
                if (!res.ok) {
                    throw res.status
                }

                return Iterator.promise(res, json => new CpayUser(json))
            })
        }

        /**
         *
         * @param uri The URI of the collection of `MerchantUser`s
         * @returns
         */
        static merchantUsers(uri: string): Promise<Iterator<MerchantUser>> {
            return ulink(uri).then(res => {
                if (!res.ok) {
                    throw res.status
                }

                return Iterator.promise(res, json => new MerchantUser(json))
            })
        }

        /**
         *
         * @param {number} id
         * @returns {Promise<CpayUser>}
         */
        static byID(id) {
            return ulink("/v2/admin/codepay-users/" + id).then(res => {
                if (!res.ok) throw res.status

                return res.json().then(json => new CpayUser(json))
            })
        }
    }

    static transactions = class {
        /**
         *
         * @returns {Promise<Iterator<Transaction>>}
         */
        static all() {
            return ulink("/v2/admin/transactions").then(res => {
                if (!res.ok) throw res.status

                return Iterator.promise(res, json => new Transaction(json))
            })
        }

        /**
         *
         * @param {number} id
         * @returns {Promise<Transaction>}
         */
        static find(id) {
            return ulink("/v2/admin/transactions/" + id).then(response => {
                if (!response.ok) throw response.status

                return response.json().then(json => new Transaction(json))
            })
        }

        /**
         * Fetches the list of transactions for a Location
         * @param {Location} location
         * @param {RequestInit} req  Additional request parameters
         * @returns {Promise<Iterator<Transaction>>}
         */
        static filterByLocation(location, req) {
            return ulink(location._links.transactions, req).then(res => {
                if (!res.ok) throw res.status
                return Iterator.promise(res, json => new Transaction(json))
            })
        }
    }

    static smb_menus = class {
        /**
         * @param {string} uri
         * @returns {Promise<Menu>}
         */
        static get(uri) {
            return ulink(uri).then(res => {
                if (!res.ok) throw res
                return res.json().then(json => new Menu(json))
            })
        }
    }

    static configs = class {
        static getMidCybs(uri: string): Promise<MIDCybs> {
            return ulink(uri).then(response => {
                if (!response.ok) throw response.status

                return response.json().then(json => new MIDCybs(json))
            })
        }

        static getMidDDA(uri: string): Promise<MIDdda> {
            return ulink(uri).then(response => {
                if (!response.ok) throw response.status
                return response.json().then(json => new MIDdda(json))
            })
        }
    }

    static uploads = class {
        /**
         * @param {string} uri
         * @returns {Promise<Upload>}
         */
        static listResellerResources(uri?: string) {
            return ulink(uri ?? "/v2/resources/resellers", { method: "GET" }).then(res => {
                if (!res.ok) throw res.status
    
                return Iterator.promise(res, json => new Upload(json))
            })
        }
    }

}

//
// Other definitions
//

/** @typedef {{ code: string, expires: Date }} Invite */
