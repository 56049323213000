import React from "react";

export function uniqid() { 
    let HEX = "0123456789ABCDEF";

    var str = '-'; // SMB menu backend looks for first character to see if string or int 
    str += Date.now().toString(16);
    str += '-'; 
    for(var i = 15; i; i--) str += HEX[ Math.floor( Math.random() * 16 ) ];

    return str; 
}


/**
 * Inserts `obj` into `arry` at position `obj.position`, and accordingly increments the `position` of every element afterwards
 * @param {Array<{ position: number }>} arry 
 * @param {{ position: number }} obj 
 */
export function insertWithPos(arry, obj) { 
    let pos = obj.position || arry.length
    obj.position = pos 
    
    arry.splice(pos, 0, obj)
    for(let i = pos + 1; i < arry.length; i++) arry[i].position += 1 
}


/**
 * Extracts the specified properties from the input object and inserts them into the output object.
 * 
 * For all properties of `input`, 
 * this function overwrites the corresponding property of `output` if it exists,
 * or assigns it to the new object returned by this function, 
 * effectively splitting the `input` object in two.  
 * 
 * This is a utility function for creating passthrough react components
 * that can use some of the props given while passing all of the others along to their output.  
 * 
 * This kind of spooky side-effect is a bad idea in most languages, 
 * but this pattern is common enough in React that it justifies having its own function.
 * 
 * @param {object} input The input object, which is left unchanged
 * @param {object} output The object in which to extract all properties from `input`
 * 
 * @returns {React.HTMLAttributes} A clone of `input` *without* any of the properties extracted to `output`
 * 
 */
export function extractProps(input, output) { 
    let propsOut = {}

    let outKeys = Object.keys(output) 
    for(const key in input) { 
        if(outKeys.indexOf(key) === -1)     propsOut[key] = input[key] 
        else                                output[key] = input[key] 
    }
    
    return propsOut
}