import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { validateInput, helperText } from './InputValidator';
import { ulinkFetch } from "services/ULink";
import { toaster } from 'global-ui';

export default function(props: any) {
    const [ errorFree, setErrorFree ]   = useState(true);

    function determineHelperText(): string {
        return errorFree ? "" : helperText[props.type]?.default || "";
    }

    function notifyIfEmailInUse(email: string) {
        ulinkFetch('/v2/admin/exists/', { method: 'POST', json: { email: email } })
        .then(res => res.json())
        .then(email => { if(email.exists) toaster.emailExists(); })
        .catch(error => { console.error("Error checking if email is in use", error) });
    }

    return (
        <TextField
            type={props.type}
            label={props.label}
            value={props.value}
            required={props.required}
            margin={props.margin || ""}
            onChange={(e) => {
                props.onChange(e);
            }}
            autoComplete={props.autoComplete || ""}
            error={props?.errorOverwrite || !errorFree}
            helperText={props?.helperTextOverwrite || determineHelperText()}
            onBlur={(e) => {
                if(props.type === "email" && props.value) notifyIfEmailInUse(props.value);
                setErrorFree(() => props.required ? validateInput(props.type, props.value) : true);
            }}
        />
    )
}