/** 
 * The environment of the application.  
 * development | remote_dev | remote_qa | production 
 * @type {!String} 
 * @default "development"
 */
export const ENV = (GLOBALS.ENV); 


/** @typedef {{ id: number, display_name: string, programmatic_name: string }} PartnerBank */

/** @type {[PartnerBank]} */
export const partner_banks = GLOBALS.ULINK_INFO.partner_banks;

/**
 * Finds an RTP Bank by its ID
 * @param {number} id 
 * @returns {?PartnerBank}
 */
export function partnerBankByID(id) { 
    let i = partner_banks.findIndex(x => x.id === id) 
    return i == -1 ? null : partner_banks[i]
}

/** 
 * Maps number => status display name 
 * @type {object} 
 */
export const entity_location_statuses = GLOBALS.ULINK_INFO.entity_location_statuses; 


/** 
 * Maps number => status display name 
 * @type {object} 
 */
export const admin_statuses = GLOBALS.ULINK_INFO.admin_statuses; 





/** 
 * Maps number => status display name 
 * @type {object} 
 */
export const dda_statuses = GLOBALS.ULINK_INFO.dda_statuses; 


/** 
 * Maps number => status display name 
 * @type {object} 
 */
export const mid_statuses = GLOBALS.ULINK_INFO.mid_statuses;


/** 
 * Maps number => status display name 
 * @type {object} 
 */
export const s4_token_statuses = GLOBALS.ULINK_INFO.mid_statuses;


export class RoleDescriptor { 
    constructor(json) { 
        this.value = json.value 
        this.display_name = json.display_name
    }
}

export class RoleValues { 
    constructor(json) { 
        /** @type {[RoleDescriptor]} */
        this.roles = json
    }

    getNameForValue(value) { return this.roles.find( x => x.value == value ).display_name }
}

const admin_context_roles = GLOBALS.ULINK_INFO.admin_context_roles
export const roles = { 
    merchant: new RoleValues(admin_context_roles.merchant),
    reseller: new RoleValues(admin_context_roles.reseller), 
    sionic: new RoleValues(admin_context_roles.sionic), 
}