

export default class { 

    constructor(json) { 

        /** @type {("DISCOUNT_FLAT"|"DISCOUNT_RATE"|"FREE_ITEM")} */
        this.type = json.$

        /** @type {number} */
        this.value_flat = json.value_flat
        /** @type {number} */
        this.value_rate = json.value_rate 

        /** @type {number} */
        this.minimum_purchase = json.minimum_purchase 

        /** @type {boolean} */
        this.subtracted_from_total = json.subtracted_from_total 

        /** @type {string} */
        this.product_description = json.product_description

    }


}