/**
 * Represents any Admin user. 
 * 
 * The current logged in user may be wrapped in a `Session` object.
 */
export default class Admin { 

    id : string
    uri : string

    status : number
    
    name : string
    email : string

    constructor(obj: any) { 
        this.id = obj.id 
        this.uri = obj.uri 

        this.status = obj.status 

        this.name = obj.name 
        this.email = obj.email 
    }
}

/**
 * Represents a session of a logged-in Admin user. 
 * 
 * Contains an `Admin` object with the session user's data, 
 * along with an array of `SessionContext` objects that they may have access to. 
 */
export class Session { 
    user: Admin
    contexts: SessionContext[]

    constructor(obj: any) { 
        this.user = new Admin(obj) 
        this.contexts = obj?.contexts?.map((json: any) => new SessionContext(json)) ?? []
    }
}


export type SessionContextType = 'reseller' | 'merchant' | 'sionic'

export class SessionContext { 
    business: string
    role: string
    links: SessionContextLink[]

    invite_links: {
        merchant: string
        reseller: string
        user: string
    }
    type: 'reseller' | 'merchant' | 'sionic'

    constructor(obj : any) { 
        this.type       = obj.type 
        this.business     = obj.business 
        this.role         = obj.role 
        this.links      = obj.links.map((json: any) => new SessionContextLink(this.type, json))
        this.invite_links = obj.invite_links
    }
}



export type LinkType = 'location' | 'merchant' | 'reseller' | 'transaction' | 'user' | 'global-user' | 'reseller-group'

/**
 * Represents one of the user's main view options after they first select a session context. 
 * 
 * These are the views shown to the user in the nav bar. 
 */
export class SessionContextLink { 
    parentType: SessionContextType

    name: string 
    type: LinkType 
    isCollection: boolean
    uri: string 

    constructor(parentType: SessionContextType, obj: any) { 
        this.parentType   = parentType
        this.name         = obj.name
        this.type         = obj.type
        this.isCollection = obj.is_collection
        this.uri          = obj.uri
    }

    /**
     * 
     * @returns The route of the appropriate view depending on this link's type 
     */
    getRoute() : string { 
        switch(this.type) { 
            case 'location': 
                if(this.isCollection)   return '/locations'
                else                    return '/location'
            
            case 'merchant': 
                if(this.isCollection)   return '/merchants'
                else                    return '/merchant'

            case 'reseller':
                if(this.isCollection)   return '/resellers'
                else                    return '/reseller'
            
            case 'reseller-group':
                if(this.isCollection)   return '/reseller-groups'
                else                    return '/reseller-group'
            
            case 'transaction':
                if(this.isCollection)   return '/transactions'
                else                    return '/transaction'

            case 'user':
                if(this.isCollection)   return `/${this.parentType}/users`
                else                    return `/${this.parentType}/user`

            case 'global-user':
                if(this.isCollection)   return '/global/users'
                else                    return '/global/user'
        }
    }

    /**
     * 
     * @returns The full relative URI that can be linked to when the user wishes to navigate 
     */
    getHref() : string { 
        return `${this.getRoute()}?uri=${encodeURIComponent(this.uri)}`
    }
}