import React, { useRef, useEffect } from "react"
import { Routes, Route, useLocation } from "react-router-dom"

import { Dashboard } from "./pages/Dashboard"
import { ListMerchants } from "./pages/merchants/ListMerchants"
import { ViewMerchant } from "./pages/merchants/ViewMerchant"
import { AddMerchant } from "./pages/merchants/AddMerchant"

import { AddReseller } from "./pages/resellers/AddReseller"
import { ListResellers } from "./pages/resellers/ListResellers"
import { ViewReseller } from "./pages/resellers/ViewReseller"

import { ListUsers } from "./pages/users/ListUsers"

import { ListLocations } from "./pages/locations/ListLocations"
import AddLocation from "./pages/locations/AddLocation"
import ListAdmins from "./pages/admins/ListAdmins"
import ViewAdmin from "./pages/admins/ViewAdmin"
import ListCpayUsers from "./pages/cpay-users/ListCpayUsers"
import ViewCpayUser from "./pages/cpay-users/ViewCpayUser"
import { AddCpayUser } from "./pages/cpay-users/AddCpayUser"
import ViewLocation from "./pages/locations/ViewLocation"
import { ListTransactions } from "./pages/transactions/ListTransactions"
import ViewTransaction from "./pages/transactions/ViewTransaction"

import SmbMenu from "./pages/locations/smb-menu/SmbMenu"
import { CreateInvite } from "./pages/resellers/CreateInvite"
import { Frame } from "./Frame"
import { GlobalState } from "GlobalState"
import ViewUser from "./pages/users/ViewUser"
import { ListResellerGroups } from "./pages/resellers/ListResellerGroups"
import { ViewResellerGroup } from "./pages/resellers/ViewResellerGroup"
import { ViewProfile } from "./pages/profile/ViewProfile"
import SystemConfig from "./pages/sionic-only/SystemConfig"
import AgreementEdit from "./pages/sionic-only/AgreementEdit"

export const Main = () => {
    const location = useLocation()

    const { setMainRef, notFound, errored } = GlobalState.useContainer()

    const $NotFound = (
        <div>
            <main id="NotFound" className="basic">
                <h1>Page not found</h1>
            </main>
        </div>
    )

    const $Errored = (
        <div>
            <main id="Error" className="basic">
                <h1>An unexpected error occurred</h1>
                <p>Please try again.</p>
            </main>
        </div>
    )

    const mainRef = useRef(null)

    useEffect(() => {
        setMainRef(mainRef)
    }, [])

    return (
        <Frame>
            <>{errored === location.pathname && $Errored}</>
            <>{notFound == location.pathname && $NotFound}</>
            <>
                {!notFound && !errored && (
                    <Routes>
                        <Route element={<Dashboard />} path="/" />

                        <Route element={<AddLocation />} path="/locations/add" />
                        <Route element={<ListLocations />} path="/locations" />
                        <Route element={<SmbMenu />} path="/location/smb-menu" />
                        <Route element={<ViewLocation />} path="/location" />

                        <Route element={<AddMerchant />} path="/merchants/add" />
                        <Route element={<ListMerchants />} path="/merchants" />
                        <Route element={<ViewMerchant />} path="/merchant" />

                        <Route element={<AddReseller />} path="/resellers/add" />
                        <Route element={<CreateInvite />} path="/:inviteType/invite" />
                        <Route element={<ListResellers />} path="/resellers" />
                        <Route element={<ViewReseller />} path="/reseller" />
                        <Route element={<ListResellerGroups />} path="/reseller-groups" />
                        <Route element={<ViewResellerGroup />} path="/reseller-group" />

                        <Route element={<ListAdmins />} path="/admins" />
                        <Route element={<ViewAdmin />} path="/admins/:admin_id" />

                        <Route element={<ListUsers />} path="/:userType/users" />
                        <Route element={<ViewUser />} path="/:userType/user" />

                        <Route element={<ViewProfile />} path="/profile" />

                        <Route element={<ListCpayUsers />} path="/codepay-users" />
                        <Route element={<AddCpayUser />} path="/codepay-users/add" />
                        <Route element={<ViewCpayUser />} path="/codepay-users/:cpay_user_id" />

                        <Route element={<ListTransactions />} path="/transactions" />
                        <Route element={<ViewTransaction />} path="/transactions/:transaction_id" />

                        <Route element={<SystemConfig />} path="/system-configuration" /> 
                        <Route element={<AgreementEdit />} path="/system-configuration/agreement" />


                        <Route path="*" element={$NotFound} />
                    </Routes>
                )}
            </>
        </Frame>
    )
}
