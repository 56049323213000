import Big from 'big.js'

import { entity_location_statuses } from "globals"

export default class Location { 

    constructor(obj) { 
        if(! obj) obj = { 
            status: Object.keys(entity_location_statuses)[0],

            name: "", 
            address_1: "", 
            address_2: "", 
            city: "", 
            region: "", 
            postal_code: "",
            override_allow_tips: null,

            _links: {}
        }

        // https://paste.ubuntu.com/p/kKNkhkSKFj/ 

        /** @type {Object} */
        this.brand = obj.brand 
        /** @type {string} */
        this.id = obj.id 
        /** @type {string} */
        this.uri = obj.uri 

        /** @type {number} */
        this.status = obj.status

        /** @type {string} */
        this.name = obj.name 

        /**
         * The URL slug that Order Ahead [will be able to] use as an alias instead of Location ID. 
         * 
         * May be `null`, but may not be empty.  Other validation pending.
         * @type {?string}
         */
        this.url_slug = obj.url_slug

        /** @type {string} */
        this.phone = obj.phone 
        /** @type {string} */
        this.address_1 = obj.address_1
        /** @type {string} */
        this.address_2 = obj.address_2
        /** @type {string} */
        this.city = obj.city 
        /** @type {string} */
        this.region = obj.region 
        /** @type {string} */
        this.postal_code = obj.postal_code 
        /** @type {boolean} */
        this.override_allow_tips = obj.override_allow_tips

        /** @type {Object} */
        this.entity = obj.entity

        //TODO: Brand
        //TODO: Created/updated, once API supports 


        /** @type {boolean} */
        this.has_rtp = obj.has_rtp
        /** @type {boolean} */
        this.has_mid = obj.has_mid
        /** @type {boolean} */
        this.has_smb_ordering = obj.has_smb_ordering
        

        /** @typedef {{ mid_cybs: string, mid_dda: string }} ConfigLinks */

        /**
         * @type {{ 
         *  transactions: string, 
         *  merchant: string, siblingLocations: string, 
         *  override_configs: ConfigLinks,
         *  ordering: { smb_menu: ?string, smb_migrate: ?string },
         *  uploads: { banner: string, logo: string }, 
         * }} 
         * @readonly
         */
         this._links = obj._links 

    }


    /**
     * Returns the full address as a one-line string. 
     * @returns {string}
     */
    fullAddress() { 
        var str = this.address_1 
        if(this.address_2) str += ', ' + this.address_2
        str += `, ${ this.city}, ${ this.region }, ${ this.postal_code }`
        return str 
    }

}



export class MIDConfig { 
    constructor(json) { 
        /** @type {number} */
        this.uri           = json.uri 

        /**
         * Status enum; use `globals.mid_statuses` to get display name 
         * @type {number}
         */
        this.status        = json.status 

        /** @type {string} */
        this.serial_number = json.serial_number 

        this.dda_account   = json.dda_account ? new DDAAccount(json.dda_account) : null 
        this.s4_tokens     = json.s4_tokens.map(x => new Shift4Token(x))

        /** @type {{ s4_tokens: string }} */
        this._links        = json._links
    }
}


export class Shift4Token { 
    constructor(json) { 
        /** @type {number} */
        this.id = json.id 
        /** @type {string} */
        this.uri            = json.uri

        /**
         * Status enum; use `globals.mid_statuses` to get display name 
         * @type {number}
         */
        this.status         = json.status 

        /** @type {string} */
        this.value          = json.value
        /** @type {string} */
        this.server_name    = json.server_name

        /** @type {boolean} */
        this.mag_stripe     = json.mag_stripe
        /** @type {boolean} */
        this.manual_entry   = json.manual_entry
    }
}


export class MIDCybs { 
    constructor(json) {
        /** @type {string} */ 
        this.uri                = json.uri 
        /** @type {boolean} */
        this.active             = json.active 
        /** @type {string} */
        this.merchant_ident     = json.merchant_ident
    }
}

export class MIDdda {
    constructor(json){
        /**@type {string} */
        this.uri = json.uri
        /**@type {boolean} */
        this.active = json.active
        /**@type {string} */
        this.masked_account = json.masked_account
        /**@type {string} */
        this.masked_routing = json.masked_routing
        /**@type {string} */
        this.name_first = json.name_first
        /**@type {string} */
        this.name_last = json.name_last
    }
}