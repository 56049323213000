import React, { useState, useEffect} from "react";
import { Link, useNavigate, useSearchParams} from 'react-router-dom'; 
import Loader from "widgets/Loader";

import ULinkService, { Iterator } from 'services/ULink'; 
import Location from "data/Location";
import LocationInfo from "./LocationInfo";
import { GlobalState } from "GlobalState"

import {
    Box, Paper, Container
} from '@mui/material'



export default function(props){

    const { scrollToTop } = GlobalState.useContainer()

    let query = useSearchParams()[0];

    //
    //Form State
    //
    let [ hasUnsaved, setHasUnsaved ] = useState(false) 

    let [ name, setName ]                       = useState(''); 
    let [ phone, setPhone ]                     = useState(''); 
    let [ address_1, setAddress_1 ]             = useState(''); 
    let [ address_2, setAddress_2 ]             = useState('');
    let [ city, setCity ]                       = useState('');
    let [ region, setRegion ]                   = useState('');
    let [ postal_code, setPostal_Code ]         = useState('');
    let [ status, setStatus ]                   = useState(0);

    //
    // Merchant data
    //
    /** @type {[ ?Merchant, function]} */
    let [ merchant, _set_merchant ] = useState(null);

    /**
     * Updates the merchant from a fetch, loading form state in the process
     * @param {Merchant} merchant 
     */
    let setMerchant = (merchant) => { 
        _set_merchant   (merchant);

        setName         (merchant.name);
        setStatus       (merchant.status);
    }

    useEffect(() => {
        scrollToTop();
    },[])
    

    useEffect(() => { 
        // Initial merchant fetch
        ULinkService.merchants.get( query.get('uri') )
        .then( setMerchant )
        .catch(err => {
            console.log(err);
            ULinkService.notifyError()
        })
    }, [])


    return (
        <Container>
            <section role="banner">
                <h1>Add a location</h1>
            </section>

            <Box>
                <Paper sx={{padding: "2rem"}} children={
                    <LocationInfo 
                        isNew={true} 
                        setHasUnsaved={setHasUnsaved} 
                        merchantObj={merchant} />
                } />
            </Box>
        </Container>  
    )
}