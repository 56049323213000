import React from "react";

import { Link } from 'react-router-dom'; 

/**
 * Utility class for Material Icons
 * 
 * @component
 * @param {object} props All props will be rendered as passed, except the defined props for this component
 * @param {string} props.name Icon Name
 * @param {"outlined"|"rounded"|"sharp"|"two-tone"} [props.theme] Material Icons theme
 * @param {string} [props.className] Additional CSS classes
 * 
 * @param {string} [props.href] Renders the button as a react-router `Link` element to this parameter. 
 * 
 * @param {function} [props.onClick] Renders the icon as a button that invokes this function.
 * This includes setting `role="button"`. 
 * Please define `aria-label` and other appropriate accessibility attributes if using this functionality.
 * 
 */
export function Icon(props) { 
    let pc = props.className; 

    let theme = props.theme
    theme = theme ? `-${theme}` : '';

    let className = `material-icons${theme} ${pc ?? ''}`;

    /**
     * @type {React.HTMLAttributes}
     */
    let cp = Object.assign({}, props);
    delete cp.className; 
    delete cp.name;
    delete cp.href; 
    //TODO: Use `extractProps` from utils.js if we expand this; that's what I made it for, it should be a bit less error-prone 

    if(props.onClick) { 
        cp['data-clickable'] = true 
        cp.role = 'button'; 

        if(! props['aria-label']) console.warn("Icon Button does not have an aria-label") 
    } else { 
        cp.role = 'img'
    }

    var icon = <i className={ className } {... cp} data-icon-name={ props.name }>{ props.name }</i>

    if(props.href) { 
        cp.role = 'button'; 
        return <Link to={props.href}  className="icon-button" >{icon}</Link>
    }
    else return icon; 
}

/**
 * Generic modal dialog 
 * @param {object} props 
 * @param {string} props.id ID to pass to the `dialog` HTML element
 * @param {function} props.close Function to close the dialog 
 * @param {string} props.title Title shown at the top 
 * 
 * @param {*} props.children
 * @returns {React.ComponentType}
 */
export function Dialog(props) { 

    return <div className="dialog-wrapper" onClick={ props.close }>
        <dialog open id={props.id} onClick={ e => e.stopPropagation() /* to prevent inner clicks from closing modal via dialog-wrapper onClick */ }>
            <div className="dialog-header">
                <h1>{ props.title }</h1>
                <Icon name="close" onClick={ props.close } aria-label="Close Dialog" />
            </div>

            <div className="dialog-body">
                { props.children }
            </div>
        </dialog>
    </div>


}