import React  from 'react';

import * as MenuData from 'lib/lib-smb-menus/data/Menu'

import MenuSection from './MenuSection';
import MenuItem from './MenuItem';
import EditorState from '../../EditorState';

/**
 * 
 * @param {object} props  
 * @param {MenuData.default} props.menuData 
 * @param {EditorState} props.editorState
 * @param {React.Dispatch<EditorState>} props.setEditorState 
 * 
 * @param {[MenuData.MenuSection]} props.childSections 
 * @param {[MenuData.MenuItem]} props.childItems
 * @param {MenuData.MenuSection} props.section 
 * 
 * @param {boolean} props.headless If `true`, do not render editable section header (See `MenuView.jsx`)
 */
function Part(props) { 

    const cprops = { 
        menuData: props.menuData, 
        editorState: props.editorState, 
        setEditorState: props.setEditorState,
    }

    return <>
        { !props.headless && <MenuSection section={ props.section } {... cprops} /> }
        {
            props.childSections.map(section => { 

                return <Part key={ section.id }  section={ props.menuData.getSection(section.id) }  headless={ false } 
                    childItems={ props.menuData.menuItems(section.id) }  childSections={ props.menuData.menuSections(section.id) } 
                    {... cprops} />

            })
        } { 
            props.childItems.map(item => <MenuItem key={ item.id } item={ item } {... cprops} />)
        }
    </>
}


/**
 * 
 * @param {object} props  
 * @param {MenuData.default} props.menuData 
 * @param {EditorState} props.editorState
 * @param {React.Dispatch<EditorState>} props.setEditorState 
 * 
 * @param {[MenuData.MenuSection]} props.childSections 
 * @param {[MenuData.MenuItem]} props.childItems
 * @param {MenuData.MenuSection} props.section 
 */
export default function(props) {

    return <div id="Editor">
        <Part {... props} headless={ true }  />
    </div>
};