import React, { useState } from 'react'; 
import { uniqid } from '../utils.js'; 

export default function(props) { 
    const key = useState(uniqid())[0]; 

    let editable = !! props.onChange; 
    let display = props.display;

    let valueProps = {}; 
    if(editable) valueProps.onChange = props.onChange; 
    else valueProps.readOnly = true; 

    return <div className="LabelledInput">
        <label htmlFor={key}>{ display }</label>
        <input id={key} type="text" aria-disabled={! editable} 
            value={ props.value } { ... valueProps }
            placeholder={ props.placeholder || display }/>
    </div>

}