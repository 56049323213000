import { GlobalState } from "GlobalState";

import React from "react";
import { Link } from "react-router-dom";

export default function(props) { 
    let global = GlobalState.useContainer()

    return <>
        <Link to="/system-configuration/agreement">Test</Link>
    </>
}