import React, { useEffect } from "react"
import { GlobalState } from "GlobalState"

export const Dashboard = () => {
    const { scrollToTop } = GlobalState.useContainer()
    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <div id="Dashboard">
            <h1>Dashboard</h1>
        </div>
    )
}
