import React, { useState, useEffect } from "react";

import ULinkService from "services/ULink";
import { DDAAccount, MIDConfig, Shift4Token } from "data/Location";
import { dda_statuses, mid_statuses, partner_banks, s4_token_statuses } from "globals";

import { toaster } from "global-ui";
import { Dialog } from "utils";

/**
 * A fragment containing `tbody` groups representing a Direct Deposit account; shared by MID and RTP
 * 
 * @param {object} props 
 * @param {DDAAccount} props.dda
 * @param {function(object)} props.mergeState
 */
function DDARows(props) { 
    return <>
        <tbody>
            <tr><th colSpan="2">Bank Account</th></tr>
            <tr>
                <td>Partner Bank</td>
                <td>
                    <select value={ props.dda.bank_id ?? ''}  onChange={ e => props.mergeState({ bank_id: e.target.value }) } >
                        <option value='' >Unknown</option>
                        { partner_banks.map(bank => 
                            <option key={ bank.id } value={ bank.id } >{ bank.display_name }</option>
                        ) }
                    </select>
                </td>
            </tr>
            <tr>
                <td>Status</td>
                <td>
                    <select value={ props.dda.status }   onChange={ e => props.mergeState({ status: e.target.value })} >
                        { Object.keys(dda_statuses).map( key => <option key={key} value={key}>{ dda_statuses[key] }</option> )}
                    </select>
                </td>
            </tr>
        </tbody>

        <tbody>
            <tr><th colSpan="2">DDA Information</th></tr>
            <tr>
                <td>Account Number</td>
                <td><input type="text" value={ props.dda.dda_account ?? '' } onChange={ e => props.mergeState({ dda_account: e.target.value }) }  /></td>
            </tr>
            <tr>
                <td>Routing Number</td>
                <td><input type="text" value={ props.dda.dda_routing ?? '' } onChange={ e => props.mergeState({ dda_routing: e.target.value })} /></td>
            </tr>
        </tbody>

        <tbody>
            <tr><th colSpan="2">Override Fee Settings</th></tr>
            <tr>
                {/* We need to implement a Big.js input for these, as well as one specific to percentages  */}
                <td>Flat fee to Payment Rail</td>
                <td><input type="text" readOnly placeholder="(Not yet implemented)" /></td>
            </tr>
            <tr>
                <td>Percentage fee to Payment Rail</td>
                <td><input type="text" readOnly placeholder="(Not yet implemented)" /></td>
            </tr>
            <tr>
                <td>Flat fee to Sionic</td>
                <td><input type="text" readOnly placeholder="(Not yet implemented)" /></td>
            </tr>
            <tr>
                <td>Percentage fee to Sionic</td>
                <td><input type="text" readOnly placeholder="(Not yet implemented)" /></td>
            </tr>
        </tbody>
    </>
}


/**
 * The contents of the "RTP Configuration" panel
 * 
 * @param {object} props 
 * @param {DDAAccount} props.rtp 
 */
export function RTP(props) { 

    let [ object, setObject ] = useState(props.rtp)
    let [ state, setState ] = useState({}) 
    let [ hasUnsaved, setHasUnsaved ] = useState(false)


    const mergeState = (update) => { 
        setHasUnsaved(true) 
        setState( old => Object.assign({}, old, update) )
    }

    const onSave = () => { 
        ULinkService.updateObject(Object.assign(object, state))
            .then(updated => { 
                ULinkService.notifySucess()
                setHasUnsaved(false)
                setObject(updated)
                setState({})
            })
            .catch(toaster.catchULink) 
    }


    return <>
        <table className="editor"><DDARows dda={ Object.assign(object, state) } mergeState={ mergeState } /></table>

        <div className="actions">
            { hasUnsaved && <button className="primary" onClick={onSave}>Save Changes</button> }
            <button className="destructive btn-size-jumbo">Remove Configuration</button>
        </div>
    </>
}


/**
 * The contents of the "MID Configuration" panel
 * 
 * @param {object} props 
 * @param {MIDConfig} props.mid
 */
export function MID(props) { 

    /** @type {[ [Shift4Token], React.Dispatch<[Shift4Token]> ]} */
    let [ tokens, setTokens ] = useState(null) 

    useEffect(() => { 
        ULinkService.getJSON(props.mid._links.s4_tokens)
            .then(json => setTokens(json.map(x => new Shift4Token(x))))
    }, [])


    let [ addTokenModal, setAddTokenModal ] = useState(null) 
    let addToken = () => setAddTokenModal(
        <Dialog title="Add Shift4 Access Token" close={ () => setAddTokenModal(null) }>

        </Dialog>
    )

    const $addToken = <button className="primary" onClick={addToken}>Add Token</button>

    var $tokens; 
    if(tokens === null) $tokens = <p>Loading...</p>
    else if(tokens.length == 0) $tokens = <>
        <p>
            There are no tokens yet for this MID. 
            It cannot process any transactions until a token is added.
        </p>
        {$addToken}
    </>
    else $tokens = <>
        <div id="shift4-access-tokens" role="list">
            { tokens.map(token => <div key={token.id} role="listitem">

                <table className="editor bordered">
                    <tbody>
                        <tr>
                            <td>Status</td>
                            <td>{ s4_token_statuses[token.status] }</td>
                        </tr>
                        <tr>
                            <td>Allow Mag Stripe?</td>
                            <td>{ token.mag_stripe ? "Yes" : "No" }</td>
                        </tr>
                        <tr>
                            <td>Allow manual entry?</td>
                            <td>{ token.manual_entry ? "Yes" : "No" }</td>
                        </tr>
                    </tbody>
                </table>
                <div className="actions">
                    <button>Deactivate</button>
                    <button className="destructive">Delete</button>
                </div>

            </div>) }       
        </div>
         
        <div className="actions" aria-controls="shift4-access-tokens">
            {$addToken}
        </div>
    </>
    
    return <>
        <p>(Actions not yet implemented)</p>

        <table className="editor">
            <tbody>
                <tr>
                    <td>Status</td>
                    <td>
                        <select value={ mid_statuses[props.mid.status] } readOnly>
                            { Object.keys(mid_statuses).map( key => <option key={key} value={key}>{ mid_statuses[key] }</option> )}
                        </select>
                    </td>
                </tr>
                <tr>
                    <td>Serial Number</td>
                    <td>{ props.mid.serial_number }</td>
                </tr>
            </tbody>
        </table>

        <h3>Shift4 Access Tokens</h3>
        { $tokens }    
    </>
}
