import { toaster } from "global-ui"
import { Link } from "parse-link-header"
import React, { useState, useEffect } from "react"

import { Iterator } from "services/ULink"
import { Icon } from "utils"

interface Identifiable {
    id?: number
    uri?: string
    key?: any
}

interface TableIterator<T extends Identifiable> {
    /** Data for actual display (regardless of the contents of `iterator`) */
    pageData?: T[]
    setPageData: React.Dispatch<React.SetStateAction<T[]>>

    /** Original iterator, which contains link and range information */
    iterator?: Iterator<T>
    navigate: (link: Link) => void

    loading: boolean
}

type FetchAction<T extends Identifiable> = () => Promise<Iterator<T>>

// This function used to be for a return type of `React.ReactNode`.
// I have no idea why; every instance of this type is just a normal arrow function to a useNavigate function,
// which returns a void, and no other files complained until we tried to do it for Resellers
//
// If you can figure out why that was the case, let Jake know leave a comment here :)
type RowClickEvent<T extends Identifiable> = (row: T, e: React.MouseEvent) => void

interface ColumnSpec<T extends Identifiable> {
    /** Column name as rendered in the header */
    name?: string

    /** Function returning the column content for the provided row */
    selector: (row: T) => React.ReactNode

    /** If `true`, render this column with `.shrink`, shrinking it to fit content */
    shrink?: boolean
}

/**
 * Common state and fetch handling for manual data tables.
 * The default `DataTable` export handles this automatically for all cases where you don't need to manipulate data.
 *
 * The return value provides `[ pageData, setPageData ]` state hooks for maniuplating the data on the current page.
 * However, this data is reset whenever the user changes pages.
 *
 * The `loading` state is also provided, which can be checked if other content outside the table depends on page loads.
 * Do not use `setLoading`; this is handled automatically.
 *
 * The rest of the props can be spread directly into `DataTableBody`;
 * see its docs for more information.
 *
 * @param initialFetch Function returning a promise to an iterator
 */
export function useTableIterator<T extends Identifiable>(
    initialFetch: FetchAction<T>
): TableIterator<T> {
    let [iterator, setIterator]: [Iterator<T>, React.Dispatch<React.SetStateAction<Iterator<T>>>] =
        useState(null)
    let [pageData, setPageData]: [T[], React.Dispatch<React.SetStateAction<T[]>>] = useState(null)
    let [loading, setLoading] = useState(true)

    const doFetch = (action: FetchAction<T>) => {
        setLoading(true)

        action()
            .then(iter => {
                setIterator(iter)
                setPageData(iter.items)
            })
            .catch(toaster.catchULink)
            .finally(() => setLoading(false))
    }

    const navigate = (link: Link) => {
        if (!iterator) {
            console.warn("useTableIterator: Cannot navigate; no iterator")
            return
        }

        doFetch(() => iterator.fetchLink(link.url))
    }

    useEffect(() => doFetch(initialFetch), [])

    return { pageData, setPageData, iterator, navigate, loading }
}

/**
 * Automatic DataTable component which wraps around `DataTableBody`
 *
 * Simply pass an iterator query to `firstFetch` and specify `columns`.
 */
export default function <T extends Identifiable>(props: {
    /** Function that performs the first page load, returning a promise to an iterator  */
    firstFetch: FetchAction<T>
    /** Array of objects specifying the content of each column */
    columns: ColumnSpec<T>[]

    onRowClicked?: RowClickEvent<T>

    loadingMessage: string

    emptyMessage: string
}) {
    let tableIterator = useTableIterator(props.firstFetch)

    return (
        <DataTableBody
            tableIterator={tableIterator}
            columns={props.columns}
            onRowClicked={props.onRowClicked}
            loadingMessage={props.loadingMessage}
            emptyMessage={props.emptyMessage}
        />
    )
}

/**
 * Manual data table component.
 *
 * Use the default automatic data table export wherever you don't need manual data handling.
 * For cases where you do, see `MerchantUsers.tsx` for a reference implementation,
 * which uses this component for handling deleted rows.
 *
 * Requires iterator setup with `useTableIterator`, which can be passed directly into the component like so:
 *
 * `let tableIterator = useTableIterator( `...` )`
 *
 * `<DataTableBody {... tableIterator} `...` />`
 */
export function DataTableBody<T extends Identifiable>(props: {
    tableIterator: TableIterator<T>
    columns: ColumnSpec<T>[]

    // Events

    onRowClicked?: RowClickEvent<T>

    loadingMessage: string

    emptyMessage: string
}) {
    const { iterator, pageData, navigate } = props.tableIterator
    const { first, last, prev, next } = iterator?.links ?? {}

    /**
     * @param {object} iconProps
     * @param {Link} iconProps.link
     * @param {string} iconProps.icon
     */
    function NavIcon(iconProps: { link: Link; icon: string }): React.ReactElement {
        const enabled = !!iconProps.link

        var onClick = null
        if (enabled) onClick = () => navigate(iconProps.link)

        return (
            <Icon
                name={iconProps.icon}
                aria-label={iconProps["aria-label"]}
                aria-disabled={!enabled}
                onClick={onClick}
            />
        )
    }

    //TODO: Loading component
    if (!(iterator && pageData)) {
        return (
            <>
                <h3 className="no-transactions">{props.loadingMessage}</h3>
            </>
        )
    } else if (!(iterator && pageData?.length)) {
        return (
            <>
                <h3 className="no-transactions">{props.emptyMessage}</h3>
            </>
        )
    }

    return (
        <div className="SionicDataTable">
            <table>
                <thead>
                    <tr>
                        {props.columns.map((col, i) => {
                            var classes = []
                            if (col.shrink) classes.push("shrink")
                            return (
                                <th key={i} className={classes.join(" ")}>
                                    {col.name || null}
                                </th>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {pageData.map(item => (
                        <tr
                            className="table-row"
                            key={item.id || item.uri}
                            onClick={props.onRowClicked && (e => props.onRowClicked(item, e))}
                            data-clickable={!!props.onRowClicked}
                        >
                            {props.columns.map((col, i) => {
                                var classes = []
                                if (col.shrink) classes.push("shrink")
                                return (
                                    <td key={i} className={classes.join(" ")}>
                                        {col.selector(item)}
                                    </td>
                                )
                            })}
                        </tr>
                    ))}
                </tbody>
            </table>

            <div role="navigation">
                {iterator?.rangeInfo && (
                    <span role="contentinfo">
                        Items {iterator.rangeInfo.start + 1} - {iterator.rangeInfo.end + 1}
                        {iterator?.totalItems && ` of ${iterator.totalItems}`}
                        {iterator?.totalPages && ` (${iterator.totalPages} pages)`}
                    </span>
                )}

                <NavIcon link={first} icon="first_page" aria-label="First page" />
                <NavIcon link={prev} icon="navigate_before" aria-label="Previous page" />
                <NavIcon link={next} icon="navigate_next" aria-label="Next page" />
                <NavIcon link={last} icon="last_page" aria-label="Last page" />
            </div>
        </div>
    )
}
