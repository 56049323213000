import toast from "react-hot-toast"
import ULinkService from "services/ULink"
import { helperText } from "widgets/InputValidator"

export const toaster = {
    // Messages
    loggedOut:      () => { toast("You have been logged out.") },
    isSubmitting:   () => { toast("Please wait a few moments.") },
    copiedToClipboard: (item: string) => { toast(`${item} copied to clipboard.`); },

    // Errors
    customError: (message: string) => { //Use extremely sparingly pls
        toast.error(message);
    },
    error: () => {
        console.error("Generic error caught by toaster")
        toast.error("An unexpected error has occured. Please refresh the page and try again.")
    },
    emailExists:            () => { toast.error("A user already exists with that e-mail.") },
    invalidEmail:           () => { toast.error("Please enter a valid email.") },
    invalidPassword:        () => { toast.error(helperText.password.default) },
    passwordsDoNotMatch:    () => { toast.error(helperText.password.doNotMatch) },
    invalidFields:          () => { toast.error("There are invalid or empty fields. Please correct them and try again.") },
    invalidCredentials:     () => { toast.error("Your email or password was incorrect.") },
    expiredLink:            () => { toast.error("The link you were sent has expired or was invalid; please try again.") },
    catchULink: (err: any) => {
        console.error("ULink error caught by toaster:", err)
        toast.error(ULinkService.errorMsg(err))
    },

    // Sucesses
    emailSent:  () => { toast.success("Success! Check your email inbox for a reset link!") },
    saved:      () => { toast.success("Changes have been saved!") },
    created: (name: string) => { toast.success(`Successfully created ${name}.`) },
    deleted: (name: string) => { toast.success(`Successfully deleted ${name}.`) },
    updated: (name: string) => { toast.success(`${name} succesfully updated.`) }
}

export const customTableStyles = {
    //for react data table component
    rows: {
        style: {
            background: "#39485F",
            color: "white",
            cursor: "pointer",
        },
    },
    headCells: {
        style: {
            background: "#39485F",
            color: "white",
        },
    },
    cells: {
        style: {
            background: "#39485F",
            color: "white",
        },
    },
}

/**
 * Formats a Date object into a human string
 * @param {Date} date
 * @returns {string}
 */
export function timestamp(date) {
    if (date == null) return "(Never)"
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
}

/**
 * Creates a human-readable string describing the time interval from now to the given date
 * @param {Date} date
 * @returns {string}
 */
export function timeIntervalFromNow(date) {
    let currentDate: any = new Date();
    let seconds = (date - currentDate) / 1000
    let remain = seconds % 60
    let minutes = seconds / 60

    if (minutes > 2) return `${Math.round(minutes)} minutes`
    else if (minutes > 1) return `1 minute and ${remain} seconds`
    else return `${seconds} seconds`
}

/**
 * Formats a phone number for display
 * @param {string} str Raw phone number from API
 * @returns {string|null} Phone number, formatted if possible, or `null` if it is empty
 */
export function phoneNumber(str) {
    if (!str) return null

    //Filter only numbers from the input
    let cleaned = String(str).replace(/\D/g, "")

    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) return "(" + match[1] + ") " + match[2] + "-" + match[3]
    else return str
}

/**
 * Formats a dollar amount for display
 * @param {Big|null} big
 * @returns {string|null} `null` if `big` is null
 */
export function currency(big) {
    if (big === null) return null
    return "$" + big.toFixed(2)
}
