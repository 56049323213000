import React, {useState} from 'react'; 

import Input from '../widgets/InputLabelled.jsx'; 

export default function(props) { 

    let mrm = props.mrm; 

    let [ field, setField ] = useState(''); 

    return <div id="LocationSettings">

        <p id="mrm-settings-explainer">
            Currently, the below settings may only be edited on the <a href={`/merchant-center/${MRM_LOCATION['merchant-id']}/locations/${MRM_LOCATION['location-id']}`} target="_blank">Edit Location page</a>.
        </p>

        <section id="mrm-settings">
            
            <Input display="Display Name" value={ mrm.name } />
            <Input display="Address" value={ mrm.address } />
            <Input display="City" value={ mrm.city } />
            <Input display="State" value={ mrm.state } />
            <Input display="Phone Number" value={ mrm.phone } />
        </section>
    </div> 
}
