import React, { useState, useEffect } from "react"
import Reseller from "data/Reseller"

import { GlobalState } from "GlobalState"
import ULinkService from "services/ULink"
import { toaster } from "global-ui"
import { useNavigate } from "react-router-dom"


// MUI Components
import { Button, Switch, TextField, FormControlLabel } from "@mui/material"
import Big from "big.js"
import FormValidator from '../../../widgets/FormValidator';
import TextInput from '../../../widgets/TextInput';

interface Props {
    state: Reseller
    mergeState: (state: any) => void
    resetState: (state: Reseller) => void
    isNew: boolean
    isInvitation: boolean
    resellerFormValid: boolean
    setResellerFormValid: (bool: boolean) => void
}

export const ResellerInfo: React.FC<Props> = ({
    mergeState,
    state,
    resetState,
    isNew,
    isInvitation,
    resellerFormValid,
    setResellerFormValid
}) => {
    const navigate = useNavigate()

    const posOptions: Array<string> = [
        "Clover",
        "Toast",
        "Square",
        "Revel",
        "Micros",
        "Lightspeed",
        "Other",
    ]

    const [isSubmitting, setIsSubmitting] = useState(false)
    const { context } = GlobalState.useContainer()

    const onSave = () => {
        if (!resellerFormValid || isSubmitting) return
        setIsSubmitting(true)

        if (isNew) {
            ULinkService.resellers
                .create(state)
                .then(newReseller => {
                    navigate("/reseller?uri=" + newReseller.uri)
                })
                .finally(() => {
                    setIsSubmitting(false)
                })
        } else {
            if (parentResellerStatus !== savedParentStatus) {
                const rate = new Big(rev_share_rate).div(100)
                console.log(rate)
                ULinkService.resellers
                    .createResellerGroup({
                        group_name: group_name,
                        parent_reseller_id: state.id,
                        rev_share_rate: rate,
                    })
                    .then(res => console.log(res))
            }
            ULinkService.updateObject(state)
                .then(updated => {
                    resetState(updated)
                    toaster.saved()
                })
                .finally(() => {
                    setIsSubmitting(false)
                    window.location.reload();
                })
                .catch(toaster.catchULink)
        }
    }

    const onDelete = () => {
        const confirm = window.confirm(
            "Are you sure? This action cannot be undone without contacting support."
        )
        if (!confirm) return

        state.status = 2
        ULinkService.updateObject(state)
            .then(() => navigate("/resellers"))
            .catch(toaster.catchULink)
    }

    const posSelect = posOptions.map((option, i) => {
        return (
            <div key={i}>
                <input
                    type="checkbox"
                    id="0"
                    checked={true}
                    // checked={obj?.pos?.indexOf(option) !== -1}
                    // onChange={e => {
                    //     if (e.target.checked) mergeState({ pos: [...obj.pos, option] })
                    //     else mergeState({ pos: obj?.pos?.filter(x => x != option) })
                    // }}
                />
                <label htmlFor="0">{option}</label>
                <br />
            </div>
        )
    })

    const [parentResellerStatus, setParentReseller] = useState(state.parent_reseller)
    const [savedParentStatus, setSavedParentStatus] = useState(state.parent_reseller)
    const [group_name, set_group_name] = useState("")
    const [rev_share_rate, set_rev_share_rate] = useState("")

    const formatInput = () => {
        set_rev_share_rate(Number(rev_share_rate).toFixed(3))
        if (Number(rev_share_rate) > 100) set_rev_share_rate("100")
        else if (Number(rev_share_rate) < 0) set_rev_share_rate("0")
    }

    const fetchResellerGroup = () => {
        if (state.parent_reseller) {
            ULinkService.resellers
                .getResellerGroup(state.id)
                .then(res => {
                    set_rev_share_rate(new Big(res?.rev_share_rate ?? 0).mul(100).toString())
                    set_group_name(res?.group_name)
                })
                .catch(console.error)
        }
        // ULinkService.getJSON(uri ?? )
        // .then(res => {
        //     set_rev_share_rate(new Big(res?.rev_share_rate ?? 0).mul(100).toString())
        // })
        // .catch(console.error)
    }

    const updateRevShare = () => {
        const rate = new Big(rev_share_rate).div(100)
        // ULinkService.updateObject({ uri, rev_share_rate: rate }).then(res => {
        //     res
        //         ? toast.success("Revenue Share Rate Updated.")
        //         : toast.error("Something went wrong. Revenue Share Rate Was Not Updated.")
        // })
    }

    useEffect(() => {
        fetchResellerGroup()
    }, [state.parent_reseller])

    return (
        <>
            <h1>Company Info</h1>
            <div
                id="ResellerInfoForm"
                style={{ display: "flex", flexDirection: "column", width: "60%" }}
            >
                <FormValidator fieldsValid={resellerFormValid} setFieldsValid={setResellerFormValid}>
                    <TextInput
                        type="generic"
                        label="Business Name"
                        value={state.name}
                        required={true}
                        margin="normal"
                        onChange={e => {
                            mergeState({ name: e.target.value })
                        }}
                    />

                    <TextInput
                        type="generic"
                        label="Address Line 1"
                        value={state.address_1 ?? ""}
                        required={true}
                        margin="normal"
                        onChange={e => mergeState({ address_1: e.target.value })}
                    />

                    <TextInput
                        id="outlined"
                        label="Address Line 2"
                        value={state.address_2 ?? ""}
                        margin="normal"
                        onChange={e => mergeState({ address_2: e.target.value })}
                    />

                    <TextInput
                        type="generic"
                        label="City"
                        value={state.city ?? ""}
                        required={true}
                        margin="normal"
                        onChange={e => mergeState({ city: e.target.value })}
                    />

                    <TextInput
                        type="addressRegion"
                        label="State"
                        value={state.region ?? ""}
                        required={true}
                        margin="normal"
                        onChange={e => mergeState({ region: e.target.value })}
                    />

                    <TextInput
                        type="addressZip"
                        label="Zip Code"
                        value={state.postal_code ?? ""}
                        required={true}
                        margin="normal"
                        onChange={e => mergeState({ postal_code: e.target.value })}
                    />
                </FormValidator>

                {!isInvitation && context.type === "sionic" && (
                    <FormControlLabel
                        control={<Switch name="parent-reseller" />}
                        checked={parentResellerStatus}
                        onChange={() => setParentReseller(!parentResellerStatus)}
                        disabled={savedParentStatus}
                        label="Reseller Group Owner"
                    />
                )}
                {!isInvitation && context.type === "sionic" && parentResellerStatus && (
                    <>
                        <TextField
                            type="text"
                            id="outlined-required"
                            label="Group Name"
                            value={group_name}
                            required={true}
                            margin="normal"
                            onChange={e => set_group_name(e.target.value)}
                        />
                        <TextField
                            type="number"
                            id="outlined-required"
                            label="Revenue Share Rate %"
                            value={rev_share_rate}
                            required={true}
                            margin="normal"
                            onChange={e => set_rev_share_rate(e.target.value)}
                            onBlur={formatInput}
                        />
                    </>
                )}

                {/* Buttons */}
                {!isInvitation && (
                    <div className="right-aligned-buttons">
                        <Button
                            sx={{ marginRight: "1rem" }}
                            color="secondary"
                            variant="outlined"
                            onClick={onSave}
                        >
                            {isNew ? "Create" : "Save"}
                        </Button>
                        {!isNew && context.type === "sionic" && (
                            <Button variant="outlined" color="error" onClick={onDelete}>
                                Delete
                            </Button>
                        )}
                        {isNew && (
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => navigate(-1)}
                            >
                                Cancel
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}
