import React, { useState } from "react";
import { uniqid } from "../utils";


export function Checkbox(props) { 
    const key = useState(uniqid())[0]; 
    let id = 'Checkbox-' + key

    return <div className="Checkbox" data-name={props.name}>
        <input type="checkbox" id={id} />
        <label htmlFor={id}>{ props.children }</label>
    </div>

}