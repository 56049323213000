import Admin from "./Admin";
import { SessionContext } from "./Admin";

export default class GlobalUser {
    uri: string
    user: Admin
    contexts: Array<GlobalUserContext>

    constructor(obj: any){
        this.uri = obj.uri;
        this.user = new Admin(obj.user);
        this.contexts = obj.contexts.map(ctx => new GlobalUserContext(ctx))
    }
}

export class GlobalUserContext {
    uri: string
    context_type: "sionic" | "reseller" | "merchant"
    business_name: string
    role_value: number

    constructor(obj: any){
        this.uri = obj.uri
        this.context_type = obj.sionic ? 'sionic' : obj.type
        this.business_name = obj.business
        this.role_value = obj.role
    }
}