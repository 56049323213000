import React, { useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"

import Merchant from "data/Merchant"
import ULinkService from "services/ULink"

import * as GlobalUI from "global-ui"
import DataTable from "widgets/DataTable"

import { MerchantInfo } from "./MerchantInfo"

import MerchantUsers from "./MerchantUsers"
import { GlobalState } from "GlobalState"

// MUI
import { Container, Paper, Box, Tab, Tabs, Typography, Button } from "@mui/material"

interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ marginTop: 2 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

export const ViewMerchant = () => {
    const query = useSearchParams()[0]
    const navigate = useNavigate()

    const [showAddLocation, setShowAddLocation] = useState(false)

    // Form state

    const [, setName] = useState("")
    const [, setStatus] = useState(0)
    const [hasUnsaved, setHasUnsaved] = useState(false)
    //We can use this to prevent submissions if form inputs invalid (currently not used here)
    const [ merchantInfoFormValid, setMerchantInfoFormValid ]   = useState(false);

    // Object state

    /** @type {[ ?Merchant, function]} */
    const [merchant, _set_merchant] = useState<Merchant>(null)

    /**
     * Updates the merchant from a fetch, loading form state in the process
     * @param {Merchant} merchant
     */
    const setMerchant = merchant => {
        _set_merchant(merchant)

        setName(merchant.name)
        setStatus(merchant.status)
    }

    const { scrollToTop } = GlobalState.useContainer()

    useEffect(() => {
        scrollToTop()
    }, [])

    //
    // Data fetching
    //

    useEffect(() => {
        // Initial merchant fetch
        ULinkService.merchants
            .get(query.get("uri"))
            .then(setMerchant)
            .then(() => {
                setState(merchant)
            })
            .catch(err => {
                console.log(err)
                //@ts-ignore
                ULinkService.notifyError()
            })
    }, [])

    //Prevent Close Tab on Unsaved Changes
    //TODO: make this work
    // if(hasUnsaved) {
    //     window.addEventListener('beforeunload', function (e) {
    //         e.preventDefault();
    //         e.returnValue = '';
    //     });
    // }

    //
    // Rendering
    //
    const [state, setState] = useState<Merchant>()
    const mergeState = update => {
        setHasUnsaved(true)
        setState(old => Object.assign({}, old, update))
    }

    // Used in MUI Tabs
    const [value, setValue] = React.useState(0)

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    return (
        <>
            {merchant && (
                <Container>
                    {/* This is the old section banner - it's fine for now since we're in a rush (what's new. fml) */}
                    <section role="banner">
                        <h1 id="merchant-title">{merchant.name}</h1>

                        <address>
                            {merchant.address_1}
                            <br />
                            {merchant.address_2 && (
                                <>
                                    {merchant.address_2} <br />
                                </>
                            )}
                            {merchant.city} {merchant.region} {merchant.zip}
                            {hasUnsaved ? (
                                <div className="notify-unsaved">
                                    <span>You have unsaved changes.</span>
                                </div>
                            ) : null}
                        </address>
                    </section>

                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Business Info" {...a11yProps(0)} />
                            <Tab label="Users" {...a11yProps(1)} />
                            <Tab label="Locations" {...a11yProps(2)} />
                            <Tab label="Transactions" {...a11yProps(3)} />
                        </Tabs>
                    </Box>

                    <Box>
                        {/* Business Info Tab */}
                        <TabPanel value={value} index={0}>
                            <Paper
                                sx={{ padding: "2rem" }}
                                children={
                                    <MerchantInfo
                                        merchantProp={merchant}
                                        setHasUnsaved={setHasUnsaved}
                                        mergeState={mergeState}
                                        state={state}
                                        setState={setState}
                                        merchantInfoFormValid={merchantInfoFormValid}
                                        setMerchantInfoFormValid={setMerchantInfoFormValid}
                                    />
                                }
                            />
                        </TabPanel>

                        {/* Users Tab */}
                        <TabPanel value={value} index={1}>
                            <Paper
                                sx={{ padding: "2rem" }}
                                elevation={3}
                                children={<MerchantUsers merchant={merchant} />}
                            />
                        </TabPanel>

                        {/* Locations Tab */}
                        <TabPanel value={value} index={2}>
                            <Paper
                                sx={{ padding: "2rem" }}
                                elevation={3}
                                children={
                                    <>
                                        <h2>Locations</h2>
                                        <DataTable
                                            firstFetch={() =>
                                                //@ts-ignore what the heck is happening here kms
                                                ULinkService.locations.getIterator( merchant._links.locations )
                                            }
                                            //@ts-ignore
                                            columns={[{ name: "Name", selector: row => row.name }]}
                                            onRowClicked={(row, e) =>
                                                navigate("/location?uri=" + row.uri)
                                            }
                                            loadingMessage="Loading Locations..."
                                            emptyMessage="No Locations"
                                        />

                                        {showAddLocation && (
                                            <Button
                                                onClick={() =>
                                                    navigate("/locations/add?uri=" + merchant.uri)
                                                }
                                            >
                                                Add Location
                                            </Button>
                                        )}
                                    </>
                                }
                            />
                        </TabPanel>

                        {/* Transactions Tab */}
                        <TabPanel value={value} index={3}>
                            <Paper
                                sx={{ padding: "2rem" }}
                                elevation={3}
                                children={
                                    <>
                                        <h2>Transactions</h2>
                                        {/* WARNING WARNING WARNING: DATATABLE BELOW HAS STUB DATA THAT NEEDS TO BE REPLACED */}
                                        <DataTable
                                            firstFetch={() =>
                                                ULinkService.merchants.merchantTransactions(
                                                    merchant
                                                )
                                            }
                                            columns={[
                                                {
                                                    name: "Date & Time",
                                                    selector: row =>
                                                        GlobalUI.timestamp(row.created_at),
                                                },
                                                {
                                                    name: "Location",
                                                    selector: row => row.location.name,
                                                },
                                                {
                                                    name: "Total",
                                                    selector: row => GlobalUI.currency(row.total),
                                                },
                                                {
                                                    name: "Payment Method",
                                                    selector: row => "BANK 5382",
                                                },
                                                {
                                                    name: "Status",
                                                    selector: row => "Settled at 11/27/21",
                                                },
                                            ]}
                                            onRowClicked={(row, e) =>
                                                navigate("/transactions/" + row.id)
                                            }
                                            loadingMessage={"Loading Transactions..."}
                                            emptyMessage={"No Transactions"}
                                        />
                                    </>
                                }
                            />
                        </TabPanel>
                    </Box>
                </Container>
            )}
        </>
    )
}
