import Big from "big.js";

/**
 * Rounding for each transaction calculation step; up to the cent. 
 * @param {Big} big 
 * @returns {Big}
 */
 export function currency(big) {
    return big.round(2, Big.roundUp)
}

export const CharacterLimit = 80; 