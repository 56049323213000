import React from "react";
import { BallTriangle } from 'react-loader-spinner';

export default function(props) {
    return (
        <div id="Loader">
            <div className="loader-cont-wrapper">
                <h2>{props.title}...</h2>
                <BallTriangle
                    height="100"
                    width="100"
                    color='white'
                    ariaLabel='loading'
                />
                <span onClick={() => window.location.reload()}>click here if taking too long...</span>
            </div>
        </div>
    );
}