export default class Upload {
    key: string
    content_type: string
    timestamp: string

    constructor(obj?: any) {
        if (!obj)
            obj = {
                key: "",
                content_type: "",
                timestamp: ""
            }

        this.key = obj.key

        this.content_type = obj.content_type

        this.timestamp = obj.timestamp
    }
}
