import React from "react";
import { Icon } from "utils";
import { extractProps } from "views/pages/locations/smb-menu/utils";


/**
 * Generic button component with optional icons
 * @param {object} propsIn 
 */
export function Button(propsIn) { 
    let params = { 
        /** @type {?React.Component} */
        children: null,

        /** @type {?string} */
        icon: null, 
    }
    let propsOut = extractProps(propsIn, params) 

    //
    // Icon(s) 
    //

    /** @type {?React.Component} */
    var icon = null 
    if(params.icon) { 
        icon = <Icon role="none" name={ params.icon } />
    }

    // Inner content

    /** @type {?React.Component} */
    var content = params.children ? <span>{ params.children }</span> : null 

    //
    // Behavior 
    //


    return <button {... propsOut}>{ icon }{ content }</button>
}