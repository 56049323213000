import { MIDCybs } from "data/Location";
import React, { useState, useEffect, ReactComponentElement } from "react";
import { toaster } from "global-ui";
import ULinkService from "services/ULink";

export default function MidCybsConfig (props: { 
    uri: string,
    location: object

}) : React.ReactNode {

    let [ loadingMidCybs, setLoadingMidCybs ] = useState(true)
    let [ midCybs, setMidCybs ] : [ MIDCybs, React.Dispatch<React.SetStateAction<MIDCybs>> ]= useState(null);

    let [newMid, setMid] : [string, React.Dispatch<React.SetStateAction<string>>] = useState('')
    let [activeStatus, setActiveStatus] = useState(false)
    let [reloadNewMid, setReload] = useState(false)


    useEffect(() => { 


        ULinkService.configs.getMidCybs(props.uri)
            .then(mid => setMidCybs(mid))
            .catch(err => { 
                if(err == 404) console.debug("Location has no override cybs mid") 
                else { 
                    console.error("Fetching override cybs mid", err)
                }
            })
            .finally(() => setLoadingMidCybs(false))         
    },[reloadNewMid]);


   function onSave(){
        ULinkService.put(props.uri, { merchant_ident: newMid , active: activeStatus })
        .then(res =>{
            if(res.status == 200){
                toaster.saved()
                setMid('')
                setActiveStatus(false)
                setReload(!reloadNewMid)
            }else if(res.status > 201){
                toaster.error()
            }
            
        })
        .catch(toaster.catchULink)
    }

    function onDelete(){
        ULinkService.delete(props.uri)
        .then(res =>{
            setMidCybs(null)
        })
        .catch(toaster.catchULink)
    }

    function handleActivation(){
        ULinkService.update({...midCybs, active: !midCybs.active}).then(()=> setReload(!reloadNewMid))
            .catch(toaster.catchULink)
    }

    let midForm : React.ReactNode = <>
        <table id='basic-edit' className="editor">
               <tbody>
                    <tr>
                        <td>Merchant Identity</td>
                        <td>
                            <input type='text' id='merchant_ident' value={newMid} onChange ={(e)=>setMid(e.target.value)}></input>
                        </td>
                    </tr>
                    <tr>
                        <td>Active</td>
                        <td>
                            <input type='checkbox' id='active' onChange ={() => setActiveStatus(!activeStatus)}></input>
                        </td>
                    </tr>
               </tbody>
        </table>
        <div className="actions">
            <button className="primary btn-size-jumbo" onClick={ onSave }>Create</button>
        </div>
    </>

    let noCybConfig : React.ReactNode = <>
        <p>No Config</p>
    </>

    let existingMid : React.ReactNode = <></>

    if(midCybs){
        existingMid = <>
            <table id='basic-edit' className="editor">
                <tbody>
                    <tr>
                        <td>Merchant Identity</td>
                        <td>
                            <p>{midCybs.merchant_ident}</p>
                        </td>
                    </tr>
                    <tr>
                        <td>Active</td>
                        <td>
                            <input type='checkbox' id='active' checked={midCybs.active ? true: false} onChange ={ handleActivation }></input>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="actions">
                <button className="destructive btn-size-jumbo" onClick={ onDelete }>Delete</button>
            </div>  
        </>
    }
        
    
    return <>
        {loadingMidCybs && <p>Searching For Config...</p>}
        {!loadingMidCybs && !midCybs && noCybConfig}
        {!loadingMidCybs && !midCybs && midForm}
        {!loadingMidCybs && midCybs && existingMid}
    </>
}