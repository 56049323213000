import React, { useState, useEffect } from "react";
import * as GlobalUI from 'global-ui'; 
import { admin_statuses, entity_location_statuses } from 'globals';
import { useNavigate } from 'react-router-dom';
import ULinkService from "services/ULink";
import DataTable from "widgets/DataTable";
import { GlobalState } from "GlobalState"



/** @typedef {import("services/ULink").Invite} Invite */


/**
 * 
 * @param {object} props 
 * @param {Invite} props.invite Invite object
 * @param {function} props.back Back to the List View 
 */
function InviteView(props) { 
    let href = `${location.protocol}//${location.host}/accept-invite?code=${props.invite.code}&expires=${props.invite.expires.getTime()}`


    return <div id="InviteView">
        <h1>Invite an Admin</h1> 

        <div className="">
            <p>An invitation has been created with the below address.  Send the link to the recipient of the invitation.</p>
            <p>This invitation expires in { GlobalUI.timeIntervalFromNow(props.invite.expires) }</p>
            <a href={href}>{ href }</a>
            <button className="primary" onClick={ () => {  navigator.clipboard.writeText(href) } }>Copy to clipboard</button>
        </div>



        <a onClick={ props.back }>Back to Admins list</a>
    </div>
}





export default function() { 
    const navigate = useNavigate();
    const { scrollToTop } = GlobalState.useContainer()


    /**
     * Created invite - shows Invite View when nonnull
     * @type {[ ?Invite, function(?Invite) ]}
     */
    let [ invite, setInvite ] = useState(null) 

    useEffect(() => {
        scrollToTop();
    },[])



    if(invite) { 
        return <InviteView invite={ invite } back={ () => setInvite(null) } />
    } else { 

        const onCreateInvite = () => { 
            ULinkService.admins.inviteCreate().then( x => setInvite(x) )
        }

        return (
            <>
                <div id="ListAdmins">
                    <h1>Sionic Admins</h1>
                    
                    <DataTable
                        firstFetch={ ULinkService.admins.all }
                        columns={[ 
                            { name: 'Name',   selector: item => item.name },
                            { name: 'Email',  selector: item => item.email },
                            { name: 'Status', selector: item => admin_statuses[item.status] },
                        ]}

                        onRowClicked={(row, e) => navigate("" + row.id)}
                        loadingMessage = {'Loading Sionic Admins...'}
                        emptyMessage = {'No Admins'}
                    />

                    <button className="primary invite-btn btn-size-lg" onClick={ onCreateInvite }>Invite an Admin</button>
                </div>
            </>
        )
    }
}