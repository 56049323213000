import React from 'react';
import GlobalInput from '../widgets/GlobalInput.jsx';

export default function(props) {
    let kernel = props.kernel;
    let modId = props.modid;
    let thisMod = kernel.mods.filter((mod) => mod.id === modId)[0];

    let ModOptionCards = (props) => {
        let option = props.option;
        return (
            <React.Fragment>
                <div className="op-card">
                    <div className="inputs-wrapper">
                        <GlobalInput label="Name" placeholder={option.name} displaybuttons="none"/>
                        <GlobalInput label="Price" placeholder="- " displaybuttons="none"/>
                    </div>
                    <div className="align-box-wrapper">
                        <input id="Available-Checkbox" type="checkbox" />
                        <label htmlFor="Available-Checkbox">Available</label>
                    </div>
                    <div className="delete-btn">
                        <img src="/merchant-center/images/delete.png" alt="delete" />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    return (
        <div id="Modal" className="modal-main">
            <div className="modal-card edit-mods">
                {/* Heading Section */}
                <div className="mods-modal-header">
                    <h2>Editing Item Choice</h2>
                    <img onClick={ props.closemodal } src="/merchant-center/images/window-close.svg" alt="close modal" />
                </div>
                <div className="edit-modal-top">
                    {/* Mod Name Inputs */}
                    <div className="edit-modal-top-left">
                        <GlobalInput label="Name" placeholder={thisMod.name} displaybuttons="none"/>
                        <GlobalInput label="Display Name" displaybuttons="none"/>
                    </div>
                    <div className="edit-modal-top-right">
                        <p>How many options will the customer select?</p>
                        <div className="options-settings-wrapper">
                            {/* Checkboxes */}
                            <div className="options-checkboxes">
                                <div className="align-box-wrapper">
                                    <input id="Required-Checkbox" type="checkbox" />
                                    <label htmlFor="Required-Checkbox">Required</label>
                                </div>
                                <div className="align-box-wrapper">
                                    <input id="Limit-Checkbox" type="checkbox" />
                                    <label htmlFor="Limit-Checkbox">Limited to one</label>
                                </div>
                            </div> 
                            {/* Settings / Info Buttons */}
                            <div className="settings">
                                <img src="/merchant-center/images/settings-gear.svg" alt="settings" />
                                <img src="/merchant-center/images/info-circle.svg" alt="info" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* Options Section */}
                <div className="edit-modal-bottom">
                    <h3>Options</h3>
                    <div className="options-cards">
                        {
                            thisMod.items.map((option) => <ModOptionCards option={option} />)
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}