import { entity_location_statuses } from "globals"

export default class Reseller {
    id: string
    status: number
    name: string
    address_1: string
    address_2: string
    city: string
    region: string
    postal_code: string
    pos: Array<string>
    ein: string
    uri: string
    midDda: string
    reseller_group_id: string
    parent_reseller: boolean

    constructor(obj?: any) {
        if (!obj)
            obj = {
                status: Object.keys(entity_location_statuses)[0],
                id: null,
                name: "",
                address_1: "",
                address_2: "",
                city: "",
                region: "",
                postal_code: "",
                pos: [],
                ein: "",
                uri: "",
                midDda: "",
                reseller_group_id: null,
                parent_reseller: false,
            }

        this.id = obj.id

        this.status = obj.status

        this.name = obj.name

        this.address_1 = obj.address_1

        this.address_2 = obj.address_2

        this.city = obj.city

        this.region = obj.region

        this.postal_code = obj.postal_code

        this.pos = obj.pos

        this.ein = obj.ein

        this.uri = obj.uri

        this.midDda = obj.midDda

        this.reseller_group_id = obj.reseller_group_id

        this.parent_reseller = obj.parent_reseller
    }
}
