import React, { useState } from 'react';
import Preview  from './editor/Preview.jsx';
import Outline  from './editor/Outline.jsx';
import Editor   from './editor/Editor.jsx';

import * as MenuData from 'lib/lib-smb-menus/data/Menu'

/**
 * 
 * @param {object} props 
 * @param {string} props.currentMenuID `id` of the top-level `Menu` edited with this view 
 * 
 * @param {MenuData.default} props.menuData 
 * @param {EditorState} props.editorState
 * @param {React.Dispatch<EditorState>} props.setEditorState 
 * @returns 
 */
export default function(props){
    const section = props.menuData.getSection(props.currentMenuID)
    const childItems = props.menuData.menuItems(props.currentMenuID) 
    const childSections = props.menuData.menuSections(props.currentMenuID)

    // `headless` prevents the top-level menu from rendering in these child components - 
    //      we don't want it since the top-level menu is represented by the tab bar

    const childProps = { section, childItems, childSections, 
        headless: true, 

        menuData: props.menuData, editorState: props.editorState, setEditorState: props.setEditorState
    }

    return (
        <div id="MenuView">
            <Outline    {... childProps } />
            <Editor     {... childProps } />
            {/* <Preview /> */}
        </div>
    );
}