import React, { useEffect, useState } from 'react'; 

import TabPanel, { TabState } from './content/TabPanel';
import MenuView from './content/MenuView.jsx';
import SettingsView from './content/SettingsView.jsx'; 
import { useSearchParams } from 'react-router-dom';
import ULinkService from '/services/ULink';
import { toaster } from '/global-ui';

import * as MenuData from 'lib/lib-smb-menus/data/Menu'
import EditorState from './EditorState';



/**
 * Root level component for the Ordering subsystem
 * Consists of the tab bar and its associated content views, which contain everything else
 */
export default function(props) {
    const query = useSearchParams()[0]


    // 
    // Tab controller implementation 
    // 

    let [ currentTab, setTab ] = useState( TabState.ON_MENU ); // Should start null bc don't know if has menu or not 
    // This is an old comment; do we still want to do this?         👆

    /** @type {[ number, function(number) ]} */
    let [ currentMenuID, setMenuID ] = useState(null); // Current menu 

    let [ editorState, setEditorState ] = useState(new EditorState())

    const tabController = { 
        tab: currentTab, 

        setMenuID: (menu) => { 
            setMenuID(menu); 
            setTab( TabState.ON_MENU ); 
        },

        setTab: (tab) => { 
            if(tab == TabState.ON_MENU) throw 'Use setMenuID'; 
            setTab(tab); 
        }
    }

    // 
    // Data fetch 
    // 

    /** @type {[ MenuData.default, function(MenuData.default) ]} */
    let [ menuData, setMenuData ] = useState(null) 
    useEffect(() => { 

        if(menuData) return

        if(query.has('uri')) { 

            ULinkService.smb_menus.get(query.get('uri'))
                .then( menuData => { 
                    setMenuData(menuData) 
                    window.menuData = menuData

                    const rootMenus = menuData.menus() 
                    if(rootMenus.length > 0) setMenuID(rootMenus[0].id)
                }) 
                .catch( toaster.catchULink )

        } else { 
            console.error("No URI")
        }

    }, [ menuData ])


    //
    // Render
    // 

    if(! menuData) return null 
    if(currentMenuID === null) return null 

    const Content = () => { 
        switch(currentTab) { 
            case TabState.ON_MENU:      return <MenuView       
                menuData={ menuData } currentMenuID={ currentMenuID }  
                editorState={ editorState } setEditorState={ setEditorState } />

            case TabState.SETTINGS:     return null //TODO: 
        }
    }

    return <div id="ORDERING_ROOT" data-tab={ TabState.toString( currentTab ) } >
        <TabPanel tabController={tabController} currentMenuID={currentMenuID} menuData={menuData}>
            {Content()}
        </TabPanel>
    </div>
}