import React, { useState, useEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { GlobalState } from "GlobalState"

import { MerchantInfo } from "./MerchantInfo"

export const AddMerchant = () => {
    const { scrollToTop } = GlobalState.useContainer()
    const collectionURI = useSearchParams()[0].get("collection-uri")
    const [, setHasUnsaved] = useState(false)
    const mergeState = update => {
        setHasUnsaved(true)
        setState(old => Object.assign({}, old, update))
    }
    const [state, setState] = useState()
    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <main id="AddMerchant">
            <section role="banner">
                <h1>Create a new business</h1>
            </section>

            <div className="paneled-grid">
                <section id="basic">
                    <h2>Business Info</h2>
                    <hr />
                    <MerchantInfo
                        isNewProp={true}
                        collectionURI={collectionURI}
                        setHasUnsaved={setHasUnsaved}
                        state={state}
                        setState={setState}
                        mergeState={mergeState}
                    />
                </section>
            </div>
        </main>
    )
}
