import { SessionContext } from "data/Admin"
import { useLocation, useNavigate } from "react-router-dom"
import { toaster } from "global-ui"
import { useEffect } from "react"
import React from "react"
import NavDrawer from "widgets/NavDrawer"
import { GlobalState } from "GlobalState"

export const Frame = (props: { children?: React.ReactNode }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const { context, setContext, session, setSession, mainRef, setIsLoading } = GlobalState.useContainer()
    

    function logOutUser() {
        fetch("/log-out", { method: "POST" })
            .then(() => {
                setSession(null)
                setContext(null)
                setIsLoading(false)
                navigate("/")
            })
            .catch(toaster.catchULink)
    }

    useEffect(() => {
        if (context) {
            if (location.pathname == "/") {
                navigate(context.links[0].getHref())
            }
        }
    }, [context])

    useEffect(() => {
        if (session && !context && session.contexts.length == 1) {
            setContext(session.contexts[0])
        }
    }, [session])

    if (!session) {
        console.warn("Main rendered without a session")
        return null
    }

    let $Nav = null
    let $Content = null

    if (context) {
        $Content = props.children
        $Nav = <NavDrawer />
    } else {
        const onSelectContext = (ctx: SessionContext) => setContext(ctx)

        $Content = (
            <>
                <h1>Please select a role before continuing</h1>

                {session.contexts.map((context, i) => {
                    return (
                        <div className="btn-container" key={i}>
                            <button
                                id={context.business + "-" + context.role}
                                className="btn-size-lg primary"
                                onClick={() => onSelectContext(context)}
                            >
                                {`${context.business}-${context.role}`}
                            </button>
                        </div>
                    )
                })}
            </>
        )
    }

    return (
        <div id="PortalMain">
            {$Nav}

            {/* Main Content - Right side of Nav Bar */}
            <div id="MainContent" ref={mainRef} role="tabpanel" style={{ paddingTop: "6rem" }}>
                {/* <Toaster position={"top-center"} containerStyle={{ top: 72, }} /> */}
                {$Content}
            </div>
        </div>
    )
}
