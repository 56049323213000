import React from "react";
import { toaster } from "global-ui";

// MUI
import {
    Button,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Box,
} from '@mui/material';

export default function(props: {
    handleFinish: () => void,
    steps: any[]
}) {

    /* MUI "Stepper" -- Form Steps */
    const [activeStep, setActiveStep]   = React.useState(0);
    const [skipped, setSkipped]         = React.useState(new Set<number>());
    const steps                         = props.steps;

    const isStepOptional = (step: number) => {
        // We can put some logic here to add optional sections :)
        return false; // Currently it's set to always be false
    };
    
    const isStepSkipped = (step: number) => {
        return skipped.has(step);
    };
    
    const handleNext = () => {
        const isValid = props.steps[activeStep].isValid
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
    
        if(isValid) setActiveStep((prevActiveStep) => prevActiveStep + 1);
        else toaster.invalidFields();
        setSkipped(newSkipped);
    };
    
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    
    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }
    
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
          const newSkipped = new Set(prevSkipped.values());
          newSkipped.add(activeStep);
          return newSkipped;
        });
    };
    
    const handleReset = () => {
        setActiveStep(0);
    };

    /* END FORM STEPPER CODE */

    return (
        <>
            <Stepper activeStep={activeStep} sx={{marginBottom: "2rem"}}>

                {steps.map((stepObj, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                    optional?: React.ReactNode;
                } = {};

                if (isStepSkipped(index)) {
                    stepProps.completed = false;
                }

                return (
                    <Step key={index} {...stepProps}>
                        <StepLabel {...labelProps}>{stepObj.title}</StepLabel>
                    </Step>
                )})}

            </Stepper>

            {/* This will run after user clicks "Finish" button */}
            {activeStep === steps.length ? (
                <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you're finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </>
            ) : (
                <>

                    
                    { props.steps[activeStep].component }

                    {/* Buttons */}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                        Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                        <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                            Skip
                        </Button>
                        )}
                        <Button onClick={() => {(activeStep === steps.length-1) ? props.handleFinish() : handleNext() }}>
                            { activeStep === steps.length - 1 ? 'Finish' : 'Next' }
                        </Button>
                    </Box>
                </>
            )}
        </>
    )
}