import React, { useEffect, useRef, useState } from "react";

import { useSearchParams  } from "react-router-dom";

import { XMLValidator, ValidationError } from 'fast-xml-parser'

import { GlobalState } from "GlobalState";
import { Button } from "@mui/material";
import { ulinkFetch } from "services/ULink";
import { toaster } from "global-ui";

interface ValidatorResult { 
    contents: string, 
    error: ValidationError, 
}

interface ValidatorHandlers { 
    onLoading:  (isLoading: boolean) => void ,
    onUpdate:   (results: ValidatorResult) => void, 
}



class Validator {  

    private currentContents ?: string 
    private pendingContents ?: string 

    private handlers ?: ValidatorHandlers

    setHandlers(handlers: ValidatorHandlers) { 
        this.handlers = handlers 
    }

    private execute(contents: string) { 
        this.currentContents = contents
        
        new Promise((accept) => { 
            let err = XMLValidator.validate('<div>' + contents + '</div>')
            if(err === true) accept({ 
                contents: contents, 
                error: null, 
            })
            else accept({ 
                contents: null, 
                error: err
            })
        })
        .then(this.handle.bind(this))
        // If we don't pass this as a lambda, `this` doesn't get bound.  lol 
    }

    validate(contents: string) { 
        if(this.currentContents) { 
            console.debug("Pending validate") 
            this.pendingContents = contents
        } else { 
            this.execute(contents) 

            if(this.handlers) this.handlers.onLoading(true) 
            else console.warn("Validator began with no handlers") 
        }
    }

    private handle(validation : ValidatorResult) { 

        if(this.pendingContents) { 
            this.execute(this.pendingContents) 
            this.pendingContents = null 
        } else { 
            if(this.handlers) { 
                this.handlers.onUpdate(validation)  
                this.handlers.onLoading(false) 
                this.currentContents = null 
            } else { 
                console.warn("Validator finished with no handlers") 
            }
        }

    }

}

let validator = new Validator() 



function AgreementPreview(props : { validatorResult : ValidatorResult | null }) : JSX.Element { 
    if(! props.validatorResult) { 
        return <div id="Preview">Loading...</div>
    }
    if(props.validatorResult.error) { 
        let err = props.validatorResult.error.err 
        return <div id="Preview">
            <h1>Invalid html</h1>
            <p>{ err.msg }</p>
            <p>at {err.line}:{err.col}</p>
        </div>
    } else { 
        return <div className="Agreement" id="Preview" dangerouslySetInnerHTML={{ __html: props.validatorResult.contents }}></div>
    }
}

export default function AgreementEdit() : JSX.Element { 
    let global = GlobalState.useContainer()

    let params = useSearchParams()[0] 
    let name = params.get("name") 
    let uri = `/v2/misc/agreements/${name}/current`

    let [ contents, setContents ] = useState<string>("")
    let [ result, setResult ] = useState<ValidatorResult>(null) 



    let updateContents = (str: string) => { 
        setContents(str) 
        validator.validate(str) 
    }

    let submit = () => { 
        if(result.error) toaster.error();
        else ulinkFetch(uri, {
            method: "POST",
            body: contents, 
            headers: { "Content-Type": "text/html" },
        })
        .then(res => { 
            if(res.ok) toaster.updated("Agreement");
            else toaster.catchULink(res) 
        })
    }


    useEffect(() => { 
        ulinkFetch(uri)
            .then(res => { 
                if(res.status == 404) toaster.customError("This thing doesn't exist yet.")
                else if(res.ok) return res.text()
                else toaster.catchULink(res)
            })
            .then( updateContents )
    }, [])

    validator.setHandlers({ 
        onLoading: global.setIsLoading,
        onUpdate: setResult, 
    })


    // https://stackoverflow.com/questions/6637341/use-tab-to-indent-in-textarea
    return <main id="AgreementEdit">
        <Button onClick={ submit } sx={{ display: "block" }}>Save new version</Button>

        <div id="AgreementEditor">
            <textarea 
                value={contents} onChange={ e => updateContents(e.target.value) }
                autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck="false"
            ></textarea>
            <AgreementPreview validatorResult={result} />
        </div>
    </main>
}