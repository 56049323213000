import { Session, SessionContext } from "data/Admin"
import { useEffect, useState } from "react"
import { createContainer } from "unstated-next"
import { toaster } from "global-ui"
import ULink from "./services/ULink"

const GlobalStateContainer = () => {
    const [hasFirstLoad, setHasFirstLoad] = useState(false)
    const [session, setSession] = useState<Session>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [context, setContext] = useState<SessionContext>(null)
    const [mainRef, setMainRef] = useState<React.MutableRefObject<any>>(null)

    useEffect(() => {
        if (session) return
        else if (hasFirstLoad) {
            toaster.loggedOut();
        } else {
            setIsLoading(true)
            ULink.session
                .refresh()
                .then(s => {
                    // if session is the same as last time and initial load already happenend
                    // If the user logs out and logs back in, the session will set again, causing an infinite loop
                    // We prevent that here
                    if (s) {
                        setHasFirstLoad(true)
                        setSession(s)
                    }
                    setIsLoading(false)
                })
                .catch(err => {
                    setIsLoading(false)
                    console.error(err)
                    toaster.catchULink(err)
                })
        }
    }, [session])

    const scrollToTop = () => {
        mainRef.current.scrollTo(0, 0)
    }

    /**
     * Logs an error and renders the result to the main page;
     * turning the entire view to a 'not found' or 'error' view.
     *
     * This is intended for the main query of a data page
     */
    const [notFound, setNotFound] = useState(null)
    const [errored, setErrored] = useState(null)
    const catchMain = (err: any) => {
        if (err == 404) {
            setNotFound(location.pathname)
        } else {
            console.error("Error caught in main query:", err)
            setErrored(location.pathname)
        }
    }

    return {
        session,
        setSession,
        isLoading,
        setIsLoading,
        context,
        setContext,
        mainRef,
        setMainRef,
        scrollToTop,
        notFound,
        errored,
        catchMain,
    }
}

export const GlobalState = createContainer(GlobalStateContainer)
