import React, { useState } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import { AcceptInvite } from "views/AcceptInvite"
import { LogIn } from "views/LogIn"
import { Main } from "views/Main"
import { Toaster } from "react-hot-toast"
import { GlobalState } from "GlobalState"

import { ThemeProvider, CssBaseline } from "@mui/material"
import { MainAdminTheme, MainAdminThemeLight, AdamsLightTheme } from "data/Theme"
import { InitiateReset } from "views/pages/forgot-password/InitiateReset"
import { ResetPassword } from "views/pages/forgot-password/ResetPassword"

const localStorageTheme = () => {
    switch (localStorage.getItem("Theme")) {
        case "MainAdminThemeLight":
            return MainAdminThemeLight
        case "AdamsLightTheme":
            return AdamsLightTheme
        default:
            return MainAdminThemeLight
    }
}

const App = () => {
    const [showPopup, setShowPopup] = useState(false)
    const { session } = GlobalState.useContainer()

    return (
        <>
            {session ? (
                <Main />
            ) : (
                <>
                    <Toaster />
                    <LogIn />
                </>
            )}
        </>
    )
}

const Root = () => {
    return (
        <ThemeProvider theme={localStorageTheme()}>
            <CssBaseline />
            <Toaster />
            <GlobalState.Provider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/forgot-password" element={<InitiateReset />} />
                        <Route path="/invites/:type" element={<AcceptInvite />} />
                        <Route path="*" element={<App />} />
                    </Routes>
                </BrowserRouter>
            </GlobalState.Provider>
        </ThemeProvider>
    )
}

document.addEventListener("DOMContentLoaded", function () {
    ReactDOM.render(React.createElement(Root), document.getElementById("APPROOT"))
})
