import React, { useState } from "react"
import { entity_location_statuses } from "globals"
import Merchant from "data/Merchant"

import ULinkService from "services/ULink"
import { useNavigate } from "react-router-dom"
import { toaster } from "global-ui"
import { GlobalState } from "GlobalState"

import FormValidator from "widgets/FormValidator"
import TextInput from "widgets/TextInput"

// MUI
import { 
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from "@mui/material";



interface Props {
    isNewProp?: boolean
    collectionURI?: string // The URI to post to, if creating a new merchant
    merchantProp?: Merchant
    setHasUnsaved?: React.Dispatch<React.SetStateAction<boolean>>
    state: Merchant
    setState: React.Dispatch<React.SetStateAction<any>>
    isInvitation?: boolean
    mergeState: (state: any) => void
    merchantInfoFormValid: boolean
    setMerchantInfoFormValid: (isValid: boolean) => void
}

export const MerchantInfo: React.FC<Props> = ({
    isNewProp,
    collectionURI,
    merchantProp,
    setHasUnsaved,
    state,
    setState,
    isInvitation,
    mergeState,
    merchantInfoFormValid,
    setMerchantInfoFormValid
}) => {
    const isNew = isNewProp || !merchantProp
    const navigate = useNavigate()
    const { context } = GlobalState.useContainer()

    const [merchant, setMerchant] = useState(merchantProp ?? new Merchant())
    const [isSubmitting, setIsSubmitting] = useState(false)

    const onSave = () => {
        if (!merchantInfoFormValid || isSubmitting) return
        setIsSubmitting(true)

        const obj = Object.assign(merchant, state)

        if (isNew) {
            ULinkService.merchants
                .create(obj, collectionURI)
                .then(newMerchant => {
                    toaster.created("a business")
                    navigate("/merchant?uri=" + newMerchant.uri)
                })
                .catch(toaster.catchULink)
                .finally(() => {
                    setIsSubmitting(false)
                })
        } else {
            ULinkService.updateObject(Object.assign(obj, state))
                .then(updated => {
                    setHasUnsaved(false)
                    setState({})
                    setMerchant(updated)

                    toaster.saved()
                })
                .catch(toaster.catchULink)
                .finally(() => {
                    setIsSubmitting(false)
                })
        }
    }

    const onDelete = () => {
        const obj = Object.assign(merchant, state)
        const confirm = window.confirm(
            "Are you sure? This action cannot be undone without contacting support."
        )
        if (!confirm) return
        obj.status = 2
        ULinkService.updateObject(obj)
            .then(() => navigate(-1))
            .catch(toaster.catchULink)
    }

    const current = Object.assign(merchant, state)

    return (
        <>
            <h1>Business Info</h1>
            <div id="MerchantInfoForm" style={{ display: "flex", flexDirection: "column" }}>
                {!isNew && (
                    <FormControl sx={{ margin: "1rem 0rem" }} fullWidth>
                        <InputLabel>{entity_location_statuses[current.status]}</InputLabel>
                        <Select
                            label={entity_location_statuses[current.status]}
                            value={current.status || ""}
                            onChange={e => mergeState({ status: e.target.value })}
                        >
                            {Object.keys(entity_location_statuses).map(num => (
                                <MenuItem key={num} value={num}>
                                    {entity_location_statuses[num]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}

                <FormValidator fieldsValid={merchantInfoFormValid} setFieldsValid={setMerchantInfoFormValid}>
                    <TextInput
                        type="generic"
                        label="Name"
                        required
                        margin="normal"
                        value={current.name || ""}
                        onChange={e => mergeState({ name: e.target.value })}
                    />

                    <TextInput
                        type="generic"
                        label="Address Line 1"
                        required
                        margin="normal"
                        value={current.address_1 || ""}
                        onChange={e => mergeState({ address_1: e.target.value })}
                    />

                    <TextInput
                        label="Address Line 2"
                        margin="normal"
                        value={current.address_2 || ""}
                        onChange={e => mergeState({ address_2: e.target.value })}
                    />

                    <TextInput
                        type="generic"
                        label="City"
                        required
                        margin="normal"
                        value={current.city || ""}
                        onChange={e => mergeState({ city: e.target.value })}
                    />

                    <TextInput
                        type="addressRegion"
                        label="State"
                        required
                        margin="normal"
                        value={current.region || ""}
                        onChange={e => mergeState({ region: e.target.value })}
                    />

                    <TextInput
                        type="addressZip"
                        label="Zip"
                        required
                        margin="normal"
                        value={current.zip || ""}
                        onChange={e => mergeState({ zip: e.target.value })}
                    />
                </FormValidator>

                {!isInvitation && (
                    <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <Button
                            sx={{ marginRight: "1rem" }}
                            variant="outlined"
                            color="secondary"
                            onClick={onSave}
                        >
                            {isNew ? "Create" : "Save"}
                        </Button>

                        {!isNew && context.type == "sionic" && (
                            <Button variant="outlined" color="error" onClick={onDelete}>
                                Delete
                            </Button>
                        )}

                        {isNew && (
                            <Button variant="outlined" color="error" onClick={() => navigate(-1)}>
                                Cancel
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </>
    )
}
