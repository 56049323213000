import React from 'react';
import { Checkbox } from './Inputs';

export default function(props) {

    let itemID = props.itemID
    let allTaxes = props.kernel.allTaxes()
    let taxEnabled = allTaxes.map(tax => props.kernel.isTaxOnItem(tax.id, itemID ))

    //TODO: Add kernel methods for AllTaxes and MenuItemTaxes, don't access kernel.data directly 
    return (
        <div>
            {
                props.kernel.data.taxes.map((tax, i) => 
                    <Checkbox key={tax.id} name={"tax-"}>{ tax.name }</Checkbox>
                )
            }
        </div>
    );
}