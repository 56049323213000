import React, { useEffect, useState }  from 'react';

import * as MenuData from 'lib/lib-smb-menus/data/Menu'
import { Checkbox } from '../../widgets/Inputs.jsx';
import { Button } from 'widgets/Button.jsx';

import GlobalInput from '../../widgets/GlobalInput.jsx';
import Mods from './Mods.jsx';
import Taxes from '../../widgets/Taxes.jsx';
import AddMods from '../../modals/AddMods.jsx';
import EditMods from '../../modals/EditMods.jsx';

import { Change } from '../../EditorState.js';

/**
 * 
 * @param {object} props 
 * @param {MenuData.default} props.menuData 
 * @param {EditorState} props.editorState
 * @param {React.Dispatch<EditorState>} props.setEditorState 
 * 
 * @param {MenuData.MenuItem} props.item 
 */
export default function(props) {
    const change = props.editorState.getChange(props.item) 
    const current = change?.getResult(props.item) ?? props.item 

    /** @param {object} chg */
    const changeExisting = (chg) => { 
        props.setEditorState(
            props.editorState.copy().withChange(
                (change ?? Change.NewEditEntity(props.item)).withApply(chg) 
            )
        )
    }

    // Add Mods Modal State
    let [addModal, setaddModal] = useState(false);
    // Edit Mods Modal State
    let [editModId, setEditModId] = useState(null);

    let modal = null;
    if(addModal) {
        modal = <AddMods kernel={kernel} closemodal={() => setaddModal(false)} />
    }
    if(editModId != null) {
        modal = <EditMods kernel={kernel} modid={editModId} closemodal={() => setEditModId(null)} />
    }

    //TODO: No overflow-y when modal is open, presumably to prevent scrolling?
    // if(editModId || addModal) {
    //     merchantBody.classList.add("no-overflow");
    // } else {
    //     merchantBody.classList.remove("no-overflow");
    // }
    
    return (
        <div className="card MenuItem">
            <GlobalInput name="name" label="Name" 
                value={ current.name } onChange={ e => changeExisting({ name: e.target.value }) }
                edittip="Click to edit item name" 
                infotip="Name of menu item goes here" />
            <GlobalInput name="description" label="Description" 
                value={ current.description } onChange={ e => changeExisting({ description: e.target.value }) }
                multiline 
                edittip="Click to edit item description" 
                infotip="Give your item a description here" />

            {/* TODO: This */}
            <div data-name="image">
                <img src={ props.item.image_url ?? "/img/img-placeholder.jpeg" } alt="Menu item image" />
                <button> Edit Image</button>
            </div>

            {/* {modal} */}
            {/* <div className="mods">
                <h5>Item Choices</h5>
                {
                    kernel.getItemMods(item.id).map((mod) => 
                        <Mods key={mod.id} mod={mod} 
                            // TODO
                            onEdit={() => setEditModId(mod.id)} onRemove={ () => {} } 
                        /> 
                    )
                }

                <Button icon="add" onClick={() => setaddModal(true)}>Add Item Choice</Button>
            </div> */}


            <div className="price">
                <GlobalInput name="price" label="Price" 
                    value={ props.item.price } // TODO: Price Big.js stuff!  Aaaaahh!
                    edittip="Click to edit item price" 
                    infotip="Give your item a price here"/>
                <Button icon="add">Add choice of size</Button>
            </div>

            {/* <div className="checkboxes">
                <h5>Special Taxes</h5>
                <Taxes kernel={kernel} itemID={item.id} />

                <h5>Availability</h5>
                <Checkbox name="custom-instructions">Custom instructions allowed</Checkbox>
                <Checkbox name="visible">Visible to public</Checkbox>
                <Checkbox name="available">Available for sale</Checkbox>
            </div> */}

            <Button data-name="delete" className='destructive' icon="delete" >Delete Item</Button>

        </div>
    )
}