import React, { useEffect } from "react"
import { useNavigate, useSearchParams, useParams } from "react-router-dom"

import ULinkService from "services/ULink"
import { GlobalState } from "GlobalState"

import DataTable from "widgets/DataTable"
import { entity_location_statuses, roles } from "globals"

import { Button, Container, Paper } from "@mui/material"

export const ListUsers = () => {
    const navigate = useNavigate()
    const { userType } = useParams()
    const query = useSearchParams()[0]
    const uri = query.get("uri")
    const { scrollToTop, context } = GlobalState.useContainer()

    useEffect(() => {
        scrollToTop()
    }, [])

    // Note: we need these to be in one component in order to pass into the Paper component "children" prop
    const DataTable_inContext = () => {
        switch (userType) {
            case "global":
                return (
                    <>
                        <DataTable
                            firstFetch={() => ULinkService.admins.globalListUsers(uri)}
                            columns={[
                                { name: "Name", selector: row => row.user.name },
                                {
                                    name: "Contexts",
                                    selector: row =>
                                        `${row.contexts[0].business_name} ${row.contexts[0].role_value}`,
                                },
                                {
                                    name: "Status",
                                    selector: row => entity_location_statuses[row.user.status],
                                },
                            ]}
                            onRowClicked={(row) =>
                                navigate(`/global/user?uri=/v2/admin${row.uri}`)
                            }
                            loadingMessage={"Loading Users..."}
                            emptyMessage={"No Users"}
                        />
                    </>
                )
            default:
                return (
                    <>
                        <DataTable
                            firstFetch={() => ULinkService.admins.listUsers(uri)}
                            columns={[
                                { name: "Name", selector: row => row.user.name },
                                {
                                    name: "Role",
                                    selector: row => roles[userType].getNameForValue(row.roleValue),
                                },
                                {
                                    name: "Status",
                                    selector: row => entity_location_statuses[row.user.status],
                                },
                            ]}
                            onRowClicked={(row, e) =>
                                navigate(`/${userType}/user?uri=/v2/admin${row.uri}`)
                            }
                            loadingMessage={"Loading Users..."}
                            emptyMessage={"No Users"}
                        />
                    </>
                )
        }
    }

    return (
        <>
            <Container>
                <header className="header-and-button">
                    <h1>All Users</h1>
                    <Button
                        id="invite-user-button"
                        variant="outlined"
                        onClick={() => navigate("/user/invite?uri=" + context.invite_links.user)}
                    >
                        Invite User
                    </Button>
                </header>

                <Paper sx={{ padding: "2rem" }} children={<DataTable_inContext />} />
            </Container>
        </>
    )
}