import React, { useState, useEffect } from "react"
import { useParams, useSearchParams, useNavigate } from "react-router-dom"
import { roles } from "globals"
import ULinkService from "services/ULink"
import { admin_statuses } from "globals"
import { GlobalState } from "GlobalState"

import PopUp from "widgets/Popup"

// MUI
import {
    Container,
    Paper,
    Box,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
} from "@mui/material"
import { toaster } from "global-ui"

export default function ViewUser(props) {
    const { scrollToTop, context } = GlobalState.useContainer()

    let navigate = useNavigate()

    let { userType } = useParams()
    let query = useSearchParams()[0]

    let [showPopup, setShowPopup] = useState(false)

    // Form state
    let [name, setName] = useState("")
    let [email, setEmail] = useState("")
    let [role, setRole] = useState("")
    let [status, setStatus] = useState("")
    let [contextArr, setContexts] = useState([])

    /** @type {[ ?Location, function ]} */
    let [admin, _set_admin] = useState(null)

    /**
     * Updates the admin from a fetch, loading form state in the process
     * @param {Admin} admin
     */
    let setAdmin = admin => {
        _set_admin(admin)

        setName(admin.user.name)
        setEmail(admin.user.email)
        setStatus(admin.user.status)
        if (admin.contexts) {
            setContexts(admin.contexts)
        } else {
            setRole(admin.role_value)
        }
    }

    //
    // Data fetching
    //
    const fetchAdmins = () => {
        ULinkService.getJSON(query.get("uri"))
            .then(res => setAdmin(res))
            .catch(err => console.error(err))
    }

    useEffect(() => {
        scrollToTop()
        fetchAdmins()
    }, [])

    //
    // Data updating
    //

    function updateUser() {
        let user = admin.user

        user.name = name
        user.email = email
        user.status = status

        if (userType == "global") {
            user.uri = `/v2/admin/admins/${user.id}`
        }
        ULinkService.update(user)
            .then(res => {
                res.json()
                toaster.saved()
            })
            .catch(toaster.catchULink)
    }

    const updateRole = (newRole, ctx_id?) => {
        if (userType == "global") {
            ULinkService.update({
                role_value: newRole,
                uri: query.get("uri") + `/${ctx_id}`,
            })
                .then(res => res.json())
                .then(data => {
                    setContexts(data.contexts)
                    toaster.saved()
                })
                .catch(toaster.catchULink)
        } else {
            setRole(newRole)
            ULinkService.update({
                role_value: newRole,
                uri: query.get("uri"),
            })
                .then(res => toaster.saved())
                .catch(toaster.catchULink)
        }
    }

    let $ContextUserView: React.ReactElement<any, any>
    let $GlobalUserView: React.ReactElement<any, any>[]

    if (userType == "global") {
        $GlobalUserView = contextArr.map((ctx, i) => {
            return (
                <>
                    <FormControl sx={{ margin: "1rem 0rem" }} fullWidth>
                        <InputLabel>{ctx.business}</InputLabel>
                        <Select
                            label={ctx.business}
                            value={ctx.role_value}
                            onChange={e => updateRole(e.target.value, ctx.ctx_id)}
                        >
                            {roles[ctx.type].roles.map(r => (
                                <MenuItem key={r.value} value={r.value}>
                                    {roles[ctx.type].getNameForValue(r.value)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            )
        })
    } else {
        $ContextUserView = (
            <>
                <FormControl sx={{ margin: "1rem 0rem" }} fullWidth>
                    <InputLabel>Role</InputLabel>
                    <Select label="Role" value={role} onChange={e => updateRole(e.target.value)}>
                        {roles[userType].roles.map(r => (
                            <MenuItem key={r.value} value={r.value}>
                                {roles[userType].getNameForValue(r.value)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </>
        )
    }

    if (!admin) return null

    return (
        <>
            {/* If Tabs need to be added to this view, see ViewReseller.tsx for ref */}
            <Container>
                <section role="banner">
                    <h1>{admin.user.name}</h1>
                </section>

                <Box>
                    <Paper
                        sx={{ padding: "2rem" }}
                        elevation={3}
                        children={
                            <>
                                <h1>User Info</h1>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "30%",
                                    }}
                                >
                                    <TextField
                                        disabled
                                        label="Name"
                                        value={name}
                                        required={true}
                                        margin="normal"
                                        onChange={e => setName(e.target.value)}
                                    />
                                    {/*
                                <TextField
                                    label="Email"
                                    value={email}
                                    required={true}
                                    margin="normal"
                                    onChange={e => setEmail(e.target.value)}
                                /> */}

                                    {userType == "global" ? $GlobalUserView : $ContextUserView}

                                    {/* Status Select */}
                                    {context.type == "sionic" && (
                                        <FormControl sx={{ margin: "1rem 0rem" }} fullWidth>
                                            <InputLabel>
                                                Status
                                            </InputLabel>
                                            <Select
                                                label="Status"
                                                value={status}
                                                onChange={e => setStatus(e.target.value)}
                                            >
                                                {Object.keys(admin_statuses).map(num => (
                                                    <MenuItem key={num} value={num}>
                                                        {admin_statuses[num]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    )}

                                    <div className="right-aligned-buttons">
                                        <Button
                                            sx={{ marginRight: "1rem" }}
                                            variant="outlined"
                                            color="secondary"
                                            onClick={updateUser}
                                        >
                                            Save
                                        </Button>

                                        <Button
                                            variant="outlined"
                                            color="error"
                                            onClick={() => navigate(-1)}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </div>
                            </>
                        }
                    />
                </Box>
            </Container>
        </>
    )
}
