import React from 'react';

import * as MenuData from 'lib/lib-smb-menus/data/Menu'


/**
 * 
 * @param {object} props 
 * @param {MenuData.default} props.menuData 
 * 
 * @param {MenuData.MenuSection} props.section 
 * @param {[MenuData.MenuSection]} props.childSections 
 * @param {[MenuData.MenuItem]} props.childItems
 * 
 * @param {boolean} props.headless If `true`, do not render editable section header (See `MenuView.jsx`)
 */
function Section(props) { 
    let indent      = props.indent || 0; 

    let heading = indent + 1; 
    if(heading > 4) heading = 4; 
    const Heading = `h${heading}`


    return <React.Fragment>
        { !(props.headless) && <Heading>{ props.section.name }</Heading> }
        <ol>
        { 
            props.childSections.map( section => { 
                const cprops = { 
                    menuData:      props.menuData,
                    childItems:    props.menuData.menuItems(section.id),
                    childSections: props.menuData.menuSections(section.id),
                    section:       props.menuData.getSection(section.id), 
                }
                return <li key={section.id}><Section {... cprops}  headless={ false }  indent={ indent + 1 }  /></li>
            } )
        }{
            props.childItems.map(item => <li key={ item.id }><h5>{ item.name }</h5></li> )
            //TODO: Scrolling !
        }

        </ol>
    </React.Fragment>
}



/**
 * 
 * @param {object} props 
 * @param {MenuData.default} props.menuData 
 * 
 * @param {MenuData.MenuSection} props.section 
 * @param {[MenuData.MenuSection]} props.childSections 
 * @param {[MenuData.MenuItem]} props.childItems
 * 
 */
export default function(props){
    return <div id="Outline">
        <Section {... props} headless={true} /> 
    </div>
}
