import Reseller from "data/Reseller"
import React, { useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import { ulinkFetch } from "services/ULink"
import { ResellerInfo } from "./pages/resellers/ResellerInfo"
import { UserEdit } from "./UserEdit"
import { useNavigate } from "react-router-dom"
import { MerchantInfo } from "./pages/merchants/MerchantInfo"
import Merchant from "data/Merchant"

// MUI
import { Container, Box, Paper } from "@mui/material"
import FormStepper from "../widgets/FormStepper" //Our custom MUI stepper
import { toaster } from "global-ui"
import { useEffect } from "react"

interface UserState {
    email: string
    name: string
    password: string
    passwordConfirm: string
}

export interface FormState {
    user: UserState
    reseller?: Reseller
    merchant?: Merchant
}

export const AcceptInvite = () => {
    const uri = useSearchParams()[0].get("uri")

    const { type } = useParams() as { type: "reseller" | "merchant" | "user" }

    const navigate = useNavigate()


    const [isSubmitting, setIsSubmitting] = useState(false)

    // UserEdit Form Values (Terry did this un-DRY mess but no time to fix rn)
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [password, setPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")

    const [reseller, setReseller] = useState<Reseller>(new Reseller())
    const mergeResellerState = (update: any) => {
        setReseller(old => Object.assign({}, old, update))
    }

    const [merchant, setMerchant] = useState<Merchant>(new Merchant())
    const mergeMerchantState = (update: any) => {
        setMerchant(old => Object.assign({}, old, update))
    }

    // --- Form Fields Valid --- //
    // UserEdit Form
    const [ userEditFormValid, setUserEditFormValid ]           = useState(false); // this is set true if both states below are true
    const [ personalDetailsValid, setPersonalDetailsValid ]     = useState(false);
    const [ accountSetupValid, setAccountSetupValid ]           = useState(false);
    const [ passwordsMatch, setPasswordsMatch ]                 = useState(true);
    // MerchantInfo Form
    const [ merchantInfoFormValid, setMerchantInfoFormValid ]   = useState(false);
    // MerchantInfo Form
    const [ resellerFormValid, setResellerFormValid ]           = useState(false);

    useEffect(() => { 
        setPasswordsMatch(password === passwordConfirm);
    }, [password, passwordConfirm]);
    useEffect(() => {
        // Sucks we have to do this but userEditForm is split up into two forms which both need to be valid
        personalDetailsValid 
        && accountSetupValid
        && passwordsMatch ? setUserEditFormValid(true) : setUserEditFormValid(false);
    }, [personalDetailsValid, accountSetupValid, passwordsMatch]);

    // --------------------------------------------


    function postUserDataToULinkAPI(userData: any) {
        ulinkFetch(uri, { method: "POST", json: userData }).then(response => {
            if (response.ok) {
                navigate("/")
            } else {
                switch (response.status) {
                    case 401:
                        toaster.expiredLink();
                        break
                    case 409:
                        toaster.emailExists();
                        break
                    case 422:
                        toaster.error();
                        break
                    default:
                        toaster.error();
                        break
                }
                setIsSubmitting(false)
            }
        })
    }

    const userEditForm = () => {
        return (
            <UserEdit
                email={email}
                setEmail={setEmail}
                name={name}
                setName={setName}
                password={password}
                setPassword={setPassword}
                passwordConfirm={passwordConfirm} setPasswordConfirm={setPasswordConfirm} passwordsMatch={passwordsMatch}
                personalDetailsValid={personalDetailsValid} setPersonalDetailsValid={setPersonalDetailsValid}
                accountSetupValid={accountSetupValid} setAccountSetupValid={setAccountSetupValid}
            />
        )
    }

    const resellerForm = () => {
        return (
            <Paper
                sx={{ padding: "2rem" }}
                children={
                    <ResellerInfo
                        isNew={false}
                        state={reseller}
                        mergeState={mergeResellerState}
                        resetState={() => setReseller(new Reseller())}
                        isInvitation={true}
                        resellerFormValid={resellerFormValid} setResellerFormValid={setResellerFormValid}
                    />
                }
            />
        )
    }

    const merchantInfoForm = () => {
        return (
            <Paper sx={{ padding: "2rem" }} children={
                <MerchantInfo
                    isNewProp={false}
                    state={merchant}
                    setState={setMerchant}
                    isInvitation={true}
                    mergeState={mergeMerchantState}
                    merchantInfoFormValid={merchantInfoFormValid} setMerchantInfoFormValid={setMerchantInfoFormValid}
                />
            } />
        )
    }

    const isFormValid = (form: string) => {
        // This function is needed because it returns state which changes many times after inviteSteps would be initialized.
        switch(form) {
            case 'UserEdit':        return userEditFormValid;
            case 'ResellerInfo':    return resellerFormValid;
            case 'MerchantInfo':    return merchantInfoFormValid;
            default: throw new Error("Form type provided is not a valid option for isFormValid argument.")
        }
    }

    // Invite Form Steps by Type
    const inviteSteps = {
        user: [
            { title: "Personal & Account",  component: userEditForm(),      isValid: isFormValid("UserEdit") }
        ],
        merchant: [
            { title: "Personal & Account",  component: userEditForm(),      isValid: isFormValid("UserEdit") },
            { title: "Information",         component: merchantInfoForm(),  isValid: isFormValid("MerchantInfo") }
        ],
        reseller: [
            { title: "Personal & Account",  component: userEditForm(),      isValid: isFormValid("UserEdit") },
            { title: "Information",         component: resellerForm(),      isValid: isFormValid("ResellerInfo") }
        ]
    };

    const lastFormStepIsValid = () => inviteSteps[type].slice(-1)[0].isValid ? true : false;

    const submitInviteForm = () => {
        if (!lastFormStepIsValid()) { toaster.invalidFields(); return; }
        if (isSubmitting) toaster.isSubmitting();
        else {
            setIsSubmitting(true);
    
            const userInfo = { user: { email, name, password, }, reseller, merchant };
    
            postUserDataToULinkAPI(userInfo);
        }
    }

    return (
        <Container>
            <Box sx={{ width: "60%", margin: "auto", marginTop: "3rem" }}>
                <h1 style={{ textAlign: "center", marginBottom: "2rem" }}>
                    ULink Admin Invitation
                </h1>

                <FormStepper steps={inviteSteps[type]} handleFinish={submitInviteForm} />
            </Box>
        </Container>
    )
}
