import React from "react";

/* PopUp Info ------------------------------------------------------------------
    - The title should almost never be changed for common errors.
    - The message will change based on the type of error or information provided -
      a default message will display if one is not provided.
    - props.button is an option to have the popup's close button run a custom
      function passed through props.
    - In parent component, always use [showPopup, setShowPopup] = useState();
    - Always pass the parent component's "setShowPopup" as a prop to PopUp.jsx
    - The Close button will use the setShowPopup and set it to null onClick
    - To pass custom messaging, you can setShowPopup({message: "Custom message"})
      then <PopUp message={showPopup.message} />
    - In your component's return JSX, just create a turnary op on showPopup
      like this -->> showPopup ? <PopUp /> : null
      as long as showPopup is not false, it will be displayed over the entire page.
*/

/**
 * @deprecated Use `utils.Dialog` instead
 * @param {object} props 
 * @returns 
 */
export default function PopUp(props) {
    return (
        <div id="PopUp">
            <div id="Popup-container">
                <div id="Popup-card">
                    <h1>{props.title || "An error has occured."}</h1>
                    <p>{props.message || "There was a problem with your request. Please try again."}</p>
                    <div className="button-align-right">
                        <button onClick={() => {
                            props.setShowPopup(null);
                            props.button ? props.button() : null;
                        }}>Close</button>
                    </div>
                </div>
            </div>
        </div>
    )
}