import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import ULinkService from "services/ULink"
import { GlobalState } from "GlobalState"

import DataTable from "widgets/DataTable"
import { Button, Paper, TextField } from "@mui/material"
import { useState } from "react"
import Big from "big.js"
import { toaster } from "global-ui"

export const ListResellerGroups = () => {
    const navigate = useNavigate()
    const uri = useSearchParams()[0].get("uri")
    const { scrollToTop, context } = GlobalState.useContainer()

    const [rev_share_rate, set_rev_share_rate] = useState<string>("")

    const fetchResellerGroup = () => {
        ULinkService.getJSON(uri)
            .then(res => {
                set_rev_share_rate(new Big(res?.rev_share_rate ?? 0).mul(100).toString())
            })
            .catch(console.error)
    }

    const updateRevShare = () => {
        const rate = new Big(rev_share_rate).div(100)
        ULinkService.updateObject({ uri, rev_share_rate: rate }).then(res => {
            res
                ? toaster.updated("Revenue Share Rate")
                : toaster.error();
        });
    }

    const inviteReseller = () => {
        navigate("/reseller/invite?name=agent&uri=" + context.invite_links.reseller)
    }

    const formatInput = () => {
        set_rev_share_rate(Number(rev_share_rate).toFixed(3))
        if (Number(rev_share_rate) > 100) set_rev_share_rate("100")
        else if (Number(rev_share_rate) < 0) set_rev_share_rate("0")
    }

    useEffect(() => {
        scrollToTop()
        fetchResellerGroup()
    }, [])

    return (
        <>
            <div id="ListMerchants">
                <div className="header-and-button">
                    <h1>All Agents</h1>
                    {context.type === "reseller" && (
                        <>
                            <TextField
                                type="number"
                                id="outlined-required"
                                label="Revenue Share Rate %"
                                value={rev_share_rate}
                                required={true}
                                margin="normal"
                                onChange={e => set_rev_share_rate(e.target.value)}
                                onBlur={formatInput}
                            />
                            <Button onClick={updateRevShare} variant="outlined">
                                Update Share Rate
                            </Button>
                        </>
                    )}
                    {context.invite_links.reseller && (
                        <Button onClick={inviteReseller} variant="outlined">
                            Invite Agent
                        </Button>
                    )}
                </div>
                <Paper
                    sx={{ padding: ".5rem 0" }}
                    children={
                        <DataTable
                            //@ts-ignore - Jake's data table is the worst
                            firstFetch={() => ULinkService.resellers.getResellerGroups(uri)} //@ts-ignore
                            columns={[{ name: "Group Name", selector: row => row.group_name }]}
                            onRowClicked={(row, e) => navigate("/reseller?uri=" + row.uri)}
                            loadingMessage={"Loading Resellers..."}
                            emptyMessage={"No Resellers"}
                        />
                    }
                />
            </div>
        </>
    )
}
