import { MenuItem, MenuSection } from "lib/lib-smb-menus/data/Menu"
import { ulid } from "ulid"

/**
 * This object manages all of the state of the Menu Editor and its child components.
 * 
 * Currently, each editable entity can correspond to 0 or 1 `Change` elements 
 * within `this.changes` (or henceforth "the changeset").  
 * This may be changed in the future to allow for undoing separate changes
 * at separate points in town, but for now it is the rule. 
 */
export default class EditorState { 

    constructor() { 
        /** @type {[Change]} */
        this.changes = []
    }

    /** @returns {EditorState} */
    copy() { 
        return Object.assign( Object.create(Object.getPrototypeOf(this)), this ) 
    }

    /**
     * Pushes or overwrites the relevant change to the changeset represented by this object. 
     * @param {Change} incomingChange 
     * @returns {EditorState}
     */
    withChange(incomingChange) { 
        let i = this.changes.findIndex(x => x.key === incomingChange.key) 
        if(i === -1) { 
            this.changes.splice(0,0, incomingChange) 
        } else { 
            this.changes[i] = incomingChange
        }

        return this
    }


    /**
     * Gets the `Change` object for the given object within the changeset, or `null` if no such `Change` exists.
     * @param {Entity} entity 
     * @returns {?Change} `Change` or `null`, because I like `null`'s semantics more than `undefined`
     */
    getChange(entity) { 
        return this.changes.find(chg => chg.key === entity.id) || null 
    }

}


/** @typedef {"CREATE"|"DELETE"|"EDIT"} ChangeType */

/** @typedef {"MenuSection"|"MenuItem"} EntityName */
/** @typedef {MenuSection|MenuItem} Entity */





/**
 * An individual change within the changeset, 
 * with types representing creating, editing, or deleting an entity. 
 * 
 * The "entity" can be any of the components of an SMB menu, 
 * and it is specified by the `entityType` property. 
 * 
 * The entity affected by the change is referenced by the `key` property; 
 * since all SMB entities are keyed by ULID, the type of the entity shouldn't
 * ever be relevant. 
 * 
 * `Change` carries a payload called `state`, the meaning of which varies
 * on the `changeType`; see the `state` docs for more information.
 * This would be more appropriate for a sum type, I wish Javascript supported them directly.
 * For now, a class like this is the most convenient way to do it. 
 */
export class Change { 

    /**
     * Do not call this constructor directly; use the factory methods instead. 
     * 
     * @param {ChangeType} changeType 
     * @param {EntityName} entityType 
     * @param {string} key 
     * @param {*} state 
     */
    constructor(changeType, entityType, key, state) { 
        /** @type {ChangeType} */
        this.changeType = changeType 

        /** @type {EntityName} */
        this.entityType = entityType

        /**
         * The unique key corresponding to the entity represented by this object. 
         * @type {string} 
         */
        this.key = key 

        /**
         * The editor state for this item, whose meaning varies based upon the `changeType`:
         * 
         * - `CREATE`: This field is initialized to an empty object, which is then treated similarly to `EDIT` payloads
         * 
         * - `EDIT`: This is the object representation of all fields that have changed from the previous state. 
         * 
         * - `DELETE`: This is the full state needed to recreate the object (in case the action is undone.)
         * 
         * @type {object}
         */
        this.state = state 
    }


    /**
     * @param {Entity} entity 
     * @returns {EntityName}
     */
    static EntityName(entity) { 
        // Normally we could just get from `constructor.name` or whatever,
        //  but that would get borked during minification 
        if(entity instanceof MenuSection)   return "MenuSection" 
        if(entity instanceof MenuItem)      return "MenuItem"
    }






    /**
     * 
     * @param {EntityName} entityType 
     * @returns 
     */
    static NewCreateEntity(entityType) { 
        return new Change("CREATE", entityType, ulid(), {})
    }


    /**
     * @param {Entity} entity 
     */
    static NewDeleteEntity(entity) { 
        return new Change("DELETE", Change.EntityName(entity), entity.id, entity) 
    }

    /**
     * @param {Entity} entity 
     */
    static NewEditEntity(entity) { 
        return new Change("EDIT", Change.EntityName(entity), entity.id, {})
    }


    static UnsupportedOperationError = "Unsupported operation for this changeType"


    /**
     * For `DELETE` operations, this returns the original entity that this was initialized with.
     * This allows for undoing deletions.
     * 
     * For other operations, this method throws `Change.UnsupportedOperationError`.
     * 
     * @returns {Entity} 
     */
    getOriginal() { 
        if(this.changeType == "DELETE") return this.state
        else throw Change.UnsupportedOperationError 
    }


    /**
     * For `CREATE` and `EDIT` operations, this method gets the resulting object
     * by merging this `state` with the provided `original` entity. 
     * 
     * For other operations, this method throws `Change.UnsupportedOperationError`.
     * 
     * @template {Entity} T 
     * @param {T} original 
     * @returns {T}
     */
    getResult(original) { 
        if(this.changeType == "CREATE" || this.changeType == "EDIT") { 
            return Object.assign(Object.create(Object.getPrototypeOf(this)), original, this.state) 
        }
        else throw Change.UnsupportedOperationError 
    }


    /**
     * Applies a property update to this `Change` by merging it into the payload state.
     * @param {object} update An Object with key/value pairs representing the new values of the entity for this `Change` 
     * @returns {Change} This object
     */
    withApply(update) { 
        Object.assign(this.state, update) 
        return this 
    }
}
