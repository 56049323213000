import React, { useEffect } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"

import ULinkService from "services/ULink"
import { entity_location_statuses } from "globals"
import { GlobalState } from "GlobalState"

import DataTable from "widgets/DataTable"

// MUI
import { Button, Container, Paper, Typography } from "@mui/material"

export const ListMerchants = () => {
    const navigate = useNavigate()
    const query = useSearchParams()[0]
    const uri = query.get("uri")
    const { scrollToTop, context } = GlobalState.useContainer()

    useEffect(() => {
        scrollToTop()
    }, [])

    return (
        <>
            <Container id="ListMerchants">
                <header className="header-and-button">
                    <h1>All Businesses</h1>
                    {context.type == "sionic" && (
                        <Button
                            id="invite-businesses"
                            variant="outlined"
                            onClick={() => navigate("/merchant/invite")}
                        >
                            Invite Business
                        </Button>
                    )}
                </header>

                <Paper
                    sx={{ padding: "2rem" }}
                    children={
                        <DataTable
                            firstFetch={() => ULinkService.merchants.getList(uri)}
                            columns={[
                                { name: "Name", selector: row => row.name },
                                {
                                    name: "Address",
                                    selector: row => row.fullAddress() ?? "(not set)",
                                },
                                {
                                    name: "Status",
                                    selector: row => entity_location_statuses[row.status],
                                },
                            ]}
                            onRowClicked={(row, e) => navigate("/merchant?uri=" + row.uri)}
                            loadingMessage={"Loading Businesses..."}
                            emptyMessage={"No Businesses"}
                        />
                    }
                />
            </Container>
        </>
    )
}
